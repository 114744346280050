import { BonoForm } from "@components/common/wiz/wiztypes/CreateBonoWiz";
import { db } from "@lib/firebase";
import { Bonus } from "@models/bono/bonus";
import { serviceLimit } from "@models/bono/sessionsPack";
import { useAuth } from "app/modules/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import * as Sentry from '@sentry/react';

const useCreateBono = () => {
    const {centerId} = useAuth()
    const createBono = async (form: BonoForm) => {
        try {
            const col = collection(db, 'bonus')
            const ref = doc(col);
            const b: Bonus = {
                nameBonus: form.nameBonus,
                pers: form.pers,
                sessionsPack: form.sessionsPack,
                img: form.img,
                description: form.description,
                active: form.active,
                centerId: centerId,
                bonusId: ref.id,
                services: []
            }
            if (b.pers) {
                b.sessionsPack.forEach(p => {
                    const l = p.servicesLimit as serviceLimit[]
                    const pairs =  l.map(v => ({name: v.name, id: v.id}))
                    b.services = [...b.services, ...pairs]
                })
            } else {
                b.services = form.services.map((v) => ({name: v.serviceName, id: v.serviceId}))
            }
            await setDoc(doc(db, `bonus/${b.bonusId}`), b)
            toast.success('Bono creado')
        } catch (error) {
            toast.error('Ha habido un error')
            Sentry.configureScope(scope => scope.setTransactionName('Error createBono'))
            Sentry.captureException(error);         
        }

    }

    return {
        createBono
    }
}

export default useCreateBono