import { ClientBooks } from '@components/clients/detail/ClientBooks'
import TabUsers from '@components/clients/page/TabUsers'
import { TabSells } from '@components/sells/page/TabSells'
import React from 'react'
type Props = {
    dataTable: any[],
    nameCol: string
}
const ChooseTab = ({ dataTable, nameCol }: Props) => {

    return (
        <>
            {
                nameCol === 'users' ?
                    <TabUsers dataTable={dataTable} />
                    : null
            }
            {
                nameCol === 'bookings' ?
                    <ClientBooks dataTable={dataTable} />
                    : null
            }
            {
                nameCol === 'sells' ?
                    <TabSells dataTable={dataTable} />
                    : null
            }

            {
                nameCol === 'sellsProducts' ?
                    <ClientBooks dataTable={dataTable} />
                    : null
            }
        </>
    )
}

export default ChooseTab