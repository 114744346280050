import { TimeV2 } from "@models/bookings/timeV2";
import { Turns } from "@models/turns/turns";
import { makeMinutes } from "../dates/makeMinutes";
import { checkInsideOnWork } from "./checkInsideOnWork";

export const checkHoursDouble = (turns: Turns, time: TimeV2) => {
    if (turns.rest === 'Nowork') {
      return false;
    }
    const morns = makeMinutes(turns.intWorkerMor1, turns.intWorkerMor2);
    if (turns.rest === 'Intensivo') {
      return checkInsideOnWork(morns.intStart, morns.intEnd, time);
    } else {
      const aft = makeMinutes(turns.intWorkerAft1, turns.intWorkerAft2);
      return checkInsideOnWork(morns.intStart, morns.intEnd, time) || checkInsideOnWork(aft.intStart, aft.intEnd, time);
    }
  }