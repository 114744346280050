import { initializeApp } from 'firebase/app'
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
})
export const db = getFirestore(app)
export const storage = getStorage(app)
export const fireAuth = getAuth(app);
export const functions = getFunctions(app, 'europe-west1')
export const analytics = getAnalytics(app)
if (process.env.NODE_ENV ===  'test') {
  connectAuthEmulator(fireAuth, "http://127.0.0.1:9099")
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  
}