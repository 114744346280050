import { CenterCol } from '@assets/sass/bootstrapEx'
import { ActionButton } from '@components/ui/button/ActionButton'
import { AsyncAction } from '@components/ui/button/AsyncAction'
import { callCloud } from '@lib/helpers/callCloud'
import { Centers } from '@models/centers/center'
import { UserFinal } from '@models/user/userFinal'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { useState } from 'react'
import * as Sentry from '@sentry/react';

interface Props {
    center: Centers,
    user: UserFinal,
    senderUid: string,
    currEm: string,
    idBook: string
}
export const AskEmAction = (
    { center, user, senderUid, currEm, idBook }: Props
) => {
    const [loading, setloading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const send = async () => {
        setloading(true);
        try {
            const movedRememberV2 = callCloud('movedRememberV2');
            const d = await (await movedRememberV2({idBook: idBook, senderUid: senderUid})).data;
            if (!d) {
                Sentry.captureMessage(`movedRemember is false idBook: ${idBook}, sender: ${senderUid} `)
            } 
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error movedRememberV2'))
            Sentry.captureException(error, {user: {id: senderUid}});
        }
        setloading(false);
        dispatch(setCloseModal())
    }
    const close = () => {
        dispatch(setCloseModal())
    }
    const mess = center.wh ? `con teléfono ${user.phone}` : `con correo ${currEm}`
    return (
        <div className={`${CenterCol}`}>
            <p className="text-center">
                El usuario {mess} no ha sido notificado de tus últimos cambios
            </p>
            <div className='d-flex '>
                <AsyncAction loading={loading} classNames='fit-own d-block mx-auto me-5' action={send} text={'Enviar'}   />
                <ActionButton text={'No enviar'} action={close} />
            </div>
        </div>
    )
}
