import React, { useCallback, useState } from 'react'
import { EditAuto } from './edit/EditAuto'
import { ActionButton } from '@components/ui/button/ActionButton';
import { ContainerCreateAuto } from './create/ContainerCreateAuto';
import { AutoWorContext } from '@lib/context/autoWorker/AutoWorCxt';
import { useAppSelector } from '@redux/store/hooks';
import { selectView } from '@redux/features/uiSlice/uiSlice';
type AutoState = '' | 'EDIT' | 'CREATE'
export const AutoContainer = () => {
  const [st, setst] = useState<AutoState>('')
  const view = useAppSelector(selectView)


  const backShow = () => {
    setst('')
  }

  const isEdit = useCallback(() => {
    setst('EDIT')
  }, [])

  return (
    <AutoWorContext>
      <div className='p-3'>
        {
          st === '' && view === 'AUTO' &&
          <div className='d-flex justify-content-end'>
            <ActionButton important={true} text={'Crear horario'} action={() => setst('CREATE')} />
          </div>
        }
        {
          (st !== 'CREATE') &&
          <EditAuto isEdit={isEdit} back={backShow} />
        }
        {
          st === 'CREATE' &&
          <ContainerCreateAuto backShow={backShow} />
        }
      </div>
    </AutoWorContext>
  )
}
