import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Services } from '@models/services/services'
import { Form } from '@components/formik/Form'
import { BoldText } from '@components/ui/text/BoldText'
import { RadioField } from '@components/formik/radio/RadioField'
import { Col } from 'react-bootstrap'
import { Acc } from '@components/ui/accordion/Acc'
import toast from 'react-hot-toast'
import { db } from '@lib/firebase'
import { updateDoc, doc } from 'firebase/firestore'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import ExpoArr from '../create/ExpoArr'
const schema = Yup.object().shape({
    isExpo: Yup.string(),
    expoArr: Yup.array().of(
        Yup.object().shape({
            dur: Yup.string().when('isExpo', {
                is: (isExpo: string) => isExpo === 'si',
                then: Yup.string().required('Falta duración'),
                otherwise: Yup.string(),
            }),
            rest: Yup.boolean(),
            index: Yup.number()
        })
    )
})
interface Props {
    ser: Services
}

const Expo = ({ ser }: Props) => {
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: {
            isExpo: ser.isExpo === true ? 'si' : 'no',
            expoArr: [],
            typeExpo: 'SERVICE_REST',
        },
        validationSchema: schema,
        onSubmit: async (values, { setValues }) => {
            if ( values.isExpo === 'si' && values.expoArr.some((el: any) => el.dur === '')) {
                return toast.error('Faltan campos')
            }
            if (ser.doubleWorker === false) {
                setloading(true)
                const isExpo = values.isExpo === 'si';
                await updateDoc(doc(db, `services/${ser.serviceId}`), {
                    isExpo: isExpo, expoArr: values.expoArr
                })
                toast.success('Cambiado')
                setloading(false)
                setValues({ isExpo: values.isExpo, expoArr: [], typeExpo: ''  })
            } else {
                return toast.error('El servicio no puede ser doble')
            }
        },
    })

    return (
        <Acc title={'Exposición'} id={'expo'} >

            <Form formikVal={formik}>
                <div className={`${Col}`}>
                    <BoldText text='Marcar servicio exposición' classNames='text-center' />
                    <div className='d-flex flex-row justify-content-evenly'>
                        <RadioField title={'Sí'} name={'isExpo'} value={'si'} />
                        <RadioField title={'No'} name={'isExpo'} value={'no'} />
                    </div>
                </div>
                {
                    ser.isExpo === true ?
                    <div className={`${Col}`}>
                        <BoldText text='Actualmente:' classNames='text-center' />
                            <div className='d-flex flex-row justify-content-evenly'>
                            {
                                ser.expoArr.map((v, i) => (
                                    <div key={i}>
                                        <p>{v.rest === false ? `Servicio: ${v.dur}` : `Descanso: ${v.dur}`}</p>
                                    </div>
                                ))

                            }
                            </div>
                    </div>
                    :
                    null
                }
                {
                    formik.values.isExpo === 'si' ?
                        <ExpoArr />
                        : null
                }
                <div className={`${JustEnd} mt-5`}>
                    <SubmitBtn loading={loading} />
                </div>

            </Form>
        </Acc>
    )
}

export default Expo