import { TurnAuto } from '@models/turns/turn-auto'
import { TurnGlobal } from '@models/turns/turnGlobal'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form'
import { DefTurns } from '@components/workers/DefTurns'
import { RangeDate } from './ContainerCreateAuto'
import useEditWorker from '@lib/hooks/worker/useEditWorker'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import useCreateTurn from '@lib/hooks/worker/useCreateTurn'
import { Worker } from '@models/user/worker'
import toast from 'react-hot-toast'
import { ActionButton } from '@components/ui/button/ActionButton'
import { ShowAuto } from '../ShowAuto'

interface Props {
  range: RangeDate,
  back: () => void

}
const initialValues = {
  intWorkerMor1: '',
  intWorkerMor2: '',
  intWorkerAft1: '',
  intWorkerAft2: '',
  rest: '',
  turns: [],
  day: '',
  name: ''
}
export const CopyAuto = ({ range, back }: Props) => {
  const { makeTurn } = useCreateTurn()
  const { selWor } = useEditWorker()
  const [sel, setsel] = useState<TurnGlobal | TurnAuto | null>(null)
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const turnAuto = makeTurn(range, selWor as Worker, values)
      turnAuto.customName = sel?.customName
      await setDoc(doc(db, `turnPlan/${turnAuto.uid}`), turnAuto)
      toast.success('Copiado')
      back()
    },
  })
  return (
    <div>
      <ShowAuto state='' sel={sel} setsel={setsel} />
      {
        sel !== null && 
        <Form formikVal={formik}>
          <DefTurns isInCopy={true} needsReset={false} currTurns={sel.turns} />
        </Form>
      } 
      <div className='d-flex justify-content-center'>

        <ActionButton text={'Volver'} action={back} classNames='mt-10' />
      </div>
    </div>
  )
}
