import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { selectUser } from '@redux/features/clientSlice/clientSlice'
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector, useAppDispatch } from '@redux/store/hooks'
import FormField from '@components/formik/FormField'
import { Form } from '@components/formik/Form'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
const initialValues = { birthDate: '' }
const schema = Yup.object().shape({ birthDate: Yup.string()
    .required('Es necesaria una fecha').required('Falta información de cliente').matches(
        /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/,
        'Fecha inválida. Usa el formato dd-mm-yyyy.'
      ),
})

export const UserBirth = () => {

    const [loading, setloading] = useState<boolean>(false)
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            const arr = values.birthDate.split('-').map((value => Number(value)));
            const d = new Date(arr[2], arr[1] -1, arr[0]);
            await updateDoc(doc(db, `users/${user?.uid}`), {
                birthDate: d
            })
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    return (
        <Form formikVal={formik}>
            <FormField type='text' name='birthDate' label='Fecha de nacimiento, formato: dd-mm-yyyy' />
            <div className='d-flex justify-content-center mt-5'>
                <SubmitBtn loading={loading} text='Enviar' />
            </div>
        </Form>
    )
}
