import { db } from '@lib/firebase'
import { Bonus } from '@models/bono/bonus'
import { selectorEditId, setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { deleteDoc, doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { InfoBono } from './InfoBono'
import { PacksBono } from './PacksBono'
import { BonoServices } from './BonoServices'
import { DelOp } from '@components/common/del/DelOp'
import toast from 'react-hot-toast'

export const EditBono = () => {
    const id = useAppSelector(selectorEditId)
    const dispatch = useAppDispatch();
    const [bonus, setbonus] = useState<Bonus | null>(null)
    const delBono = async () => {
        await deleteDoc(doc(db, `bonus/${id}`))
        dispatch(setCloseModal());
        toast.success('Bono eliminado')
    }
    useEffect(() => {
        const sub = onSnapshot(doc(db, `bonus/${id}`), (snap) => {
            const bonos = snap.data() as Bonus;
            setbonus(bonos);
        })
        return () => {
            sub()
        }
    }, [id])

    return (
        <div className='p-4'>
            {
                bonus ?
                    <div>
                        <InfoBono {...bonus} />
                        <PacksBono {...bonus} />
                        {
                            bonus.pers === false &&
                            <BonoServices {...bonus} />
                        }
                        <div className='p-4 mt-5'>
                            <DelOp del={delBono} quest={'¿Quieres eliminar el bono?'} text={'Eliminar'} />
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}
