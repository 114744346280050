import { Acc } from '@components/ui/accordion/Acc'
import { SimpleCard } from '@components/ui/item/SimpleCard'
import { BoldText } from '@components/ui/text/BoldText'
import { Bonus } from '@models/bono/bonus'
import { SessionsPack } from '@models/bono/sessionsPack'
import React, { useState } from 'react'
import { EditPack } from './EditPack'

export const PacksBono = (bono: Bonus) => {
    const [currIndex, setcurrIndex] = useState(0)
    const [pack, setpack] = useState<SessionsPack | null>(null)
    const packSel = (index: number) => {
        setcurrIndex(index)
        setpack(bono.sessionsPack[index])
    }
    const back = () => {
        setpack(null)
    }
    return (
        <Acc title={'Edita los packs'} id={'EditPacks'}>
            {
                pack !== null ?
                <div>
                    <EditPack bono={bono} index={currIndex} pack={pack} pers={bono.pers} back={back} />
                </div>
                :
                <div className='mt-5 mb-5'>
                    <BoldText text={'Selecciona el pack que quieras editar'} classNames='text-center' />
                    <div className='d-flex justify-content-center'>

                        {
                            bono.sessionsPack.map((p, index) => (
                                <div key={index} className='position-relative w-300px me-3 is-pointer'  onClick={() => packSel(index)}>
                                    <SimpleCard
                                        childIcon={
                                            <i className={`${p.servicesLimit ? 'fa fa-paint-brush' : 'fa-solid fa-gift'} text-white`}
                                                style={{ fontSize: 35 }}></i>
                                        }
                                        classNames='w-300px h-auto mt-5'
                                        title={p.name}
                                        text1={p.servicesLimit ?
                                            `${p.servicesLimit.map((v) => `${v.name}(${v.sesNumber})`).join(', ')}`
                                            : `Sesiones a gastar: ${p.sessionPack}`}
                                        text2={`Precio ${p.amountPack} €`}
                                        back={p.servicesLimit ? 'bg-success' : 'bg-info'} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </Acc>
    )
}
