import { IconItem } from '@components/ui/item/IconItem'
import { Field, useField } from 'formik'
import React from 'react'
interface Props {
    title: string,
    subTitle?: string,
    name: string,
    value: string,
    icon?: string,
    className?: string,
}
export const RadioField = ({ title, subTitle, name, value, icon, className }: Props) => {
    const [field] = useField(name);
    return (
        <label className={`d-flex flex-stack mb-5 cursor-pointer ${icon !== undefined ? 'w-200px' : ''} ${className ? className : ''}`}>
            <span className='d-flex align-items-center me-2'>
                {
                    icon !== undefined &&
                    <IconItem icon={icon} />
                }
                <span className='d-flex flex-column'>
                    <span className={`fw-bolder ${field.value === value ? 'text-primary' : 'text-gray-800'} text-hover-primary fs-5`}>
                        {title}
                    </span>
                    {
                        subTitle &&
                        <span className='fs-6 fw-bold text-gray-400'>
                            {subTitle}
                        </span>
                    }
                </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
                <Field className={`form-check-input`} type='radio' name={name} value={value}
                    onMouseDown={(e: any) => {
                        e.preventDefault();
                        e.target.focus();
                        e.target.blur();
                    }}
                />
            </span>
        </label>
    )
}
