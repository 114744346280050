import { db } from "@lib/firebase";
import { Booking } from "@models/bookings/booking"
import { Centers } from "@models/centers/center";
import { Remember } from "@models/remember/remember";
import { UserFinal } from "@models/user/userFinal";
import { doc, getDoc, setDoc, WriteBatch } from "firebase/firestore";
import { calcDate } from "./calcDate";
import { checkPhoneAndEm } from "./checkPhoneAndEm";
import { chooseSerName } from "./chooseSerName";
import { convertPhone } from "./convertPhone";
import * as Sentry from '@sentry/react';

export const createRemember = async (book: Booking, batch: WriteBatch,) => { 
    if (book.StartTime < new Date()) return;
    try {
        const user = (await (getDoc(doc(db, `users/${book.customerUid}`)))).data() as UserFinal;
        const center = (await (getDoc(doc(db, `centers/${book.centerId}`)))).data() as Centers;
        if (user && center) {
            const {template, serviceName} = chooseSerName(book);
            const {dayBefore, sended, sendingDate} = calcDate(book.StartTime);
            const remember: Remember = {
                showingDate: book.showingDate,
                showingHour: book.showingHour,
                centerId: book.centerId,
                email: book.customerEmail,
                name: book.customerName,
                bookId: book.senderUid,
                userUid: book.customerUid,
                dateDue: book.StartTime,
                workerName: book.doubleWorker ? `${book.objDoubleWorker!.workerIdA} + ${book.objDoubleWorker!.workerIdB}` : book.workerId,
                template: template,
                serviceName: serviceName,
                dayBefore: dayBefore,
                sended: sended,
                sendingDate: sendingDate,
                docId: book.senderUid,
                phone: center.wh ? convertPhone(book.customerPhone) : '',
                wh: center.wh
              };
              const isValid = checkPhoneAndEm(remember.phone, user.fakeEmail)
              if (isValid) {
                  batch.set(doc(db, `remembers/${remember.docId}`), {
                      ...remember
                  })
              }
        }        
    } catch (error) {
        Sentry.configureScope(scope => scope.setTransactionName('Create remember failed'))
        Sentry.captureException(error, {user: {id: book.senderUid}});
    }
}