import { SelectField } from '@components/formik/SelectField';
import { RadioField } from '@components/formik/radio/RadioField';
import { ActionButton } from '@components/ui/button/ActionButton';
import { ResCol } from '@components/ui/container/ResCol';
import { BoldText } from '@components/ui/text/BoldText';
import { hoursAday } from '@lib/helpers/turns/hoursAday';
import { Option } from '@models/opts/Option';
import { Turns } from '@models/turns/turns';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
const ds = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
interface Props {
    needsReset: boolean,
    currTurns: Turns[],
    isInCopy?: boolean
}
export const DefTurns = React.memo(({ needsReset, currTurns, isInCopy }: Props) => {
    const [hours, sethours] = useState<Option[]>([]);
    const [turns, setturns] = useState<Turns[]>([]);
    const { values, setFieldValue, handleSubmit, isSubmitting } = useFormikContext<any>();
    const [dayIndex, setdayIndex] = useState(0)
    const [indexEdit, setindexEdit] = useState<null | number>(null)
    const [selTurn, setselTurn] = useState<Turns>(
        {
            day: 'Lunes',
            intWorkerMor1: '',
            intWorkerMor2: '',
            intWorkerAft1: '',
            intWorkerAft2: '',
            rest: ''
        }
    )
    useEffect(() => {
        const t = hoursAday();
        sethours(t.map((v) => ({ label: v, value: v })))
    }, [])
    useEffect(() => {
        if (needsReset === true) {
            setindexEdit(null)
            setturns([])
            setdayIndex(0)
        }
    }, [needsReset])

    useEffect(() => {
        if (currTurns.length > 0 && turns.length === 0) {
            setdayIndex(7)
            setturns(currTurns)
        }
    }, [currTurns, turns])
    


    useEffect(() => {
        if (currTurns.length === 0) {
            setFieldValue('turns', turns)
        }
    }, [turns, setFieldValue, currTurns.length])

    const save = () => {
        const { intWorkerMor1, intWorkerMor2, intWorkerAft1, intWorkerAft2, rest } = values;
        const lacksMorn = (intWorkerMor1 === '' || intWorkerMor2 === '');
        const lacksAft = (intWorkerAft1 === '' || intWorkerAft2 === '');
        if (rest === '') {
            return toast.error('Faltan campos')
        }
        if (rest === 'Descanso' && (lacksMorn || lacksAft)) {
            return toast.error('Faltan campos')
        }
        if (rest === 'Intensivo' && lacksMorn) {
            return toast.error('Faltan campos')
        }
        if (indexEdit !== null) {
            setturns((prevArray) => {
                const newArray = [...prevArray]; // Make a copy of the array
                newArray[indexEdit] = { ...newArray[indexEdit], intWorkerMor1, intWorkerMor2, intWorkerAft1, intWorkerAft2, rest }; // Update the object at the specified index
                return newArray; // Update the state with the updated array
            });
            if (currTurns.length > 0) {
                setFieldValue('day', ds[indexEdit])
                if (isInCopy) {
                    return setindexEdit(null);
                }
                handleSubmit()
            }
            return setindexEdit(null)
        }
        const t: Turns = { ...selTurn, intWorkerMor1, intWorkerMor2, intWorkerAft1, intWorkerAft2, rest }
        const currIndex = ds.findIndex((v) => v === selTurn.day);
        setdayIndex(currIndex + 1)
        setturns((prevArray) => {
            const newArray = [...prevArray, t];
            return newArray;
        });

        setselTurn({
            day: ds[currIndex + 1],
            intWorkerMor1: '',
            intWorkerMor2: '',
            intWorkerAft1: '',
            intWorkerAft2: '',
            rest: ''
        })

    }
    return (
        <div>
            {
                dayIndex > 0 ?
                    <BoldText classNames='text-primary fs-3' text='Haz click en el día que quieras editar' />
                    : null
            }
            {

                dayIndex <= 6 ?
                    <p className='text-primary fs-4'>Horarios para {ds[dayIndex]}</p>
                    :
                    <p className='fs-4'>{currTurns.length > 0 ? 'Edita el horario' : 'Horario completado'}</p>

            }
            <div className='d-flex flex-wrap justify-content-center mb-5'>
                {
                    turns.map((value, i) => (
                        <div key={value.day} className='w-100px d-flex flex-column justify-content-center is-pointer border p-2 me-3 mb-2 rounded-2 ' onClick={() => {
                            if (i === indexEdit) {
                                setindexEdit(null)
                            } else {
                                setindexEdit(i)
                            }
                        }
                        }>
                            <BoldText text={value.day} classNames={`text-center ${indexEdit === i ? ' border-bottom border-bottom-2 border-primary' : ''}`} />
                            {
                                value.intWorkerMor1 ?
                                    <p className='text-muted text-center mb-1'>{value.intWorkerMor1} - {value.intWorkerMor2}</p>
                                    :
                                    <p className='text-muted text-center mb-1'> - </p>

                            }
                            {
                                value.intWorkerAft1 ?
                                    <p className='text-muted text-center mb-1'>{value.intWorkerAft1} - {value.intWorkerAft2}</p>
                                    :
                                    <p className='text-muted text-center mb-1'> - </p>

                            }

                        </div>

                    ))
                }
            </div>
            <div className='bg-light-aqua rounded-2 p-4'>

                <div className='row mb-6 mt-6 '>
                    <label className=' pt-0 col-lg-4 col-form-label required fw-bold fs-6'>Con descanso, intensivo, no trabaja</label>
                    <div className='col-lg-8 fv-row'>
                        <div className='d-flex' >
                            <RadioField className='me-4' title={'Descanso'} name={'rest'} value={'Descanso'} />
                            <RadioField className='me-4' title={'Intensivo'} name={'rest'} value={'Intensivo'} />
                            <RadioField className='me-4' title={'No trabaja'} name={'rest'} value={'Nowork'} />
                        </div>
                    </div>
                </div>
                <ResCol
                    childrenFirst={
                        <>
                            <BoldText text='Intervalo de mañanas' classNames='mb-2' />
                            <div className='d-flex flex-row'>
                                <div className='w-300px'>
                                    <SelectField disabled={values.rest === 'Nowork'} className='me-3' opts={hours} search={true} name={'intWorkerMor1'} placeHolder={''} />

                                </div>
                                <div className='w-300px'>
                                    <SelectField disabled={values.rest === 'Nowork'} opts={hours} search={true} name={'intWorkerMor2'} placeHolder={''} />

                                </div>
                            </div>

                        </>
                    }
                    childrenSecond={
                        <>
                            <BoldText text='Intervalo de tardes' classNames='mb-2' />
                            <div className='d-flex flex-row'>
                                <div className='w-300px'>
                                    <SelectField disabled={values.rest === 'Intensivo' || values.rest === 'Nowork'} className='me-3' opts={hours} search={true} name={'intWorkerAft1'} placeHolder={''} />

                                </div>
                                <div className='w-300px'>
                                    <SelectField disabled={values.rest === 'Intensivo' || values.rest === 'Nowork'} opts={hours} search={true} name={'intWorkerAft2'} placeHolder={''} />

                                </div>
                            </div>
                        </>

                    } />

                <div className='d-flex justify-content-between'>
                    {
                        (dayIndex !== 7 || indexEdit !== null) && currTurns.length === 0 ?
                            <ActionButton text={indexEdit !== null ? 'Editar' : 'Añadir'} action={save} />
                            : null
                    }
                    {
                        currTurns.length > 0 && indexEdit !== null ?
                            <ActionButton text={isInCopy ? 'Cambiar':'Actualizar'} action={save} />
                            : null
                    }
            </div>
                    {
                        isInCopy && <ActionButton classNames='d-block ms-auto me-auto' important={true} disabled={isSubmitting} text={'Guardar'} action={() => {
                            setFieldValue('turns', turns)
                            handleSubmit()
                        }}  />
                    }
            </div>

        </div>
    )
})
