import {BillEditPayload, BillPayload, BillState, TypeSells} from '@models/sells/helpersSells'
import {RootState} from '@redux/store/store'
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState: BillState = {
  outputs: [],
  cleanOutputs: [],
  dayBill: '',
  editBill: {docId: '', item: null, type: '', actions: ''},
  type: null,
  dayArr: '',
  monthPdf: '',
  openModal: false,
}


export const billSlice = createSlice({
  name: 'bill',
  initialState,
  reducers: {
    closeSellsModal: (state) => {
      state.openModal = false;
    },

    setDayBill: (state, action: PayloadAction<string>) => {
      const payload = action.payload
      state.dayBill = payload
    },

    setSumBill: (state, action: PayloadAction<BillPayload>) => {
      const payload = action.payload
      state.outputs = [...state.outputs, ...payload.output]
      state.cleanOutputs = [...state.cleanOutputs, ...payload.cleanOutput]
    },

    setResetBill: (state) => {
      state.cleanOutputs = []
      state.outputs = []
      state.dayBill = ''
    },
    setEditBill: (state, action: PayloadAction<BillEditPayload>) => {
      const payload = action.payload
      state.editBill = {...payload}
      state.openModal = true;
    },
    setType: (state, action: PayloadAction<TypeSells | null>) => {
      const payload = action.payload
      state.type = payload;
    },
    setDayArr: (state, action: PayloadAction<string>) => {
      const payload = action.payload
      state.dayArr = payload;
    },
    setMonthPdf: (state, action: PayloadAction<string>) => {
      const payload = action.payload
      state.monthPdf = payload;
    },

  },
})

export const selectDayBill = (state: RootState) => state.billSlice.dayBill
export const selectMonthPdf = (state: RootState) => state.billSlice.monthPdf
export const selectType = (state: RootState) => state.billSlice.type
export const selectDayArr = (state: RootState) => state.billSlice.dayArr
export const selectModalSells = (state: RootState) => state.billSlice.openModal

export const selectBill = createSelector(
  (state: RootState) => state.billSlice,
  (billSlice) => {
    return {
      output: billSlice.outputs,
      clean: billSlice.cleanOutputs,
    }
  }
)

export const selectTitle = createSelector(
  (state: RootState) => state.billSlice,
  (billSlice) => {
    let title = '';
    const type = billSlice.type;
    if (type === null) {
      title = 'Total'
    } else if (type === 'BOOKS') {
      title = 'Total reservas'
    } else if (type === 'BONUS') {
      title = 'Total bonos'
    } else if (type === 'PRODS') {
      title = 'Total productos'
    } else if (type === 'TICKS') {
      title = 'Total tickets'
    }
    return title
  }
)


export const selectEditBill = createSelector(
  (state: RootState) => state.billSlice,
  (billSlice) => {
    return billSlice.editBill
  }
)

export const {setSumBill, setResetBill, setDayBill, setEditBill, setType, setDayArr, setMonthPdf, closeSellsModal} = billSlice.actions

export default billSlice.reducer
