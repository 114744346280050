import { GlobalContainer } from '@components/common/modal/GlobalContainer'
import { GlobalModal } from '@components/common/modal/GlobalModal'
import { GlobalSpinner } from '@components/ui/spinner/GlobalSpinner'
import store from '@redux/index'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { VersionContext } from '@lib/context/version/VersionCxt'
// https://medium.com/readytowork-org/importing-data-from-firestore-to-emulator-6bcdb83a861c
const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Provider store={store}>
              <VersionContext>
                <GlobalSpinner />
                <GlobalModal>
                  <GlobalContainer />
                </GlobalModal>
                <Outlet />
                <MasterInit />
              </VersionContext>
            </Provider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>

  )
}

export { App }
