import React from 'react'
interface Props {
  text: string,
  subText: string,
  downText: string,
  isAuto: boolean,
  action?: () => void;
  del?: (index: number) => void;
  index?: number
}
export const ColorBox = ({ text, subText, downText, isAuto, action, del, index }: Props) => {
  return (
    <div
      onClick={() => {
        if (action) {
          action()
        }
      }}
      className={`p-3 m-2 d-flex flex-column is-pointer position-relative ${isAuto ? 'bg-sky' : 'bg-light-warning'}`} style={{ width: 145}}>
        {
          del ?
          <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger "style={{ zIndex: 3 }} onClick={() => del(index as number)}></i>
          : null
        }
      <p>{subText}</p>
      <p className={`${isAuto ? 'text-primary' : 'text-warning'}`}>{text}</p>
      <div className={`separator ${isAuto ? 'border-primary' : 'border-warning'}  mb-4`}></div>
      <p>{downText}</p>

    </div>
  )
}
