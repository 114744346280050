import { NavWiz } from '@components/common/wiz/NavWiz'
import { StepWiz } from '@components/common/wiz/StepWiz'
import { Wiz } from '@components/common/wiz/Wiz'
import React, { useEffect } from 'react'
import { SeeCode } from './SeeCode'
import { CreateTicket } from './CreateTicket'

export const ContainerCrTicket = () => {

  
  return (
    <Wiz type='TICKET_FORM'
    navs={
      <>
        <NavWiz isFirst={true} title='Crear ticket' />
        <NavWiz title='Código del ticket' />
      </>
    }
    contentForm={
      <>
        <StepWiz isFirst={true}>
            <CreateTicket />
        </StepWiz>
        <StepWiz>
            <SeeCode />
        </StepWiz>
      </>
    } />
  )
}
