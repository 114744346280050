import { format } from "date-fns";
import { Sell } from "@models/sells/sell";
import { SellsBookings } from "@models/bookings/sells-bookings";
import { PayMeth } from "@models/paymentMetod/payMeth";
import { doRandomUid } from "../random/doRandomUid";
export const trOutputSells = (arr: any[]) => {
    let output: Sell[] = []; 
    output = (arr as SellsBookings[]).map((value) => ({
        docId: (value.docId as string),
        dateTime: value.dateTime,
        uidInCol: value.uidInCol,
        allTypes: ['BOOKS'],
        sells: [{
            name: value.serviceName,
            amount: value.amount,
            workerName: (value.workerName as string),
            type: 'BOOKS',
            orderDate: format(value.dateTime, 'dd-MM-yyyy HH:mm'),
            parentId: (value.docId as string),
            uidInCol: value.uidInCol,
            paymentMethod: (value.paymentMethod as PayMeth),
            userName: (value.userName as string),
            userUid: (value.userUid as string),
            uidFind: doRandomUid(value.centerId),
            col: 'sellsBookings',
        }],
        userUid: (value.userUid as string),
        userName: value.userName,
        centerId: value.centerId,
        cross: false,
        amount: value.amount,
        prepay: value.prepay ? value.prepay : null,
        paymentMethod: (value.paymentMethod as PayMeth),
        email: '',
        cif: '',
        counter: 0,
        postal: '',
        busName: '',
        dir: ''
    }))
    return output
}