import { ConsentSel } from "@components/clients/consent/ConsentSel";
import { SeeSigned } from "@components/clients/consent/SeeSigned";
import { db } from "@lib/firebase";
import { Consent } from "@models/user/consent";
import { useAuth } from "app/modules/auth"
import { collection, onSnapshot, query, where, deleteDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from "@components/formik/Form";
import { SelectField } from "@components/formik/SelectField";
import { Option } from '@models/opts/Option'
import { SubmitBtn } from "@components/formik/SubmitBtn";
import { ClientHead } from "@components/clients/detail/ClientHead";
import toast from 'react-hot-toast'
import * as Sentry from '@sentry/react';

const initialValues = { sel: '' }
const schema = Yup.object().shape({ sel: Yup.string().required('Es necesario elegir') })

const ClientConsent: React.FC = () => {
    const { centerId } = useAuth();
    const [cons, setcons] = useState<Consent[]>([])
    const [conOpts, setconOpts] = useState<Option[]>([]);
    const [selCon, setselCon] = useState<Consent | null>(null);
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            const c = cons.filter(el => el.docId === values.sel)[0];
            setselCon(c);
            setValues({ ...initialValues })
            resetForm()
        },
    })
    useEffect(() => {
        const qConsent = query(collection(db, 'consent'), where('centerId', '==', centerId))
        const sub = onSnapshot(qConsent, (snap) => {
            const docs = snap.docs.map((v) => {
                const q = v.data() as Consent;
                q.docId = v.id;
                return q
            })
            const opts = docs.map((v) => ({ label: v.title, value: v.docId })) as Option[]
            setconOpts(opts)
            setcons(docs)
        })
        return () => {
            sub()
        }
    }, [centerId])

    const consCompleted = () => {
        setselCon(null);
    }

    const delConsent = async () => {
        try {
            setselCon(null)
            await deleteDoc(doc(db, `consent/${selCon?.docId}`))
            formik.resetForm()
            toast.success('Consentimiento eliminado')
        } catch (error) {
            toast.error('Error al eliminar el consentimiento')
            Sentry.configureScope(scope => scope.setTransactionName('Error del consent'))
            Sentry.captureException(error, {user: {id: selCon?.docId}});
        }
    }

    return (
        <div className="">
            <ClientHead isForBack={true} />
            <div className="d-flex justify-content-center">

                <div className="w-300px">
                    <Form formikVal={formik}>
                        <SelectField search={false} opts={conOpts} name={"sel"} placeHolder={"Consentimientos para firmar"} />
                        <div className="mt-3 d-flex justify-content-center">
                            <SubmitBtn loading={false} />
                        </div>
                    </Form>
                </div>
            </div>
            {
                selCon === null ?
                    <SeeSigned />
                    : null
            }
            {
                selCon === null ?
                    null
                    : <ConsentSel delConsent={delConsent} consent={selCon} consCompleted={consCompleted} />
            }


        </div>
    )
}

export default ClientConsent