import { ColorBox } from '@components/ui/item/ColorBox'
import { BoldText } from '@components/ui/text/BoldText'
import { useAutoContext } from '@lib/context/autoWorker/AutoWorCxt'
import { TurnAuto } from '@models/turns/turn-auto'
import { TurnGlobal } from '@models/turns/turnGlobal'
import { selectView } from '@redux/features/uiSlice/uiSlice'
import { useAppSelector } from '@redux/store/hooks'
import React from 'react'
interface Props {
  sel: TurnAuto | TurnGlobal | null,
  setsel: React.Dispatch<React.SetStateAction<TurnAuto | TurnGlobal | null>>,
  state?: string
}
export const ShowAuto = ({ sel, setsel, state }: Props) => {
  const view = useAppSelector(selectView)
  const { autos, globs } = useAutoContext()
  return (
    <div>

      {
        sel === null ?
          <div>
            {
              view === 'EDIT_GLOB' || state !== undefined ?
              <div>
                  {
                    globs.length > 0 ?
                      <BoldText text='Horarios plantilla' />
                      : <p className='text-center text-muted'>No has creado ninguna plantilla</p>
                  }
                  <div className='d-flex flex-wrap'>
                    {
                      globs.map((value) => (
                        <ColorBox action={() => setsel(value)} key={value.uid} text={value.customName as string} subText={'Horario plantilla'} downText={''} isAuto={false} />
                      ))
                    }
                  </div>
              </div>
              : null
            }
            {
              view === 'AUTO' ?
              <div>
                  {
                    autos.length > 0 ?
                      <BoldText text='Horarios del emplead@' />
                      : <p className='text-center text-muted'>No has creado ningun horario auto</p>

                  }
                  <div className='d-flex flex-wrap'>
                    {
                      autos.map((value) => (
                        <ColorBox action={() => setsel(value)} key={value.uid} text={value.customName as string} subText={'Horario personalizado'} downText={value.firstDate as string} isAuto={true} />
                      ))
                    }
                  </div>
              </div>
              : null
            }
          </div>
          :
          <div>
            <div className='d-flex justify-content-center'>
              {
                'workerDoc' in sel ?
                  <ColorBox key={sel.uid} text={sel.customName as string} subText={'Horario personalizado'} downText={sel.firstDate as string} isAuto={true} />
                  :
                  <ColorBox key={sel.uid} text={sel.customName as string} subText={'Horario plantilla'} downText={''} isAuto={false} />
              }
            </div>
          </div>
      }
    </div>
  )
}
