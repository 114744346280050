import { db } from '@lib/firebase';
import { Bonus } from '@models/bono/bonus';
import { getDocs, query, collection, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react'
import { Option } from "@models/opts/Option";

export default function useBonoSells(centerId: string) {

    const [bonus, setBonus] = useState<Bonus[]>([])
    const [bonusOpts, setBonusOpts] = useState<Option[]>([])

    const fetchProds = useCallback(
        async () => {
            const t = (await getDocs(query(collection(db, 'bonus'),
                where('centerId', '==', centerId)))).docs.map((value) => {
                    const data = value.data();
                    data.bonusId = value.id
                    return data
                }) as Bonus[];
            setBonus(t)
        },
        [centerId],
    )

    useEffect(() => {
        let active = true;
        if (active === true) {
            fetchProds()
        }
        return () => {
            active = false;
        }
    }, [fetchProds])

    useEffect(() => {
        if (bonus.length > 0) {
            const o = bonus.map((value) => ({label: value.nameBonus, value: value.bonusId}))
            setBonusOpts(o)
        }
    }, [bonus])

    return {
        bonusOpts,
        bonus
    }

}
