import { db } from "@lib/firebase";
import { Booking } from "@models/bookings/booking"
import { addHours } from "date-fns";
import {  collection, doc, WriteBatch } from "firebase/firestore"
import { retStopper } from "../stopper/retStopper";
const clone = require('clone-deep')
export const addSimpleBook = (
    argBook: Booking,
    addCanary: boolean,
    batch:  WriteBatch,
) => {
    const bookCol = collection(db, 'bookings');
    const refBook = doc(bookCol);
    const book = clone(argBook) as Booking;
    if (addCanary) {
        book.StartTime = addHours(book.StartTime, 1)
        book.EndTime = addHours(book.EndTime, 1)
    }
    book.idBook = refBook.id;
    const stop = retStopper(book)
    batch.set(doc(db, 'stopper', book.idBook), stop)
    batch.set(refBook, book)

}