import React, { useEffect, useState } from 'react'
interface Props {
    url?: string,
    name: string,
    back?: string,
    colorLetter?: string
}
export const BoxPhoto = ({ url, name, back, colorLetter }: Props) => {
    const [color, setcolor] = useState<string>('');
    useEffect(() => {
        if (back) {
            setcolor(back)
        } else {
                let rawColor = ''
                const colors = ['success', 'danger', 'warning', 'primary-active']
                for (var i = 0; i < 1; i++) {
                    rawColor = colors[Math.floor(Math.random() * colors.length)];
                }
                setcolor(rawColor)
        }
    }, [back])

    return (
        <div >
            <div className='symbol symbol-50px'>
                {!url ? (
                    <span className={`symbol-label bg-${color} fs-5 fw-bolder`}>
                        <p className={`mb-0 ${colorLetter ? colorLetter : 'text-white'}`}>{name.charAt(0).toLocaleUpperCase()}</p>
                    </span>
                ) : (
                    <div className='box-photo rounded-2' style={{ backgroundImage: `url(${url})` }} />
                )}
            </div>
        </div>
    )
}
