import { BonusSell } from '@components/common/wiz/wiztypes/BonoSellsWizHelper';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import { Ticket } from '@models/ticket/ticket';
import { UserFinal } from '@models/user/userFinal';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice';
import { FormMeth } from '@components/formik/formMeth/FormMeth';
import { FormTicket } from '@components/formik/formMeth/FormTicket';
import { BoldText } from '@components/ui/text/BoldText';
import useAddSellsBonus from '@lib/hooks/sells/useAddSellsBonus';
import useTicketSells from '@lib/hooks/sells/useTicketSells';
import { useAppDispatch, useAppSelector } from '@redux/store/hooks';
import { doc, updateDoc } from 'firebase/firestore';
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import toast from 'react-hot-toast';
import { db } from '@lib/firebase';
import FormField from '@components/formik/FormField';
import { othersIcon } from '@assets/sass/bootstrapEx';
import { RadioField } from '@components/formik/radio/RadioField';


export const StepBonus3 = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const { makePriceTicket } = useTicketSells();
  const { addSellBonus, } = useAddSellsBonus()
  const { setFieldValue, values } = useFormikContext<BonusSell>();
  const total = values.finalPrice
  const processTicket = async (ticket: Ticket) => {
    const opTicket = makePriceTicket(ticket, total);
    if (opTicket) {
      const { restAmount, restTicket, ticketId } = opTicket;
      if (restAmount > 0) {
        setFieldValue('finalPrice', restAmount)
        toast.success('Selecciona cómo pagar el resto, el ticket ya ha sido gastado')
      } else {
        const isGood = await addSellBonus(values, user as UserFinal, 'Ticket')
        if (isGood) {
          dispatch(setCloseModal())
          toast.success('Venta realizada')
        } else {
          toast.error('Error al crear la venta')
        }
      }
      await updateDoc(doc(db, `tickets/${ticketId}`), {
        amount: restTicket
      })
    }
  }
  useEffect(() => {
    if (values.packSel && values.simple === 'Simple') {
      setFieldValue('finalPrice', Number(values.packSel.amountPack))
    }
  }, [values.packSel, values.simple, setFieldValue])

  useEffect(() => {
    if (values.simple === 'Frac') {
      setFieldValue('finalPrice', Number(values.firstPayment))
    }
  }, [values.firstPayment, values.simple, setFieldValue])

  return (
    <div className='w-100'>
      {
        values.packSel ?
          <>
            {
              values.simple === 'Frac' ?
                <BoldText classNames='text-success text-center fs-2' text={`Total: ${Number(values.packSel.amountPack).toFixed(2)} €`} />
                : null
            }
            <BoldText classNames='text-success text-center fs-2' text={`A pagar:  ${total.toFixed(2)} €`} />
          </>
          : null
      }
      {
        values.simple === 'Frac' ?
          <>
            {
              values.packSel ?
                <BoldText classNames='text-warning fs-3' text={`Falta por pagar: ${Number(values.packSel.amountPack) - values.finalPrice}`} />
                : null
            }
            <FormField name={'firstPayment'} label={'Primer pago'} type='text' />
          </>
          : null
      }
      {
        values.meth === 'Ticket' ?
          <FormTicket processTicket={processTicket} />
          :
          <>
            {
              total > 0 ?
                <FormMeth total={total} />
                : 
                <div className='d-flex justify-content-center'>
                    <RadioField className='m-3' title='Otros' name='meth' value='Otros'
                    icon={othersIcon}
                    />
                </div>
            }
          </>
      }
    </div>
  )
}
