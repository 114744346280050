import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Admin } from '@models/user/admin'
import { Acc } from '@components/ui/accordion/Acc'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitField } from '@components/formik/SubmitField'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { callCloud } from '@lib/helpers/callCloud'
import toast from 'react-hot-toast'
import { ChangeNots } from './ChangeNots'
import * as Sentry from '@sentry/react';

const schema = Yup.object().shape({ 
    adminMail: Yup.string().email('El formato del email debe ser correcto').required('Es necesario un email'),
    password: Yup.string().required('Es necesaria una nueva contraseña').min(10, 'Mínimo 10 carácteres')
})
export const FormAdmin = (admin: Admin) => {
    const [load, setload] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: { adminMail: admin.adminMail, password: '' },
        validationSchema: schema,
        onSubmit: async () => { }
    })
    const changeAdminEmail = async () => {
        setload(true);
        const newEmail = formik.values.adminMail;
        try {
            const changeEmailUser = callCloud('changeEmailUser');
            await changeEmailUser({ uid: admin.uid, newEmail: newEmail })
            toast.success('Cambio realizado')
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error changeEmailUser'))
            Sentry.captureException(error, {user: {id: admin.uid}});
            toast.error('Error al cambiar el email, puede que este email ya esté cogido por otro administrador')
        }
        setload(false)
    }
    const changePass = async () => {
        try {
            const newPass = formik.values.password;
            const changePasswordUser = callCloud('changePasswordUser');
            changePasswordUser({ uid: admin.uid, newPass: newPass })
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('changePasswordUser'))
            Sentry.captureException(error, {user: {id: admin.uid}});
            toast.error('Error al cambiar el password')

        }

    }
    return (
        <div>
            <Acc title={`Información de ${admin.adminName}`} id={'InfoAdmin'} classNames='mb-5'>
                <Form formikVal={formik}>
                    <FormField name={'adminMail'} label={'Cambia el correo electrónico'} type={'text'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'adminMail'} loading={load} handleSubmit={changeAdminEmail} />
                    </div>
                    <FormField isPassword={true} name={'password'} label={`Cambia el password: ${admin.password}`} type={'text'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'password'} loading={load} handleSubmit={changePass} />
                    </div>
                </Form>
            </Acc>
            <Acc title={'¿Qué notificaciones quieres recibir?'} id={'NotsAdmin'}  >
                <ChangeNots admin={admin} />
            </Acc>
        </div>
    )
}
