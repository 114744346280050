import { Acc } from '@components/ui/accordion/Acc'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Services } from '@models/services/services'
import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { callCloud } from '@lib/helpers/callCloud'
import toast from 'react-hot-toast'
import { collection, getCountFromServer, query, where } from 'firebase/firestore'
import { db } from '@lib/firebase'
interface Props {
    ser: Services
}
const schema = Yup.object().shape({ newName: Yup.string().required('Es necesario un nombre') })
export const EditSerName = ({ ser }: Props) => {
    const initialValues = { newName: '' }
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            const rewNameService = callCloud('rewNameService')
            const newName = values.newName;
            const oldName = ser.serviceName;
            const serviceId = ser.serviceId;
            const centerId = ser.centerId;
            const q = query(collection(db, 'services'), where('serviceName', '==', newName), where('centerId', '==', ser.centerId));
            const count = await getCountFromServer(q);
            const servicesNamedSame = count.data().count;
            if (servicesNamedSame > 0) {
                toast.error('El nombre del servicio debe de ser único');
                setloading(false);
                return;
            }
            try {
                await rewNameService({newName, oldName, centerId, serviceId})
                toast.success('Nombre cambiado');
            } catch (error) {
                toast.error('El nombre no ha podido cambiar');
            }
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    return (
        <Acc title={`Editar nombre de ${ser.serviceName}`} id={'name'} classNames='mb-3 mt-3'>
            <Form formikVal={formik}>
                <p>El nombre también cambiará en: <strong>citas del futuro, bonos en venta</strong>.</p>
                <InlineInput name='newName' label={'Nuevo nombre del servicio'} />
                <div className={`${JustEnd} mt-3`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </Acc>
    )
}
