import React from 'react'

interface Props {
    title: string
    isFirst?: boolean,
}

export const NavWiz = ({ title, isFirst,  }: Props) => {
    return (
        // && indexStepper === 0 
        <div
            className={`stepper-item ${isFirst === true ? 'current' : ''}`}
            data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{title}</h3>
        </div>
    )
}