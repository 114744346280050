import { ModalView, switchTitles } from '@redux/types/ui/modalViews'
import React from 'react'
interface PropsTitle {
    view: ModalView
}
export const ModalTitle = ({view}: PropsTitle) => {
    const title = switchTitles(view)
  return (
    <h5 className="modal-title text-white">{title}</h5>
  )
}
