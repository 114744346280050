import { SelImg } from '@components/formik/img/SelImg'
import { Acc } from '@components/ui/accordion/Acc'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Form } from '@components/formik/Form'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
interface Props {
    url: string,
    serviceId: string
}
const schema = Yup.object().shape({ img: Yup.string().required('Es necesaria una imagen') })
export const ImgSer = ({ serviceId, url }: Props) => {
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: { img: url },
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            await updateDoc(doc(db, `services/${serviceId}`), {
                img: values.img
            })
            toast.success('Cambiado')
            setloading(false)
        },
    })
    return (
        <Acc title={'Cambia la foto'} id={'Photo'} classNames='mb-3 mt-3'>
            <Form formikVal={formik}>
                <SelImg name='img' />
                <div className={`${JustEnd} mt-5`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </Acc>
    )
}
