import React from 'react'
interface Props {
    children: React.ReactNode
}
export const TRPar = ({children}: Props) => {
  return (
    <tr>
        {children}
    </tr>
  )
}
