import { setCloseModal, selectorModalView, setMinModal, selectorWarn } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { FC } from 'react';
import { ModalTitle } from './ModalTitle';
type BoxProps = {
    children: React.ReactNode;
};

export const GlobalModal: FC<BoxProps> = ({children}) => {
    const {display, view} = useAppSelector(selectorModalView);
    const dispatch = useAppDispatch();
    const warn = useAppSelector(selectorWarn)
    const notPaid = view === 'DATE_DUE' && warn === false;
    const isRewrite = view === 'IS_REWRITE' || view === 'NEW_VERSION'
    const close = () => {
        if (view === 'BIG_BOOK') {
            dispatch(setMinModal())
        } else {
            dispatch(setCloseModal())
        }
    }

    return (
        <div
            className={`modal ${display ? 'd-block' : 'd-none'}`}
            id="global_modal"
            data-keyboard="false"
            data-backdrop="static"
            tabIndex={-1} role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ backgroundColor: '#00000070' }}
        >
            <div className="modal-dialog modal-dialog-centered mw-700px" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-aqua" style={{padding: 10, paddingLeft: 25}}>
                        <ModalTitle view={view} />
                        {
                            notPaid || isRewrite ?
                            null
                            :
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
                                <i className={`fa-solid ${view === 'BIG_BOOK' ? 'fa-minus' : 'fa-x'} text-white fs-2`}></i>
                            </div>
                        }
                    </div>
                    <div className='modal-body py-lg-0 px-lg-0'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
