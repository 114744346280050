import { tarIcon, efIcon, crossIcon, othersIcon, ticketIcon } from '@assets/sass/bootstrapEx'
import { BoldText } from '@components/ui/text/BoldText'
import { useEffect } from 'react'
import FormField from '../FormField'
import { RadioField } from '../radio/RadioField'
import { useFormikContext } from 'formik'
import { PayMeth } from '@models/paymentMetod/payMeth'
type Props = {
    total: number
}
export const FormMeth = ({ total }: Props) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const efQntValue = values.efQnt;
    const tarQntValue = values.tarQnt;
    const meth = values.meth as PayMeth;
    useEffect(() => {
        const isNum = typeof Number(efQntValue) === 'number';
        if (meth === 'Mixto' && isNum) {
            const ef = Number(efQntValue);
            const tar = total - ef;
            setFieldValue('tarQnt', tar)
        } else if (meth === 'Efectivo' && isNum) {
            const change = Number(efQntValue) - total;
            if (change > 0) {
                setFieldValue('changeMoney', change)
            } else {
                setFieldValue('changeMoney', 0)
            }
        }
    }, [efQntValue, meth, total, setFieldValue])
    return (
        <div>
            <div className='d-flex flex-wrap flex-row align-items-center justify-content-center'>
                <RadioField className='m-3' title='Tarjeta de crédito' name='meth' value='Tarjeta de crédito'
                    icon={tarIcon}
                />
                <RadioField className='m-3' title='Efectivo' name='meth' value='Efectivo'
                    icon={efIcon}
                />
                <RadioField className='m-3' title='Mixto' name='meth' value='Mixto'
                    icon={crossIcon}
                />
                <RadioField className='m-3' title='Otros' name='meth' value='Otros'
                    icon={othersIcon}
                />
                <RadioField className='m-3' title='Ticket' name='meth' value='Ticket'
                    icon={ticketIcon}
                />
            </div>
            {
                values.meth === 'Efectivo' || values.meth === 'Mixto' ?
                    <FormField name={'efQnt'} label={`Efectivo`} type={'text'} />
                    : null
            }
            {
                values.meth === 'Mixto' ?
                    <div>
                        <BoldText classNames='text-success fs-3' text={`Paga:  ${tarQntValue > 0 ? tarQntValue.toFixed(2) : '0'} € en tarjeta`} />
                    </div>
                    : null
            }
            {
                values.meth === 'Efectivo' ?
                    <div>
                        <BoldText classNames='text-success fs-3' text={`El cambio es de ${values.changeMoney.toFixed(2)} €`} />
                    </div>
                    : null
            }
        </div>
    )
}
