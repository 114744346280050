import { BonoForm } from '@components/common/wiz/wiztypes/CreateBonoWiz';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import { Pers } from './Pers';
import { Norm } from './Norm';

export const StepPack = () => {
    const { setFieldValue, values } = useFormikContext<BonoForm>();
    useEffect(() => {
        setFieldValue('services', [])
        setFieldValue('sessionsPack', [])
    }, [values.pers, setFieldValue])
    
    return (
        <div className='w-100'>
            {
                values.pers ?
                    <Pers />
                    :
                    <Norm />
            }
        </div>
    )
}
