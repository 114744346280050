import { ActionButton } from '@components/ui/button/ActionButton';
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
export const CounterComplete = () => {
    const counter = useAppSelector(selectSellsCounter);
    const [isCompleted, setisCompleted] = useState(true);
    const nav = useNavigate()
    useEffect(() => {
      if (counter !== null) {
        setisCompleted(counter.completed);
      }
    
    }, [counter])
    const goComplete = () => {
        nav('/ventas')
    }
    
    return (
        <>
            {
                !isCompleted ?
                <ActionButton classNames='me-3' isDanger={true} text='Sistema de ventas incompleto' action={goComplete} />
                : null
            }
        </>
    )
}
