import { useFormikContext, useField } from 'formik';

interface SubmitProps {
  text?: string;
  name: string;
  loading: boolean,
  classNames?: string
  handleSubmit: () => void
}

export const SubmitField = ({ text, name, loading, classNames, handleSubmit }: SubmitProps) => {
  const { isSubmitting, initialValues, } = useFormikContext<any>();
  const [field, meta] = useField(name);
  const isFieldDirty = JSON.stringify(field.value) !== JSON.stringify(initialValues[name]);
  const isValid = !meta.error && isFieldDirty;

  return (
    <>
      <button
        onClick={handleSubmit}
        type='button'
        className={`btn btn-est text-white ${classNames ? classNames : ''}`}
        disabled={isSubmitting || !isValid}
      >
        {!loading && <span className='indicator-label'>{text ? text : 'Continuar'}</span>}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Espera...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </>
  );
};