/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import logo from '@assets/corp/Imago.png'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { fireAuth } from '@lib/firebase'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { ForgotPassword } from './ForgotPassword'
import toast from 'react-hot-toast'
import { Form } from '@components/formik/Form'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Esto no es un email válido...')
    .required('Es necesario un email'),
  password: Yup.string()
    .required('Es necesaria una contraseña'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const [isReset, setisReset] = useState<boolean>(false);
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      try {
        await signInWithEmailAndPassword(fireAuth, values.email, values.password);
      } catch (error) {
        toast.error('Email o password incorrectos');
        setLoading(false)
        return;
      }
      setLoading(false)
      resetForm({ values: { ...initialValues }, errors: {}, touched: {} })
    },
  })
  const toggleReset = () => {
    setisReset(!isReset);
  }

  return (
    <div className='d-flex flex-column align-self-center w-sm-50 p-10 order-2 order-lg-1 h-100'>
      {
        !isReset ?
            <Form formikVal={formik}>
              {/* begin::Heading */}
              <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Bienvenid@</h1>
              </div>
              {/* begin::Heading */}

              {/* begin::Separator */}
              <div className='separator separator-content my-14'>
                <img
                  alt='Logo'
                  src={logo}
                  className='h-50px'
                />
              </div>
              {/* end::Separator */}
              <div className='fv-row mb-8'>
                <FormField
                  type='email'
                  name='email'
                  label='Email'
                />
              </div>
              <div className='fv-row mb-8'>
                <FormField
                  type='password'
                  name='password'
                  label='Contraseña'
                  isPassword={true}
                />
              </div>

              {/* begin::Wrapper */}
              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                {/* begin::Link */}
                <a href="#" className='link-primary pe-auto text-center' onClick={toggleReset}>
                  ¿Has olvidado la contraseña?
                </a>
                {/* end::Link */}
              </div>
              {/* end::Wrapper */}

              {/* begin::Action */}
              <div className='d-grid mb-10'>
                <SubmitBtn loading={loading} />
              </div>
              {/* end::Action */}
            </Form>
          : <ForgotPassword toggleReset={toggleReset} />
      }


    </div>

  )
}
