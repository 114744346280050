import { db } from '@lib/firebase';
import { useAuth } from 'app/modules/auth';
import { collection, doc, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useEffect } from 'react'
import {Worker} from '@models/user/worker';
import { useAppDispatch } from '@redux/store/hooks';
import { setCat, setCenter, setMachines, setRec, setSellsCounter, setServices, setWorkers } from '@redux/features/gquerySlice/gquerySlice';
import { Centers } from '@models/centers/center';
import { Services } from '@models/services/services';
import { Machine } from '@models/machines/machine';
import { Cat } from '@models/services/serviceCat';
import { setInitSched } from '@redux/features/calSlice/calSlice';
import * as Sentry from '@sentry/react';
import { SellsCounter } from '@models/sells/sellsCounter';
import { RecCenter } from '@models/recCenter/recCenter';

export const QueryEmitter = () => {
    const {centerId} = useAuth();
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (centerId !== '') {
          const q = query(
              collection(db, 'users'),
              where('centerWorkerName', '==', centerId),
              where('role', 'array-contains', 'worker')
          );
          const unsub = onSnapshot(q, (snap) => {
              const workers = snap.docs.map((value) => value.data()) as Worker[];
              dispatch(setWorkers(workers));
          })
          return () => unsub();
        }
    }, [centerId, dispatch]);

    useEffect(() => {
        if (centerId !== '') {
            const d = doc(db, `centers/${centerId}`);
            const unsub = onSnapshot(d, (snap) => {
              const reads = snap.data() as Centers;
              if (reads) {
                delete reads.geo
                dispatch(setCenter(reads))
                dispatch(setInitSched(reads.rawCenterTime.some(el => el.dayCenter === 'Domingo' && el.openCenter === 'Abierto')))
              } else {
                Sentry.captureMessage(`Query emitter error ${centerId}`)
              }
            })
            return () => unsub()
        }
      }, [centerId, dispatch]);

      useEffect(() => {
        if (centerId !== '') {
            const qSer = query(collection(db, 'services'), where('centerId', '==', centerId), orderBy('serviceName', 'asc'));
            const unsub = onSnapshot(qSer, (snap) => {
                const services = snap.docs.map((value) => {
                  const data = value.data();
                  data.serviceId = value.id;
                  return data
                }) as Services[]
                dispatch(setServices(services))
            })
            return () => unsub()
        }
      }, [centerId, dispatch]);

      useEffect(() => {
        if (centerId !== '') {        
          const qMach = query(collection(db, 'machine'), where('centerId', '==', centerId))
          const unsub = onSnapshot(qMach, (snap) => {
            const machines = snap.docs.map((value) => value.data()) as Machine[];
            dispatch(setMachines(machines))
          })
          return () => {
            unsub()
          }
        }
      }, [centerId, dispatch])

      useEffect(() => {
        if (centerId !== '') {        
            const qCats = query(collection(db, 'cat'), where('center', '==', centerId))
            const unsub = onSnapshot(qCats, (snap) => {
              const cats = snap.docs.map((value) => value.data()) as Cat[];
              dispatch(setCat(cats))
            })
            return () => {
              unsub()
            }
        }
      }, [centerId, dispatch])

      useEffect(() => {
        if (centerId !== '') {        
            const qCounter = query(collection(db, 'sellsCounter'), where('centerId', '==', centerId))
            const unsub = onSnapshot(qCounter, (snap) => {
              const counter = snap.docs.map((value) => value.data())[0] as SellsCounter;
              dispatch(setSellsCounter(counter))
            })
            return () => {
              unsub()
            }
        }
      }, [centerId, dispatch])

      useEffect(() => {
        if (centerId !== '') {
            const unsub = onSnapshot(doc(db, `recCenters/${centerId}`), (ob) => {
              if (ob.exists()) {
                const d = ob.data() as RecCenter
                dispatch(setRec(d.is_init))
              } else {
                dispatch(setRec(false))
              }
            })         
            return () => {
              unsub()
            }
        }
      }, [centerId, dispatch])
      
      




  return null
}
