import { Turns } from "@models/turns/turns"
import { switchDaysForCalendar } from "../dates/switchDaysForCalendar";

export const getNoWorkWeeks = (turns: Turns[]) => {
    const disableWeekDays: string[] = [];
    const noWork = turns.filter(el => el.rest === 'Nowork')
    noWork.forEach(el => {
      const weekDay = switchDaysForCalendar(el.day)
      disableWeekDays.push(weekDay)
    });
    return disableWeekDays; 
}