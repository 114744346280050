import { db } from "@lib/firebase"
import { Cat } from "@models/services/serviceCat"
import { useAuth } from "app/modules/auth"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Option } from "@models/opts/Option";

export default function useCats() {
    const { centerId } = useAuth()
    const [cats, setcats] = useState<Cat[]>([])
    const [catOpt, setcatOpt] = useState<Option[]>([]);

    useEffect(() => {
        const qCat = query(collection(db, 'cat'), where('center', '==', centerId))
        const sub = onSnapshot(qCat, (snap) => {
            const docs = snap.docs.map((value) => {
                const c = value.data() as Cat;
                c.uid = value.id;
                return c
            }) as Cat[];
            setcats(docs)
        })
        return () => {
            sub()
        }
    }, [centerId])

    useEffect(() => {
        if (cats.length > 0) {
            const o = cats.map((value) => ({label: value.name, value: value.name, id: value.uid}))
            setcatOpt(o)
        }
    }, [cats])
    


    return {
        cats, catOpt
    }

}