import { Spinner } from '@components/ui/spinner/Spinner';
import useFetchAsk from '@lib/hooks/calendar/askEm/useFetchAsk';
import { selectNeed } from '@redux/features/calSlice/calSlice';
import { useAppSelector } from '@redux/store/hooks';
import { AskEmAction } from './AskEmAction';


export const AskEmContainer = () => {
  const {userUid, centerId, currEm, senderUid, idBook} = useAppSelector(selectNeed) 
  const { loaded, user, center } = useFetchAsk(userUid, centerId)

  return (
    <div className=' d-flex justify-content-center flex-column p-4'>
      {
        !loaded ?
          <Spinner />
          : null
      }
      {
        loaded && user && center ?
          <AskEmAction center={center}
           user={user}
           currEm={currEm}
           senderUid={senderUid}
           idBook={idBook}
           />
          :
          null
      }
      {
        loaded && !user && !center ?
          <p className="text-center">
            No se ha podido encontrar el {!user ? 'usuario' : 'centro'}
          </p>
          :
          null
      }
    </div>
  )
}
