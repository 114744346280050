import React from 'react'
interface Props {
    id: string,
    show: boolean,
    children: React.ReactNode,
    maxW?: string
}
export const ModalShow = ({ id, show, children, maxW }: Props) => {
    return (
        <div
            className={`modal ${show ? 'd-block' : 'd-none'}`}
            id={id}
            data-keyboard="false"
            data-backdrop="static"
            tabIndex={-1} role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ backgroundColor: '#00000070' }}
        >
            <div className={`modal-dialog modal-dialog-centered ${maxW ? maxW : 'mx-700px'}` }role="document">
                <div className={`modal-content `}> 
                    {
                        children
                    }
                </div>
            </div>
        </div>
    )
}
