import { Admin } from "@models/user/admin";
import { AuthUser } from "@models/user/AuthUser"
/* TODO (FUTURE): QUIT CASE ADMIN */

export const switchUserAuth = (doc: any) => {
    try {
        const authUser: AuthUser = {
            centerId: '',
            name: '',
            uid: '',
            role: [],
            email: '',
            canary: false,
            centers: []
    
        }
        if (doc.role.includes('superAd') || doc.role.includes('worker')) {
            authUser.centerId = doc.centerWorkerName;
            authUser.name = doc.name;
            authUser.uid = doc.uid;
            authUser.role = [...doc.role];
            authUser.email = doc.mail;
        } else  if (doc.role.includes('admin')){
            const ad: Admin = doc;
            authUser.centerId = ad.centerWorkerName;
            authUser.name = ad.adminName;
            authUser.uid = ad.uid;
            authUser.role = [...doc.role];
            authUser.email = doc.adminMail;
            if (doc.centers) {
                authUser.centers = doc.centers;
            }
        } 
    
        return authUser;
        
    } catch (error) {
        return null;
    }
}