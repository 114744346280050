import { Booking } from "@models/bookings/booking";
import { TimeV2 } from "@models/bookings/timeV2";
import { Machine } from "@models/machines/machine";
import { validateOneTime } from "./validateOneTime";
import { getMachineBookings } from "./getMachineBookings";

export const aparCheck = (time: TimeV2, machines: Machine[], bookings: Booking[]) => {
    let valid = true;
    const mirrStock = [];
    if (time.service.apar) {
        const machFilt = machines.filter(el => el.servicesIds.find(mu => mu.id === time.service.serviceId))[0];
        const stock = machFilt.sesApar;
        const otherWorkerApar = getMachineBookings(machFilt, time.worker, bookings);
        if (otherWorkerApar.length !== 0) {
            otherWorkerApar.forEach(b => {
                const valid = validateOneTime(time, [b]);
                if (!valid.valid) {
                    mirrStock.push(time);
                }
            });
            if (stock <= mirrStock.length) {
                valid = false;
            }
        }
    }
    return valid;
}