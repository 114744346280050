import { BonusSell } from '@components/common/wiz/wiztypes/BonoSellsWizHelper';
import { useFormikContext } from 'formik';
import { SelectField } from '@components/formik/SelectField';
import { ResCol } from '@components/ui/container/ResCol';
import { RadioBoxContainer } from '@components/formik/radio/box/RadioBoxContainer';
import { RadioBox } from '@components/formik/radio/box/RadioBox';
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import { useAppSelector } from '@redux/store/hooks';
import React, { useEffect } from 'react'
import useWorkersSells from '@lib/hooks/sells/useWorkersSells';
import useBonoSells from '@lib/hooks/sells/useBonoSells'
import { RadioField } from '@components/formik/radio/RadioField';
import { RadioContainer } from '@components/formik/radio/RadioContainer';

export const StepBonus1 = () => {
    const { setFieldValue, values } = useFormikContext<BonusSell>();
    const user = useAppSelector(selectUser);
    const { bonus, bonusOpts } = useBonoSells(user?.centerClient as string)
    const { worOpts } = useWorkersSells()
    useEffect(() => {
        if (values.bonoId !== '') {
            const p = bonus.filter(el => el.bonusId === values.bonoId)[0];
            setFieldValue('selBonus', p)
        } else {
            setFieldValue('selBonus', null)
        }
    }, [values.bonoId, bonus, setFieldValue])
    useEffect(() => {
        if (values.indexPack !== null) {
            const i = Number(values.indexPack)
            const pack = values.selBonus?.sessionsPack[i];
            setFieldValue('packSel', pack)
        } else {
            setFieldValue('packSel', null)
        }
    }, [values.indexPack, setFieldValue, values.selBonus])
    return (
        <div className='w-100'>
            <div className='mb-5'>
                <RadioContainer title='Selecciona el pago'>
                    <RadioField icon=' fa-solid fa-circle' name={'simple'} value={'Simple'} title={'Pago simple'} />
                    <RadioField icon=' fa-solid fa-circle-half-stroke' name={'simple'} value={'Frac'} title={'Pago fraccionado'} />
                </RadioContainer>
            </div>
            <ResCol
                childrenFirst={
                    <SelectField name='workerName' opts={worOpts} search={false} placeHolder={'Facturación'} />
                }
                childrenSecond={
                    <SelectField name='bonoId' opts={bonusOpts} search={true} placeHolder={'Buscar bono...'} />
                }
            />
            <RadioBoxContainer>
                {
                    values.selBonus !== null ?
                        values.selBonus.sessionsPack.map((value, index) => (
                            <RadioBox key={index} name={'indexPack'} value={`${index}`} title={`${value.name} por ${value.amountPack} € `} />
                        ))
                        : null
                }
            </RadioBoxContainer>
        </div>
    )
}
