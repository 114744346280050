import { db } from "@lib/firebase"
import { SellsBonus } from "@models/bono/sellsBonus"
import { collection, doc, getDoc,  getDocs,  query,  where,  writeBatch } from "firebase/firestore"
import { commitBatch } from "../common/commitBatch";
import { Sell } from "@models/sells/sell";
import { Ticket } from "@models/ticket/ticket";
import { deleteSellsTicket } from "./deleteSellsTicket";

export const deleteSellsBonus = async (bonus: SellsBonus) => {
    const batch = writeBatch(db);
    const queryTicket = (await getDocs(query(collection(db, 'tickets'), where('code', '==', bonus.name), where('centerId', '==', bonus.centerId))))
    const thereisTicket = queryTicket.size > 0;
    let relatedByuidFindArr: Sell | null = null;
    if (bonus.uidFind) {
        const q = query(collection(db, 'sells'), where('uidFindArr', 'array-contains', bonus.uidFind))
        const isUidFind = (await getDocs(q))
        if (isUidFind.size > 0) {
            relatedByuidFindArr = isUidFind.docs.map((v) => v.data())[0] as Sell;
        }
    }
    if (thereisTicket) {
        const t = queryTicket.docs[0].data() as Ticket;
        await deleteSellsTicket(t, bonus.id, batch)
        const isDel = await commitBatch(batch)
        return isDel
    }
    if (relatedByuidFindArr !== null) {
        if (relatedByuidFindArr.cross === true) {
            const related = relatedByuidFindArr.sells.filter(el => el.type === 'BONUS').map((v) => v.uidFind)
            if (related.length > 0) {
                related.forEach(id => {
                    batch.delete(doc(db, `sellsBonus/${id}`))
                })
            }
            batch.delete(doc(db, `sells/${relatedByuidFindArr.docId}`))
        } else {
            const newSimples = relatedByuidFindArr.sells.filter(el => el.uidFind !== bonus.uidFind);
            const newUidFindArr = relatedByuidFindArr.uidFindArr!.filter(el => el !== bonus.uidFind);
            if (newSimples.length > 0) {
                const newAmount = newSimples.reduce((a, b) => +a + +b.amount, 0);
                batch.update(doc(db, `sells/${relatedByuidFindArr.docId}`), {
                    sells: newSimples,
                    uidFindArr: newUidFindArr,
                    amount: newAmount
                })
            } else {
                batch.delete(doc(db, `sells/${relatedByuidFindArr.docId}`))
            }
        } 
    } else {
        batch.delete(doc(db, `sells/${bonus.id}`))
        batch.delete(doc(db, `sellsBonus/${bonus.id}`))
        if (bonus.fracDocId && bonus.fracDocId.length > 0) {
            bonus.fracDocId.forEach((id) => {
              batch.delete(doc(db, `sells/${id}`))
            })
        }
    }

    const isDel = await commitBatch(batch)
    return isDel
}