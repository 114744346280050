import { ActionButton } from '@components/ui/button/ActionButton'
import { BoldText } from '@components/ui/text/BoldText'
import { callCloud } from '@lib/helpers/callCloud'
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'
import { selectorWarn } from '@redux/features/uiSlice/uiSlice'
import { useAppSelector } from '@redux/store/hooks'
import { useAuth } from 'app/modules/auth'
import React, { useEffect, useState } from 'react'

export const MakePay = () => {
    const center = useAppSelector(selectCenter)
    const [dateDue, setdateDue] = useState('')
    const {currentUser} = useAuth()
    const [url, seturl] = useState('');
    const warn = useAppSelector(selectorWarn)

    const goToInv = () =>  {
        window.location.replace(url);
    }

    useEffect(() => {
        let active = true;
      if (center?.dateDue && active) {
        if (center.owner) {
            const downLoadInv =  async () =>  {
                const listFromAdmin = callCloud('listFromAdmin');
                const sub = (await listFromAdmin({id: center.owner})).data as any;
                seturl(sub.hosted_invoice_url);
            }
            downLoadInv()
        }

        setdateDue(center.dateDue)
      }
      return () => {
        active = false;
      }
    }, [center])
    

  return (
    <div className='p-4'>
        <BoldText classNames='text-danger mb-5' text={warn ? `La última factura de la suscripción ha resultado impagada, el día ${dateDue} se cerrará el acceso a la agenda.` : 'Debido al impago de la última factura la agenda no está disponible.'} />
        {
            currentUser && currentUser.role.includes('admin') && url !== '' ?
            <ActionButton action={goToInv} text={'Pagar factura'} />
            : null
        }

    </div>
  )
}
