import billSlice from '@redux/features/billSlice/billSlice'
import calSlice from '@redux/features/calSlice/calSlice'
import clientSlice from '@redux/features/clientSlice/clientSlice'
import gquerySlice from '@redux/features/gquerySlice/gquerySlice'
import ticketSlice from '@redux/features/ticketSlice/ticketSlice'
import uiSlice from '@redux/features/uiSlice/uiSlice'
import { configureStore } from '@reduxjs/toolkit'
// ...

export const store = configureStore({
  reducer: {
    uiSlice: uiSlice,
    calSlice: calSlice,
    gquerySlice: gquerySlice,
    clientSlice: clientSlice,
    ticketSlice: ticketSlice,
    billSlice: billSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch