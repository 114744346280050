import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { callCloud } from '@lib/helpers/callCloud'
import { selectUid } from '@redux/features/clientSlice/clientSlice'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
const initialValues = {
    email: ''
}
const schema = Yup.object().shape({
    email: Yup.string().required('Es necesario un email').email('Falta formato email correcto'),
})
export const ChangeEmail = () => {
    const { uid } = useAppSelector(selectUid);
    const dispatch = useAppDispatch()
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            const { email } = values;
            const changeUserEmailFromAdmin = callCloud('changeUserEmailFromAdmin');
            const res = await (await changeUserEmailFromAdmin({ uid: uid, newEmail: email })).data as any;
            if (res.isError || !res.isEmailTrue) {
                const mess = res.isError ? 'Hay un error en la función' : 'El email que hemos enviado ha fallado';
                setValues({ ...initialValues })
                resetForm()
                setloading(false)
                return toast.error(mess)
            }
            const rewUserEmailDueChange = callCloud('rewUserEmailDueChange');
            const rew = (await rewUserEmailDueChange({ uid: uid, newEmail: email })).data as any;
            if (rew === false) {
                toast.error('No hemos podido reescribir los recordatorios, contacta con Estetical')
            }
            setloading(false)
            toast.success('Email cambiado')
            dispatch(setCloseModal())
        },
    })
    return (
        <Form formikVal={formik}>
            <FormField name={'email'} label={'Cambiar email'} type={'text'} />
            <div className='d-flex justify-content-center mt-5'>
                <SubmitBtn loading={loading} text='Enviar' />
            </div>
        </Form>
    )
}
