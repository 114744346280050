import { IMyDate } from "@models/bookings/IMyDate";
import { TurnAuto } from "@models/turns/turn-auto"
import { Turns } from "@models/turns/turns";
import { parse } from "date-fns";

export const saveAutoDatesOne = (workerUid: string,  turnsAuto: TurnAuto[]) => {
    const datesToParse: Turns[] = [];
    const autoDisDates: IMyDate[] = [];
    const enabledDates: IMyDate [] = [];
    const verify = turnsAuto.some(el => el.workerDoc === workerUid);
    if (verify) {
    const autoWorker = turnsAuto.filter(el => el.workerDoc === workerUid);
    autoWorker.forEach(el => el.turns.forEach(turn => datesToParse.push(turn)));
      datesToParse.forEach(el => {
        if(el.rest === 'Nowork') {
        const parseToDate = parse(el.showDate as string, 'd/MM/yyyy', new Date())
        const out = {
          month: parseToDate.getMonth() + 1,
          year: parseToDate.getFullYear(),
          day: parseToDate.getDate() 
        }
        autoDisDates.push(out);
      }
      });
    datesToParse.forEach(el => {
      if(el.rest !== 'Nowork') {
      const ver = new Date()
      const parseToDate = parse(el.showDate as string, 'd/MM/yyyy', new Date());
      if(parseToDate >= ver) {
        const save = {
          month: parseToDate.getMonth() + 1,
          year: parseToDate.getFullYear(),
          day: parseToDate.getDate()
        }
        enabledDates.push(save);
        }
      } 
    });
    }
    return {autoDisDates, enabledDates} 
}