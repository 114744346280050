import { CenterCol, Col, JustEnd } from '@assets/sass/bootstrapEx'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import Switch from '@components/formik/Switch'
import { BoldText } from '@components/ui/text/BoldText'
import useCreateUser from '@lib/hooks/user/useCreateUser'
import { useAuth } from 'app/modules/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect } from 'react'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { useAppDispatch } from '@redux/store/hooks'
import { toast } from 'react-hot-toast';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import * as Sentry from '@sentry/react';
import { userFields, userRules } from '@components/common/wiz/wiztypes/FastBookWizHelper'

const initialValues = {
    user: {
        ...userFields
    }
}
const schema = Yup.object().shape({
    ...userRules
})

export const CreateUser = () => {
    const dispatch = useAppDispatch();
    const { loading, setloading, createFinalUser } = useCreateUser()
    const { centerId } = useAuth()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            const created = await createFinalUser(values.user);
            if (created !== '') {
                toast.success('Usuario creado')
            } else {
                Sentry.configureScope(scope => scope.setTransactionName('Error at create user look fun'))
                toast.error('Ha habido algún error contacta con Estetical')
            }
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    useEffect(() => {
        formik.setFieldValue('user.center', centerId)
        formik.setFieldValue('user.needsUser', true)
    }, [centerId, formik.setFieldValue])

    useEffect(() => {
        const phone = formik.values.user.phone ;
        const name = formik.values.user.displayName as string;
        if (phone !== '' && name !== '') {
            const first = name.substring(0, 1).toUpperCase();
            formik.setFieldValue('user.password', `${first}${phone}`)
        }
    }, [formik.setFieldValue, formik.values])
    return (
        <div className={`${Col} w-100 p-4 `}>
            <Form formikVal={formik}>
                <FormField name={'user.displayName'} label={'Pon el nombre'} type={'text'} />
                <FormField type='email' name='user.email' label='Pon email' />
                <div className={`${CenterCol}`}>
                    <BoldText classNames='text-center mb-0' text={'¿El email es inventado?'} />
                    <Switch nameField={'user.fakeEmail'} holder={''} defvalue={false} />
                </div>
                <FormField name={'user.phone'} label={'Pon el teléfono'} type={'text'} />
                <FormField type='password' name='user.password' label='Contraseña' />
                <div className={`${CenterCol} mb-4`}>
                    <BoldText classNames='text-center mb-0' text={'¿Marcar como cliente Estetical?'} />
                    <Switch nameField={'user.fromEst'} holder={''} defvalue={false} />
                </div>
                <div className={`${JustEnd} mt-5`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
