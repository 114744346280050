import { ActionButton } from '@components/ui/button/ActionButton'
import { useState } from 'react'
import FormField from '../FormField'
import { Ticket } from '@models/ticket/ticket'
import { useFormikContext } from 'formik'
import { getTicketByCode } from '@lib/helpers/ticket/getTicketByCode'
import toast from 'react-hot-toast'
import { SubmitField } from '../SubmitField'
type Props = {
    processTicket: (ticket: Ticket) => Promise<void>
}
export const FormTicket = ({ processTicket }: Props) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const [loading, setloading] = useState(false);
    const ticketExists = async () => {
        setloading(true);
        const code = values.code;
        const ticket = await getTicketByCode(code);
        if (ticket) {
            await processTicket(ticket);
        } else {
            toast.error('No hay ningún ticket con ese código');
        }
        setloading(false);
    }
    const backToMeths = () => {
        setFieldValue('meth', '')
    }

    return (
        <>
            <FormField name={'code'} label={'Escribe el código'} type={'text'} />
            <div className='d-flex justify-content-between'>
                <ActionButton classNames='mt-4' action={backToMeths} text={'Volver a métodos'} />
                <SubmitField text='Pagar con ticket' classNames={` mt-4`} name={'code'} loading={loading} handleSubmit={ticketExists} />
            </div>

        </>
    )
}
