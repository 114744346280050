import { db } from "@lib/firebase"
import { Ticket } from "@models/ticket/ticket"
import { doc, getDoc, WriteBatch } from "firebase/firestore"

export const deleteSellsTicket = async (ticket: Ticket, bonoSellId: string | null, batch: WriteBatch) => {
    const refSell = (await getDoc(doc(db, `sells/${ticket.code}`)))
    const thereisSell = refSell.exists();
    if (thereisSell) {
        batch.delete(doc(db, `sells/${ticket.code}`))
    }
    if (bonoSellId) {
        batch.delete(doc(db, `sellsBonus/${bonoSellId}`))
    }
    batch.delete(doc(db, `tickets/${ticket.uid}`))
}