import { ActionButton } from '@components/ui/button/ActionButton'
import { BarItem } from '@components/ui/item/BarItem'
import { TDAny } from '@components/ui/table/TDAny'
import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { TableBody } from '@components/ui/table/TableBody'
import { TableHead } from '@components/ui/table/TableHead'
import { TablePag } from '@components/ui/table/TablePag'
import { db } from '@lib/firebase'
import useFilterTable from '@lib/hooks/ui/useFilterTable'
import { SellsBonus } from '@models/bono/sellsBonus'
import { setChangeBono, setPayLeft } from '@redux/features/clientSlice/clientSlice'
import { showModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { KTCard } from '_metronic/helpers'
import { useAuth } from 'app/modules/auth'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form'
import Switch from '@components/formik/Switch'
const heads = ['Bono', 'Cliente', 'Fecha venta', 'Precio', 'Deuda', 'Sesiones']
const initialValues = { isDebt: false }

export const TableSellsBono = () => {
    const [sells, setbonusSell] = useState<SellsBonus[]>([])
    const dispatch = useAppDispatch()
    const { centerId } = useAuth();
    const { dataTable, setDebounceQuery, totalResults, handleChangePage, currentPage, resultsPerPage } = useFilterTable(sells);
    const formik = useFormik({
        initialValues,
        onSubmit: async () => {},
    })

    const isDebt = useMemo(() => {
        return formik.values.isDebt
    }, [formik.values.isDebt])
    useEffect(() => {
        const bQuery = query(collection(db, 'sellsBonus'), where('centerId', '==', centerId), where('firstPayment', '==', true))
        const sub = onSnapshot(bQuery, (snap) => {
            let prs = snap.docs.map((value) => {
                const data = value.data();
                data.id = value.id
                return data
            }) as SellsBonus[]
            prs = prs.filter(el => el.hasTicket === undefined)
            if (isDebt) {
                prs = prs.filter(sell => sell.leftToPay && sell.leftToPay > 0)
            }
            setbonusSell(prs);
        })
        return () => {
            sub()
        }
    }, [centerId, isDebt])


    const passSeachterm = useCallback(
        (str: string) => {
            setDebounceQuery(str)
        },
        [setDebounceQuery])
    const payFrac = (n: number, bonoId: string) => {
        dispatch(setPayLeft({ num: n, bonoId: bonoId }))
        dispatch(showModal({ view: 'PAY_BONO_LEFT' }))

    }

    const changeBono = (val: SellsBonus) => {
        dispatch(setChangeBono(val))
        dispatch(showModal({ view: 'CHANGE_SES' }))
    }

    return (
        <KTCard>
            <TableBody options={
                <div className='d-flex justify-content-between align-items-center'>
                    <TableHead passSearch={passSeachterm} />
                    <Form classNames='' formikVal={formik}>
                        <Switch nameField={'isDebt'} holder={'Sólo deudas'} defvalue={false}  />
                    </Form>
                </div>
            }
                heads={heads}>
                {
                    dataTable.map((value) => (
                        <TRPar key={`${new Date().getTime()}-${value.id}`}>
                            <TDAny classNames='w-75px'>
                                <div className={` ms-5 d-flex justify-content-start`}>
                                    <BarItem isTable={true} classContainer='mb-0' title={value.name} desc={''} back={'bg-aqua'} />
                                </div>
                            </TDAny>
                            <TDText text={value.customerName!} />
                            <TDText text={value.dateShow} />
                            <TDText text={value.amount} />
                            {
                                value.simplePay ?
                                    <TDText text={'Pago simple'} />
                                    : (
                                        value.leftToPay as number > 0 ?
                                            <TDAny>
                                                <ActionButton text={`Pagar ${value.leftToPay!.toFixed(2)} €`} action={() => payFrac(value.leftToPay!, value.id!)} />
                                            </TDAny>
                                            : <TDText text={'Pagado'} />
                                    )
                            }
                            <TDAny classNames='w-25px'>
                                <ActionButton text='Sesiones' action={() => {
                                    const bono = value;
                                    delete bono.dateTime
                                    changeBono(bono)
                                }} />
                            </TDAny>
                        </TRPar>
                    ))
                }
            </TableBody>
            <TablePag className={'pagination-bar'} totalResults={totalResults} currentPage={currentPage} resultsPerPage={resultsPerPage} onChange={handleChangePage} />
        </KTCard>
    )
}
