import { Form } from '@components/formik/Form';
import { SubmitBtn } from '@components/formik/SubmitBtn';
import { TextArea } from '@components/formik/TextArea';
import { BoldText } from '@components/ui/text/BoldText';
import { db } from '@lib/firebase';
import { UserFinal } from '@models/user/userFinal';
import { selectUid } from '@redux/features/clientSlice/clientSlice'
import { useAppSelector } from '@redux/store/hooks'
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

const initialValues = {
    custBeh: ''
}
const schema = Yup.object().shape({
    custBeh: Yup.string().required('Es necesario un comentario')
})
export const AddCom = () => {
    const { uid } = useAppSelector(selectUid);
    const [com, setcom] = useState<string>('');
    const [loading, setloading] = useState<boolean>(false)
    const [migrDone, setmigrDone] = useState(false)
    useEffect(() => {
        let active = true;
        if (active) {
            const getCom = async () => {
                const cusRef = getDoc(doc(db, `customers/${uid}`));
                const userRef = getDoc(doc(db, `users/${uid}`));
                const tryCus = (await cusRef).exists()
                const us = (await userRef).data() as UserFinal;
                if (us.com) {
                    setcom(us.com)
                    return;
                } else if (tryCus) {
                    const cus = (await cusRef).data();
                    if (cus && cus.custBeh) {
                        setcom(cus.custBeh)
                        await updateDoc(doc(db, `users/${uid}`), {
                            com: cus.custBeh
                        })
                    }
                }
                setmigrDone(true)
            }
            getCom()
        } 

        return () => {
            active = false;
        }
    }, [uid])

    useEffect(() => {
        if (!migrDone) {
            return;  
        }
        const sub = onSnapshot(doc(db, `users/${uid}`), (snap) => {
            const u = snap.data() as UserFinal;
            setcom(u.com)

        })
        return () => {
            sub()
        }
    }, [uid, migrDone])
    
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            await updateDoc(doc(db, `users/${uid}`), {
                com: values.custBeh
            })
            setloading(false)
            setValues({ ...initialValues })
            resetForm();
        },
    })

    return (
        <div className='p-4'>
            <BoldText text={com} classNames='text-justify' />
            <Form formikVal={formik}>
                <TextArea rows={6} name='custBeh' />
                <div className='d-flex justify-content-center mt-5'>
                    <SubmitBtn loading={loading} text='Enviar' />
                </div>
            </Form>
        </div>
    )
}
