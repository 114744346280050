import { selectDayArr } from '@redux/features/billSlice/billSlice';
import { useAppSelector } from '@redux/store/hooks';
import React, { useEffect, useState } from 'react'
import { endOfDay, parse, startOfDay } from 'date-fns'
import { collection, onSnapshot, query, Timestamp, where } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { useAuth } from 'app/modules/auth';
import { useBillContext } from '@lib/context/bill/BillCxt';
import { Sell } from '@models/sells/sell';

export const QueryBills = () => {
    const dayArr = useAppSelector(selectDayArr);
    const [arrDates, setarrDates] = useState<Date[]>([])
    const { centerId } = useAuth()
    const { setRawOutputs, modifiedItem, addSells, deleteItem } = useBillContext();
    useEffect(() => {
        if (dayArr !== '') {
            const arr = dayArr.split(' - ');
            const d = new Date();
            const d1 = parse(arr[0], 'd/M/yyyy', d);
            const d2 = parse(arr[1], 'd/M/yyyy', d);
            const start = startOfDay(d1)
            const end = endOfDay(d2);
            setarrDates([start, end]);
        }
    }, [dayArr])

    useEffect(() => {
        if (arrDates.length > 0) {
            const q = query(collection(db, `sells`), where('dateTime', '>=', arrDates[0]), where('dateTime', '<=', arrDates[1]), where('centerId', '==', centerId));
            const sub = onSnapshot(q, (snap) => {
                const newSells: Sell[] = [] // hi haura de tots els tipus
                snap.docChanges().forEach((snap) => {
                    const item = snap.doc.data() as Sell;
                    item.sells = item.sells.map((v) => ({...v, amount: typeof v.amount === 'string' ? Number(v.amount) : v.amount}))
                    if (item.dateTime instanceof Timestamp) {
                        item.dateTime = item.dateTime.toDate()
                    }
                    if (snap.type === 'added') {
                        newSells.push(item)
                    } else if (snap.type === 'modified') {
                        modifiedItem(item)
                    } else {
                        deleteItem(item.docId)
                    }
                })
                addSells(newSells)
            })
            return () => {
                sub()
            }
        }

    }, [centerId, arrDates, addSells, modifiedItem, deleteItem])

    /* 
        only query to mantain
    */

    useEffect(() => {
        if (arrDates.length > 0) {
            const q = query(collection(db, `sellsBookings`),
                where('sellsDate', '>=', arrDates[0]), where('sellsDate', '<=', arrDates[1]), where('centerId', '==', centerId));
            const sub = onSnapshot(q, (qSnap) => {
                const noDate: any[] = []
                qSnap.docChanges().forEach((change) => {
                    const item = change.doc.data();
                    if (typeof item.amount !== 'number') {
                        item.amount = Number(item.amount)
                    }
                    if (change.type === 'added') {
                        noDate.push(item)
                    } else if (change.type === 'modified') {
                        modifiedItem(item)
                    } else if (change.type === 'removed') {
                        deleteItem(change.doc.id)
                    }
                })
                if (noDate.length > 0) {
                    setRawOutputs(noDate, 'BOOKS')
                }
            })
            return () => {
                sub()
            }
        }
    }, [centerId, arrDates, setRawOutputs, modifiedItem, deleteItem])

    return null
}
