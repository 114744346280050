import { db, storage } from '@lib/firebase';
import { UserFinal } from '@models/user/userFinal';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, deleteObject  } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Option } from '@models/opts/Option'
import { Form } from '@components/formik/Form';
import { SubmitBtn } from '@components/formik/SubmitBtn';
import { SelectField } from '@components/formik/SelectField';
import { ActionButton } from '@components/ui/button/ActionButton';
import * as Sentry from '@sentry/react';

const initialValues = { sel: '' }
const schema = Yup.object().shape({ sel: Yup.string().required('Es necesario un código') })

export const SeeSigned = () => {
    const { id } = useParams()
    const [signed, setsigned] = useState<string[]>([])
    const [conOpts, setconOpts] = useState<Option[]>([]);
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            pickSigned(values.sel)
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    useEffect(() => {
        const sub = onSnapshot(doc(db, `users/${id}`), (snap) => {
            const u = snap.data() as UserFinal;
            if (u.signedConsents) {
                setsigned(u.signedConsents)
                setconOpts(u.signedConsents.map((v) => ({label: v, value: v})))
            }
        })

        return () => {
            sub()
        }
    }, [id])

    async function pickSigned(name: string) {
        toast.success('Espera...')
        const url = await getDownloadURL(ref(storage, `ConsentPdf/${name}`))
        const t = setTimeout(() => {
            window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            clearTimeout(t)
        }, 0)
    }
    async function deleteConsent() {
        const name = formik.values.sel
        try {
            const newConsents = signed.filter(el => el !== name);
            await updateDoc( doc(db, `users/${id}`), {
                signedConsents: newConsents
            })
            const delRef = ref(storage, `ConsentPdf/${name}`);
            await deleteObject(delRef)
            formik.resetForm()
            toast.success('Firma eliminada')
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error deleteConsent'))
            Sentry.captureException(error, {user: {id: name}});
            toast.error('Error al eliminar la firma')

        }
    }
    return (
        
        <div className='mt-20'>
            <div className='d-flex justify-content-center'>
                {
                    signed.length > 0 ?
                    <div className="w-300px">
                        <Form formikVal={formik}>
                            <SelectField search={false} opts={conOpts} name={"sel"} placeHolder={"Consentimientos del usuario"} />
                                {
                                    formik.values.sel !== '' ?
                                    <div className="mt-3 d-flex  justify-content-around">
                                    <ActionButton text={'Eliminar'} isDanger={true} action={deleteConsent}  />
                                    <SubmitBtn text='Ver' loading={loading} />
                                    </div>
                                    : null
                                }
                        </Form>
                    </div>
                    : null
                }
            </div>
        </div>
    )
}
