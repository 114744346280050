import { Form } from '@components/formik/Form'
import { Acc } from '@components/ui/accordion/Acc'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { TextArea } from '@components/formik/TextArea'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import toast from 'react-hot-toast'
const schema = Yup.object().shape({ desc: Yup.string().required('Pon una descripción') })
interface Props {
    currDesc: string,
    serviceId: string
}
export const DescSer = ({ currDesc, serviceId }: Props) => {

    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: { desc: currDesc },
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            await updateDoc(doc(db, `services/${serviceId}`), {
                description: values.desc
            })
            toast.success('Cambiado')
            setloading(false)
        },
    })

    return (
        <Acc title='Edita la descripción' id='desc' classNames='mb-3 mt-3'>
            <Form formikVal={formik}>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Pon una descripción</label>
                <TextArea name={'desc'} rows={4} />
                <div className={`${JustEnd} mt-5`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </Acc>
    )
}
