import { callCloud } from "@lib/helpers/callCloud";
import { useState } from "react";

export default function useCreateUser() {

    const [loading, setloading] = useState(false);

    const createFinalUser = async(form: any) => {
        const atomicUserFinal = callCloud('atomicUserFinal');
        const isCreated = (await atomicUserFinal({form: form})).data as string;
        return isCreated;
    }
    
    return {
        loading,
        setloading,
        createFinalUser
    }
}

