
import { TableBono } from '@components/bono/tables/TableBono'
import { TableSellsBono } from '@components/bono/tables/TableSellsBono'
import { ContainerSec } from '@components/common/sectionspage/ContainerSec'
import { SectionsPage } from '@components/common/sectionspage/SectionsPage'
import React from 'react'

const BonoPage: React.FC = () => {

    return (
        <div>
        <SectionsPage indexSec={3} content={
            <>
                <ContainerSec isFirst={true} content={<TableBono />} id={"bono"} />
                <ContainerSec content={<TableSellsBono />} id={"bonosell"} />
            </>
        } />
    </div>
    )
}

export default BonoPage