import { nameintPosValid } from "@lib/helpers/Yup/nameintPosValid";
import { Bonus } from "@models/bono/bonus";
import { SessionsPack } from "@models/bono/sessionsPack";
import { PayMeth } from "@models/paymentMetod/payMeth";
import * as Yup from 'yup'
export type typePayBono = 'Simple' | 'Frac'
export interface BonusSell {
    workerName: string,
    code: string,
    bonoId: string,
    meth: PayMeth,
    efQnt: string,
    tarQnt: number,
    discount: string,
    newPrice: string,
    indexPack: null | string,
    selBonus: Bonus | null
    packSel: SessionsPack | null,
    simple: typePayBono,
    firstPayment: string,
    finalPrice: number,
    changeMoney: number
}

const BonusSellSchemas = [
    Yup.object().shape({
        bonoId: Yup.string().required('Selecciona el producto'),
        workerName: Yup.string().required('Selecciona el emplead@'),
        indexPack: Yup.mixed().required('Selecciona un pack')
    }),
    Yup.object().shape({
        discount: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).positive('Número positivo').typeError('Tiene que ser un número').integer('El número debe ser entero'),
        newPrice: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número'),
    }),
    Yup.object().shape({
        simple: Yup.string(),
        firstPayment: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).when('simple', {
            is: (simple: typePayBono) => simple === 'Frac',
            then: Yup.number()
            .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
            .required('Pon un número')
            .typeError('Tiene que ser un número').test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
                return Number(value) === 0 || Number(value) > 0
              }),
            otherwise: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)), 
        }),
        finalPrice: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).when('simple', {
            is: (simple: typePayBono) => simple === 'Frac',
            then: Yup.number().required('Pon un número').transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').required('Falta el primer pago')
            .test('is-less-than-final-price', 'El primer pago no puede ser mayor al precio final', function (value: any) {
                const pack = (this.resolve(Yup.ref('packSel')) as SessionsPack | null);
                if (pack) {
                    const currentPrice = pack.amountPack
                    return Number(value) < Number(currentPrice);
                } else {
                    return false;
                }
              }),
            otherwise: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)), 
        }),
        meth: Yup.string().required('Es necesario un tipo'),
        efQnt: Yup.number().when('meth', {
            is: (meth: string) => meth === 'Efectivo' || meth === 'Mixto',
            then: Yup.number().required('Pon un número'),
            otherwise: Yup.number()
        }).transform(nameintPosValid),
    })
]

const initBonusSell: BonusSell = {
    workerName: '',
    code: '',
    bonoId: '',
    meth: '',
    efQnt: '',
    tarQnt: 0,
    discount: '',
    newPrice: '',
    simple: 'Simple',
    indexPack: null,
    selBonus: null,
    packSel: null,
    firstPayment: '',
    finalPrice: 0,
    changeMoney: 0
}

export {initBonusSell, BonusSellSchemas}