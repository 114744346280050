import { BonusSell } from '@components/common/wiz/wiztypes/BonoSellsWizHelper';
import FormField from '@components/formik/FormField';
import { SubmitField } from '@components/formik/SubmitField';
import { doPer } from '@lib/helpers/sells/doPer';
import { useFormikContext } from 'formik';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash'
import { SimpleCard } from '@components/ui/item/SimpleCard';

export const StepBonus2 = () => {
    const { setFieldValue, values } = useFormikContext<BonusSell>();
    
    const changePrice = () => {
        if (values.packSel) {
            const pack = cloneDeep(values.packSel);
            pack.amountPack = values.newPrice
            setFieldValue('packSel', pack);
            setFieldValue('newPrice', '');
            toast.success('Precio cambiado')
        }
    }
    const makeDiscount = () => {
        if (values.packSel) {
            const pack = cloneDeep(values.packSel);
            const n = Number(values.discount);
            const packPrice = Number(pack.amountPack)
            const disc = doPer(n, packPrice)
            const final = (packPrice - disc)
            pack.amountPack = final.toFixed(2);
            setFieldValue('packSel', pack);
            setFieldValue('discount', '');
            toast.success('Precio cambiado')

        }
    }


    return (
        <div className='w-100'>
            <div className='d-flex justify-content-center mb-5'>
                {
                    values.packSel !== null ?
                        <SimpleCard
                            childIcon={<i className={`${values.packSel.servicesLimit ? 'fa fa-paint-brush' : 'fa-solid fa-gift'} text-white`}
                                style={{ fontSize: 35 }}></i>}
                            classNames='w-300px h-150px mt-5'
                            title={values.packSel.name}
                            text1={`Precio: ${values.packSel.amountPack}`}
                            back={values.packSel.servicesLimit ? 'bg-success' : 'bg-info'} />
                        : null
                }
            </div>
            <div>
                <FormField name={'discount'} label={'Aplica un porcentaje al precio del bono'} type={'text'} />
                <SubmitField name={'discount'} loading={false} handleSubmit={makeDiscount} />
            </div>
            <div>
                <FormField name={'newPrice'} label={'Cambiar el precio'} type={'text'} />
                <SubmitField name={'newPrice'} loading={false} handleSubmit={changePrice} />
            </div>
        </div>
    )
}