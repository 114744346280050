import { BoldText } from '@components/ui/text/BoldText'
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import React, { useEffect, useState } from 'react'
interface Props {
    icon: string,
    title: string,
    subTitle?: string,
    total: string,
    emplo?: boolean,
    av?: string
}
export const ItemBill = ({icon, title, subTitle, total, emplo, av}: Props) => {
  const [name, setname] = useState('')
  useEffect(() => {
    if (title && emplo) {
      let abName = title as string;
      const nameArr = abName.split(' ');
      abName = `${nameArr[0]} ${nameArr[1] ? nameArr[1].substring(0,1).toUpperCase() : ''}`
      setname(abName)
    }
  }, [title, emplo])
  return (
    <div className='d-flex align-items-center mb-6'>
    <div className='symbol symbol-45px w-40px me-5'>
      <span className={`symbol-label ${emplo ? 'bg-lighten' : 'bg-info'}`}>
        {
          emplo === true ? 
          <img width={45} height={45} src={toAbsoluteUrl(`/media/svg/avatars/${av}.svg`)} alt='avatar' />
          :
          <i className={`${icon} text-white svg-icon-1 fs-2x`}></i>
        }
      </span>
    </div>
    <div className='d-flex align-items-center flex-wrap w-100'>
      <div className='mb-1 pe-3 flex-grow-1'>
        <p  className='fs-5 text-gray-800 fw-bold mb-0'>
          {emplo ? name : title}
        </p>
        <div className='text-gray-400 fw-semibold fs-7'>{subTitle}</div>
      </div>
      <div className='d-flex align-items-center'>
        <div className='fw-bold fs-5 text-gray-800 pe-1'>{total} €</div>
      </div>
    </div>
  </div>
  )
}
