import { Form } from '@components/formik/Form';
import { SubmitBtn } from '@components/formik/SubmitBtn';
import Switch from '@components/formik/Switch';
import { db } from '@lib/firebase';
import { callCloud } from '@lib/helpers/callCloud';
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice';
import { useAppDispatch, useAppSelector } from '@redux/store/hooks';
import { doc, updateDoc } from 'firebase/firestore';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';

export const FakeEmail = () => {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch()
    const [loading, setloading] = useState<boolean>(false)
    const initialValues = {
        fakeEmail: user?.fakeEmail as boolean
    }
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true);
            const fromFakeToTrueEmail = callCloud('fromFakeToTrueEmail');
            const res = (await fromFakeToTrueEmail({uid: user?.uid})).data as any;
            if (res.isError || res.isEmailTrue === false) {
                const mess = res.isError ? 'Hay un error en la función' : 'El email que hemos enviado ha fallado';
                setValues({ ...initialValues });
                setloading(false)
                resetForm();
                return toast.error(mess)
            }
            await updateDoc(doc(db, `users/${user?.uid}`), {
                fakeEmail: values.fakeEmail
            })
            setloading(false)
            toast.success('Cambiado')
            dispatch(setCloseModal())
        },
    })
    return (
        <Form formikVal={formik}>
            <div className='d-flex justify-content-center'>
                <Switch nameField={'fakeEmail'} holder={`Cambiar ${user?.fakeEmail === false ? 'a falso' : 'a verdadero' }`} defvalue={user?.fakeEmail as boolean} />
            </div>
            <div className='d-flex justify-content-center mt-5'>
                    <SubmitBtn loading={loading} text='Enviar' />
            </div>
        </Form>
    )
}
