import { db } from "@lib/firebase";
import { getCenter } from "@lib/helpers/auth/getCenter";
import { Centers } from "@models/centers/center";
import { UserFinal } from "@models/user/userFinal";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import * as Sentry from '@sentry/react';

const useFetchAsk = (
userUid: string,
centerId: string,

) => {
    const [user, setuser] = useState<UserFinal | null>(null);
    const [center, setcenter] = useState<Centers | null>(null)
    const [loaded, setloaded] = useState<Boolean>(false)
    useEffect(() => {
        const fetch = async () => {
          try {
            const d = (await getDoc(doc(db, `users/${userUid}`))).data() as UserFinal;
            const c = (await getCenter(centerId, false))
            if (d && c) {
              setuser(d);
              setcenter(c)
            }
          } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error fetchask'))
            Sentry.captureException(error, {user: {id: userUid}});
          }
          setloaded(true)
        }
        fetch()
      }, [userUid, centerId]);
      return {
        center,
        user,
        loaded
      }
}

export default useFetchAsk