interface Props {
    link?: string,
    action?: () => void,
    icon: string,
    textButt?: string,
    classText?: string
}
export const LinkButton = ({ link, icon, action, textButt, classText }: Props) => {
    return (
        <>
            {
                link ?
                    <a href={link}>
                        {
                            textButt ?
                                <button
                                    className={`btn btn-bg-primary btn-active-color-primary text-white ${classText ? classText : ''}`}
                                >
                                    {
                                        textButt
                                    }
                                </button>
                                :
                                <button
                                    className='btn btn-sm btn-icon btn-bg-primary btn-active-color-primary ms-5'
                                >
                                    <i className={`${icon} text-white`}></i>
                                </button>
                        }
                    </a>
                    :
                    <button
                        onClick={action}
                        className='btn btn-sm btn-icon btn-bg-primary btn-active-color-primary ms-5'
                    >
                        <i className={`${icon} text-white`}></i>
                    </button>
            }
        </>
    )
}
