import { selectType, setType } from '@redux/features/billSlice/billSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import React, { memo } from 'react'

export const UpMenu = memo(() => {
    const currType = useAppSelector(selectType);
    const dispatch = useAppDispatch();

    return (
        <div className={`card mb-5 mb-xl-10 fit-own `}>
            <div className='card-body pt-1 pb-0'>
                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item' onClick={(() => dispatch(setType(null)))} >
                            <p
                                className={
                                    `nav-link is-pointer ${currType === null ? 'text-primary' : null} me-6 `}
                            >
                                Total
                            </p>

                        </li>
                        <li className='nav-item' onClick={(() => dispatch(setType('BOOKS')))}>
                            <p
                                className={
                                    `nav-link is-pointer ${currType === 'BOOKS' ? 'text-primary' : null} me-6 `}
                            >
                                Reservas
                            </p>
                        </li>
                        <li className='nav-item' onClick={(() => dispatch(setType('PRODS')))}>
                            <p
                                className={
                                    `nav-link is-pointer ${currType === 'PRODS' ? 'text-primary' : null} me-6 `}
                            >
                                Productos
                            </p>
                        </li>
                        <li className='nav-item' onClick={(() => dispatch(setType('BONUS')))}>
                            <p
                                className={
                                    `nav-link is-pointer ${currType === 'BONUS' ? 'text-primary' : null} me-6 `}
                            >
                                Bonos
                            </p>
                        </li>
                        <li className='nav-item' onClick={(() => dispatch(setType('TICKS')))}>
                            <p
                                className={
                                    `nav-link is-pointer ${currType === 'TICKS' ? 'text-primary' : null} me-6 `}
                            >
                                Tickets
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
})
