import { db } from '@lib/firebase';
import { doc, updateDoc,} from 'firebase/firestore';
import { Checkbox } from '@mobiscroll/react';
import { useAppSelector } from '@redux/store/hooks';
import { selectPureWorkers } from '@redux/features/gquerySlice/gquerySlice';

export const CalendarWorkers = () => {
    const workers = useAppSelector(selectPureWorkers)
    const filter = (ev: any) => {
        const uid = ev.target.value;
        const user = doc(db, `users/${uid}`);
        updateDoc(user, {
            defCal: ev.target.checked
        })
    }
    return (
        <div className='d-flex flex-wrap justify-content-center'>
            {
                workers.map((value) => {
                    return (
                        <Checkbox
                            theme='ios'
                            key={value.uid}
                            checked={value.defCal ? value.defCal : false}
                            onChange={filter}
                            value={value.uid}
                            label={value.name}
                        />
                    )
                })
            }
        </div>
    )
}
