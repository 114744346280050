import { SellsBonus } from "@models/bono/sellsBonus"
import { PayMeth } from "@models/paymentMetod/payMeth"
import { Sell, SimpleSell } from "@models/sells/sell"
import { SellsCounter } from "@models/sells/sellsCounter"
import { format } from "date-fns"
import { doRandomUid } from "../random/doRandomUid"
import { doc, getDoc } from "firebase/firestore"
import { db } from "@lib/firebase"
import { UserFinal } from "@models/user/userFinal"

export const addSellPayLeft = async (sellsBonus: SellsBonus, counter: SellsCounter) => {
    const docUser = doc(db, `users/${sellsBonus.userUid}`);
    const u = (await getDoc(docUser)).data() as UserFinal;
    const simpleSell: SimpleSell = {
        name: sellsBonus.name,
        amount: Number(sellsBonus.amount),
        workerName: sellsBonus.optFac,
        type: "BONUS",
        orderDate: format(new Date(), 'dd-MM-yyyy HH:mm'),
        parentId: sellsBonus.id as string,
        paymentMethod: sellsBonus.paymentMethod as PayMeth,
        userName: u.displayName,
        userUid: sellsBonus.userUid,
        uidFind: doRandomUid(sellsBonus.centerId),
        uidInCol: sellsBonus.originBono
    }
    const sell: Sell = {
        uidInCol: sellsBonus.originBono,
        docId: sellsBonus.id as string,
        dateTime: sellsBonus.dateTime,
        centerId: sellsBonus.centerId,
        cross: false,
        userUid: sellsBonus.userUid,
        email: u.email,
        userName: u.displayName,
        amount: Number(sellsBonus.amount),
        paymentMethod: sellsBonus.paymentMethod as PayMeth,
        allTypes: ["BONUS"],
        sells: [simpleSell],
        counter: 0,
        cif: counter.cif,
        busName: counter.busName,
        dir: counter.dir,
        postal: counter.postal,
        isAdmin: true
    }
    return sell
}