import { TurnAuto } from "@models/turns/turn-auto";
import { Worker } from '@models/user/worker';
export const decideAutoOrWor = (
  turnsAuto: TurnAuto[], worker: Worker, localDay: string, nameDay: string
  ) => {
    const filterWorkerAuto = turnsAuto.filter(t => t.workerDoc === worker.uid);
    const filterLocalDay = filterWorkerAuto.filter(t => t.turns.find(turn => turn.showDate === localDay));
    if (filterLocalDay[0] !== undefined) {
      const onlyDayTurn = filterLocalDay[0].turns.filter(t => t.day === nameDay)
      return {data: onlyDayTurn[0], auto: true};
    } else {
      const workerTurns = worker.turns.filter(t => t.day === nameDay);
      return {data: workerTurns[0], auto: false}
    }
}