import { db } from '@lib/firebase'
import { Admin } from '@models/user/admin'
import { useAuth } from 'app/modules/auth'
import { query, collection, where, getDocs, onSnapshot, doc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Option } from '@models/opts/Option';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { SelectField } from '@components/formik/SelectField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { FormAdmin } from './FormAdmin'
const initialValues = { uid: '' }
const schema = Yup.object().shape({ uid: Yup.string().required('Es necesario un admin') })

export const EditAdmin = () => {
    const { currentUser } = useAuth()
    const [adminsOpts, setadminsOpts] = useState<Option[]>([])
    const [uid, setuid] = useState<string>('')
    const [admin, setadmin] = useState<Admin | null>(null)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setuid(values.uid)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    /* 
        TODO: only finds the one with centerWorkerName
    */
    useEffect(() => {
        let active = true;
        if (active) {
            if (currentUser?.role.includes('superAd')) {
                const getAdminsOpts = async () => {
                    const qAdmin = query(collection(db, 'users'), where('role', 'array-contains', 'admin'), where('centerWorkerName', '==', currentUser!.centerId))
                    const ads = (await getDocs(qAdmin)).docs.map((v) => v.data()) as Admin[];
                    const opts = ads.map((v) => ({ label: v.adminName, value: v.uid }))
                    setadminsOpts(opts)
                }
                getAdminsOpts()
            } else {
                setuid(currentUser!.uid)
            }
        }
        return () => {
            active = false;
        }
    }, [currentUser])
    useEffect(() => {
        if (uid === '') {
            return;
        }
        const sub = onSnapshot(doc(db, `users/${uid}`), (snap) => {
            const d = snap.data() as Admin;
            setadmin(d)
        })
        return () => {
            sub()
        }
    }, [uid])

    return (
        <div className='p-4'>
            {
                currentUser?.role.includes('superAd') && uid === '' ?
                    <Form formikVal={formik}>
                        <SelectField search={true} opts={adminsOpts} name={'uid'} placeHolder={'Elige un admin para editar'} />
                        <div className={`${JustEnd} mt-5 `}>
                            <SubmitBtn classNames=' mt-3 ms-lg-3 ms-0 p-3 w-150px' loading={false} />
                        </div>
                    </Form>
                    : null
            }
            {
                admin ?
                    <FormAdmin {...admin} />
                    : null
            }
        </div>
    )
}
