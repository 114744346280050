import { Spinner } from '@components/ui/spinner/Spinner';
import { db } from '@lib/firebase';
import { CustomerBilling } from '@models/customerbills/customerBilling';
import { Admin } from '@models/user/admin';
import { KTCard } from '_metronic/helpers';
import { useAuth } from 'app/modules/auth'
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { FormCounter } from './FormCounter';
import { BoldText } from '@components/ui/text/BoldText';
import CardWarning from '@components/ui/cards/cardWarning/CardWarning';
import { counterForm } from '@models/sells/sellsCounter'

export const SellsCheck = () => {
    const { currentUser } = useAuth();
    const [loading, setloading] = useState(false);
    const [counterForm, setcounterForm] = useState<counterForm>({cif: '', dir: '', postal: '', needsYear: false});
    useEffect(() => {
        let active = true;
        if (currentUser && active) {
            if (currentUser.role.includes('admin') || currentUser.role.includes('superAd')) {
                setloading(true);
                const invokeAdmins = async () => {
                    const cusIdsArr:string[] = []
                    const qAdmins = query(collection(db, 'users'), 
                    where('role', 'array-contains', 'admin'), 
                    where('centerWorkerName', '==', currentUser.centerId));
                    const admins = (await getDocs(qAdmins)).docs.map((v) => v.data()) as Admin[];
                    admins.forEach(ad => {if (ad.cus_id){cusIdsArr.push(ad.cus_id)}});
                    if (cusIdsArr.length > 0) {
                        const id = cusIdsArr[0];
                        const qBilling = query(collection(db, 'customerBilling'), where('customerId', '==', id))
                        const billingDocs = (await getDocs(qBilling));
                        if (billingDocs.size > 0) {
                            const bill = billingDocs.docs.map((v) => v.data())[0] as CustomerBilling;
                            setcounterForm({
                                cif: bill.cif, 
                                dir: bill.address, 
                                postal: typeof bill.postal === 'number' ? bill.postal.toString() : bill.postal,
                                needsYear: false
                            })
                        }
                    }
                }
                invokeAdmins();
                setloading(false);
            }
        }
        return () => {
            active = false;
        }
    }, [currentUser])
    return (
        <KTCard className='p-4 mb-5'>
            <BoldText text={'Rellena la información para empezar con los tickets de venta'} />
            {
                loading?
                <Spinner />
                : (currentUser?.role.includes('worker') ? <CardWarning title={'Es necesaria una cuenta de administrador/a'} subtitle={''} children={
                    <BoldText text={'Para empezar con las tickets de venta, conéctate con una cuenta de admin y rellena la información'} />
                } /> : <FormCounter counterForm={counterForm} isInConfig={false} />)
            }

        </KTCard>
    )
}
