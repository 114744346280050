import { MultPreSer } from '@models/bookings/MultPre'
import { UserFinal } from '@models/user/userFinal'
import { format } from 'date-fns'
import * as Yup from 'yup'

export const userRules = {
  user: Yup.object().shape({
    needsUser: Yup.boolean(),
    displayName: Yup.string().when('needsUser', {
      is: (needsUser: boolean) => needsUser === true,
      then: Yup.string().required('Falta información de cliente'),
      otherwise: Yup.string(),
    }),
    email: Yup.string().when('needsUser', {
      is: (needsUser: boolean) => needsUser === true,
      then: Yup.string().required('Falta información de cliente').email('Pon un formato correcto'),
      otherwise: Yup.string()
    }),
    phone: Yup.string().when('needsUser', {
      is: (needsUser: boolean) => needsUser === true,
      then: Yup.string().required('Falta información de cliente').matches(/^[0-9]*$/, 'Sólo números'),
      otherwise: Yup.string()
    }),
    password: Yup.string().when('needsUser', {
      is: (needsUser: boolean) => needsUser === true,
      then: Yup.string().required('Falta información de cliente').min(10, '10 carácteres mínimo').max(25) 
      .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, 'La contraseña requiere números y letras'),
      otherwise: Yup.string()
    }),
  })
}

const fastBookSchemas = [
  Yup.object({
    client: Yup.string().when('user.needsUser', {
      is: (needsUser: boolean) => needsUser === false,
      then: Yup.string().required('Selecciona un cliente para continuar'),
      otherwise: Yup.string()
    }),
    ...userRules
  }),
  Yup.object({
    // multArr: Yup.array().min(1, 'No has reservado').required('No has reservado')
  })
]

export interface FastBook {
  d: string,
  workerName: string,
  client: string
  multArr: MultPreSer[],
  currBono: boolean,
  currBonoId: string,
  currSerId: string,
  centerRest: number,
  isFirstTime: boolean,
  userDoc: UserFinal | null,
  user: {
    needsUser: boolean
    displayName: string,
    birthDate: string,
    email: string,
    fakeEmail: boolean,
    phone: string,
    password: string,
    center: string,
    fromEst: boolean
  }
}

export const userFields = {
  needsUser: false,
  displayName: '',
  fakeEmail: false,
  email: '',
  phone: '',
  password: '',
  center: '',
  fromEst: false,
  birthDate: format(new Date(), 'dd-MM-yyyy')
}

const initFastBook: FastBook = {
    d: '',
    workerName: '',
    client: '',
    multArr: [],
    currBono: false,
    currBonoId: '',
    currSerId: '',
    centerRest: 0,
    isFirstTime: false,
    userDoc: null,
    user: {
      ...userFields
    }
}

export {initFastBook, fastBookSchemas}