import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { TextArea } from '@components/formik/TextArea'
import { KTCard } from '_metronic/helpers'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { Messages } from '@models/user/messages'
import { db } from '@lib/firebase'
import { addDoc, collection, updateDoc, doc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { BoldText } from '@components/ui/text/BoldText'
const schema = Yup.object().shape({
  text: Yup.string().required('Es necesaria un comentario'),
  matter: Yup.string().required('Es necesario un título'),

})
const initialValues = { text: '', matter: '' }

export const Com = () => {
  const [loading, setloading] = useState<boolean>(false)
  const { id } = useParams();
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setValues, resetForm }) => {
      setloading(true)
      const m: Messages = {
        matter: values.matter,
        text: values.text,
        creation: new Date().getTime(),
        docId: ''
    } 
    const ref = await addDoc(collection(db, `users/${id}/messages`), m)
    await updateDoc(doc(db, `users/${id}/messages/${ref.id}`), {
        docId: ref.id
    })
      setloading(false)
      setValues({ ...initialValues })
      resetForm()
    },
  })
  return (
    <>    
      <BoldText classNames='text-muted' text='Añade un comentario al cuestionario' />
      <KTCard className='p-5'>
        <Form formikVal={formik}>
          <FormField name='matter' label='Título' type='text' />
          <TextArea name='text' rows={8} />
          <div className='d-flex justify-content-center mt-5'>
            <SubmitBtn loading={loading} />
          </div>
        </Form>
      </KTCard>
    </>
  )
}
