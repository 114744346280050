import { SearchUserFast } from '@components/calendar/cell/fastbook/SearchUserFast'
import { TicketForm } from '@components/common/wiz/wiztypes/CreateWizTicket'
import FormField from '@components/formik/FormField'
import { SelectField } from '@components/formik/SelectField'
import { RadioContainer } from '@components/formik/radio/RadioContainer'
import { RadioField } from '@components/formik/radio/RadioField'
import { ActionButton } from '@components/ui/button/ActionButton'
import { ResCol } from '@components/ui/container/ResCol'
import { PhotoItem } from '@components/ui/item/PhotoItem'
import { BoldText } from '@components/ui/text/BoldText'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import useWorkersSells from '@lib/hooks/sells/useWorkersSells'
import { Services } from '@models/services/services'
import { useAuth } from 'app/modules/auth'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'

export const CreateTicket = () => {
  const { centerId } = useAuth();
  const { worOpts } = useWorkersSells();
  const { services, optsServices } = useServicesBooking([])
  const { setFieldValue, values } = useFormikContext<TicketForm>();

  useEffect(() => {
    if (values.type === 'bono') {
      setFieldValue('bono', true)
    } else {
      setFieldValue('bono', false)
    }
  }, [values.type, setFieldValue])

  useEffect(() => {
    if (values.bono) {
      const tot = values.services.reduce((a, b) => +a + +Number(b.price), 0);
      setFieldValue('amount', tot.toFixed(2))
    }
  }, [values.bono, values.services, setFieldValue])

  useEffect(() => {
    setFieldValue('centerId', centerId)
  }, [centerId, setFieldValue])

  const add = () => {
    const sel = services.filter(s => s.serviceId === values.currService)[0];
    if (values.services.some(el => el.serviceId === sel.serviceId)) {
      toast.error('Servicio ya incluído')
    } else {
        const newData = values.services.concat(sel)
        setFieldValue('services', newData)
    }
      setFieldValue('currService', '')
  }
  const deleteItem = (index: number) => {
    const newData = values.services.filter((_, i) => i !== index)
    setFieldValue('services', newData)
  }

  return (
    <div className='w-100'>
      <ResCol
        childrenFirst={
          <SearchUserFast label='Quién crea el ticket' clearable={true} />

        }
        childrenSecond={
          <SelectField name='workerName' opts={worOpts} search={false} placeHolder={'Facturación'} />
        }
      />
      <div className='mb-5'>
        <RadioContainer title='Selecciona el tipo'>
          <RadioField icon=' fa-solid fa-heart' name={'type'} value={'bono'} title={'Servicios'} />
          <RadioField icon=' fa-solid fa-coins' name={'type'} value={'mon'} title={'Monetario'} />
        </RadioContainer>
      </div>

      {
        values.bono ?
          <div className='row'>
            <div className='col-6 mb-10'>
              <BoldText text={`Total ${values.amount} € `} />
              <SelectField name='currService' opts={optsServices} search={true} placeHolder={'Elige los servicios'} />
              {
                values.currService !== '' &&
                <ActionButton text={'Añadir'} action={add} classNames='mt-3' />
              }
            </div>
            <div className='d-flex flex-wrap'>
              {
                values.services.map((ser: Services, index: number) => (
                  <div key={ser.serviceId} className='me-5'>
                    <PhotoItem
                      index={index}
                      img={ser?.img as string}
                      title={ser?.serviceName as string}
                      subText={ser?.cat}
                      deleteFun={() => deleteItem(index)} whatever={undefined} />

                  </div>
                ))
              }
            </div>

          </div>
          :
          <FormField name={'amount'} label={'Pon el precio'} type={'text'} />
      }

    </div>
  )
}
