import clsx from "clsx";
import { Field, FieldProps } from "formik";
import { useState } from "react";

type FormFieldProps = {
    name: string;
    label: string;
    type: string,
    autocomplete?: 'off' | 'on',
    isPassword?: boolean
};

function FormField({ name, label, type, autocomplete, isPassword }: FormFieldProps) {
    const [typeValue, settypeValue] = useState<any>(type)
    return (
        <div className="mt-2 mb-2">
            <Field name={name}>
                {({ field, meta }: FieldProps) => (
                    <>
                        <label className='fw-bolder'>{label}</label>
                        <div className="input-group">
                            <input
                                type={typeValue}
                                placeholder=''

                                autoComplete={type === 'password' ? `new-password` : 'off'}
                                className={clsx(
                                    'form-control bg-transparent',
                                    'position-relative',
                                )}
                                id={name} {...field}
                            />
                            {
                                isPassword && isPassword === true &&
                                <span className="input-group-text" onClick={() => settypeValue(typeValue === 'password' ? 'text' : 'password')}>
                                    <i className={`${typeValue === 'password' ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'} text-primary fs-2x`}></i>
                                </span>
                            }
                        </div>
                        {meta.touched && meta.error &&
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className=" text-warning">{meta.error}</span>
                            </div>
                        }
                    </>
                )}
            </Field>
        </div>
    );
}

export default FormField