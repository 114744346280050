import { ActionButton } from '@components/ui/button/ActionButton';
import { BarItem } from '@components/ui/item/BarItem';
import { TDAny } from '@components/ui/table/TDAny';
import { TDText } from '@components/ui/table/TDText';
import { TRPar } from '@components/ui/table/TRPar';
import { TableBody } from '@components/ui/table/TableBody';
import { TableHead } from '@components/ui/table/TableHead';
import { TablePag } from '@components/ui/table/TablePag';
import { BoldText } from '@components/ui/text/BoldText';
import { db } from '@lib/firebase';
import useFilterTable from '@lib/hooks/ui/useFilterTable';
import { Bonus } from '@models/bono/bonus'
import { ServicePair } from '@models/machines/servicePair';
import { showModal } from '@redux/features/uiSlice/uiSlice';
import { useAppDispatch } from '@redux/store/hooks';
import { KTCard } from '_metronic/helpers';
import { useAuth } from 'app/modules/auth';
import { query, collection, where, onSnapshot, orderBy } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react'
const headsUsers = ['Nombre', 'Tipo', 'Servicios', 'Editar']

export const TableBono = () => {
    const [bonus, setbonus] = useState<Bonus[]>([])
    const { centerId } = useAuth();
    const dispatch = useAppDispatch();
    const { dataTable, setDebounceQuery, totalResults, handleChangePage, currentPage, resultsPerPage } = useFilterTable(bonus);
    const passSeachterm = useCallback(
        (str: string) => {
            setDebounceQuery(str)
        },
        [setDebounceQuery])

    useEffect(() => {
        const bQuery = query(collection(db, 'bonus'), where('centerId', '==', centerId), orderBy('nameBonus', 'asc'))
        const sub = onSnapshot(bQuery, (snap) => {
            const bonos = snap.docs.map((v) => v.data()) as Bonus[];
            setbonus(bonos);
        })

        return () => {
            sub()
        }
    }, [centerId])

    const create = () => {
        dispatch(showModal({ view: 'CREATE_BONO' }))
    }
    const edit = (id: string) => {
        dispatch(showModal({ view: 'EDIT_BONO', editId: id }))
    }
    const showSers = (sers: ServicePair[]) => {
        const sersString = sers.length > 0 ? sers.map((v) => v.name).join(', ') : '';
        dispatch(showModal({ view: 'SHOW_BONO_SERS', editId: sersString }));
    }
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-5'>
                <BoldText classNames='fs-2' text='Bonos del centro' />
                
            </div>
            <KTCard>
                <TableBody options={
                    <div className='d-flex justify-content-between align-items-center'>
                        <TableHead passSearch={passSeachterm} />
                        <ActionButton important={true} text={'Crear'} action={create} />
                    </div>
                }
                    heads={headsUsers}>
                    {
                        dataTable.map((value) => (
                            <TRPar key={`${new Date().getTime()}-${value.bonusId}`}>
                                <TDAny classNames='w-75px'>
                                    <div className={` ms-5 d-flex justify-content-start`}>
                                        <BarItem isTable={true} classContainer='mb-0' title={value.nameBonus} desc={''} back={'bg-aqua'} />
                                    </div>
                                </TDAny>
                                <TDText text={`${value.pers ? 'Personalizado' : 'Normal'}`} />
                                <TDAny>
                                    <ActionButton text={'Ver'} action={() => { showSers(value.services) }} />
                                </TDAny>
                                <TDAny>
                                    <ActionButton text={'Editar'} action={() => edit(value.bonusId)} />
                                </TDAny>
                            </TRPar>
                        ))
                    }
                </TableBody>
                <TablePag className={'pagination-bar'} totalResults={totalResults} currentPage={currentPage} resultsPerPage={resultsPerPage} onChange={handleChangePage} />
            </KTCard>
        </div>
    )
}
