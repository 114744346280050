import React from 'react'
interface Props {
    children: React.ReactNode,
    title: string,
    id: string,
    classNames?: string
}
export const Acc = ({children, title, id, classNames}: Props) => {
  return (
    <div className={`accordion ${classNames ? classNames : ''}`} id={`kt_${id}`}>
    <div className='accordion-item'>
      <h2 className='accordion-header' id={`kt_${id}_header_1`}>
        <button
          className='accordion-button fs-4 fw-semibold collapsed'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={`#kt_${id}_body_1`}
          aria-expanded='false'
          aria-controls={`kt_${id}_body_1`}
        >
         {title}
        </button>
      </h2>
      <div
        id={`kt_${id}_body_1`}
        className='accordion-collapse collapse'
        aria-labelledby={`kt_${id}_header_1`}
        data-bs-parent={`#kt_${id}`}
      >
        <div className='accordion-body'>
            {children}
        </div>
      </div>
    </div>
  </div>
  )
}
