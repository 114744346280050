import { makeMinutes } from "@lib/helpers/dates/makeMinutes";
import { decideAutoOrWor } from "@lib/helpers/turns/decideAutoOrWor";
import { Booking } from "@models/bookings/booking";
import { TimeV2 } from "@models/bookings/timeV2";
import { Machine } from "@models/machines/machine";
import { TurnAuto } from "@models/turns/turn-auto";
import { checkAftOrMor } from "../checkAftOrMor";
import { checkInsideOnWork } from "../checkInsideOnWork";
import { Worker } from "@models/user/worker";
import { isPast } from "../isPast";
import { valBooksMul } from "./valBooksMult";
import { aparCheck } from "./aparCheck";

export const randomWorkerPickerV2 = (
    time: TimeV2,
    workers: Worker[],
    localDay: string,
    daySer: string,
    turnsAuto: TurnAuto[],
    bookings: Booking[],
    machine: Machine[],
    canary: boolean,
) => {
    const workersAv: string[] = [];
    workers.forEach(w => {
        const arrived = decideAutoOrWor(
            turnsAuto,
            w,
            localDay,
            daySer,

        );
        if (arrived.data.rest !== 'Nowork') {
            const morns = makeMinutes(
                arrived.data.intWorkerMor1,
                arrived.data.intWorkerMor2,
            );

            if (arrived.data.rest === 'Descanso') {
                const afts = makeMinutes(
                    arrived.data.intWorkerAft1,
                    arrived.data.intWorkerAft2,
                );
                const check = checkAftOrMor(morns, afts, time);
                if (check.via && !isPast(localDay, time, canary)) {
                    const validated = valBooksMul(
                        check.def.intStart,
                        check.def.intEnd,
                        time,
                        w.name,
                        bookings,
                    );
                    if (validated.valid && aparCheck(time, machine, bookings)) {
                        workersAv.push(w.name);
                    }
                }
            } else if (arrived.data.rest === 'Intensivo') {
                if (
                    checkInsideOnWork(morns.intStart, morns.intEnd, time) && !isPast(localDay, time, canary)
                ) {
                    const validated = valBooksMul(
                        morns.intStart,
                        morns.intEnd,
                        time,
                        w.name,
                        bookings,
                    );
                    if (validated.valid && aparCheck(time, machine, bookings)) {
                        workersAv.push(w.name);
                    }
                }
            }
        }
    });
    const valids = workersAv.length > 0;
    if (valids) {
        return workersAv;
    } else {
        return false;
    }
};
