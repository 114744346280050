import { AsyncAction } from '@components/ui/button/AsyncAction'
import { BoldText } from '@components/ui/text/BoldText'
import { db } from '@lib/firebase';
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import { useAppSelector } from '@redux/store/hooks';
import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react'

export const TrackWhats = () => {
    const user = useAppSelector(selectUser);
    const [loading, setloading] = useState(false)
    const sendWhats = async () => {
        setloading(true)
        const mess = {
            channelId: "1d89ff46-780d-4e5b-85f5-9d1ed9bd5aab",
            to: `34${user!.phone.trim()}`,
            type: "hsm",
            content: {
                hsm: {
                    namespace: "d6cedb90_0d9d_4f4e_877c_e772b56d0df8",
                    templateName: 'tracking',
                    language: {
                        policy: "deterministic",
                        code: "es_ES",
                    },
                    params: []
                },
            },
        };
        await addDoc(collection(db, `messages`), mess);
        setloading(false)
    }
    return (
        <div >
            <BoldText text={'Antes de utilizar esta función, asegúrate de que el cliente tiene guardado el número 618191688 como contacto y le ha mandado un mensaje y de que el su número de teléfono es español y está bien escrito'} />
            <AsyncAction classNames='d-block mx-auto' loading={loading} text={'Enviar whatsapp'} action={sendWhats} />
        </div>
    )
}
