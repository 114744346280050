import { Acc } from '@components/ui/accordion/Acc'
import { Bonus } from '@models/bono/bonus'
import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import { SubmitField } from '@components/formik/SubmitField'
import Switch from '@components/formik/Switch'
import { TextArea } from '@components/formik/TextArea'
import { SelImg } from '@components/formik/img/SelImg'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { toast } from 'react-hot-toast'

const schema = Yup.object().shape({
    nameBonus: Yup.string().required('Es necesario un nombre'),
    img: Yup.string().required('Es necesario una imagen'),
    description: Yup.string().required('Es necesario una descripción'),

})

export const InfoBono = (bono: Bonus) => {
    const formik = useFormik({
        initialValues: {
            nameBonus: bono.nameBonus, active: bono.active,
            img: bono.img,
            description: bono.description,
        },
        validationSchema: schema,
        onSubmit: async () => { },
    })
    const changeName = async () => {
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            nameBonus: formik.values.nameBonus
        })
        toast.success('Cambiado')
        
    }
    const changeActive = async () => {
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            active: formik.values.active
        })
        toast.success('Cambiado')
    }
    const changeDesc = async () => {
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            description: formik.values.description
        })
        toast.success('Cambiado')
    }
    const changeImg = async () => {
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            img: formik.values.img
        })
        toast.success('Cambiado')
    }
    return (
        <div>
            <Acc title={`Información de ${bono.nameBonus} `} id={'InfoBono'} classNames='mt-5 mb-5'>
                <Form formikVal={formik} >
                    <InlineInput name={'nameBonus'} label={'Nombra el bono'} />
                    <div className={`${JustEnd}`}>
                        <SubmitField text='Cambiar' name={'nameBonus'} loading={false} handleSubmit={changeName} />
                    </div>
                    <div className='d-flex justify-content-center mt-4 mb-4'>
                        <Switch nameField={'active'} holder={'¿El bono estará activo?'} defvalue={true} />
                    </div>
                    <div className={`${JustEnd}`}>
                        <SubmitField text='Cambiar' name={'active'} loading={false} handleSubmit={changeActive} />
                    </div>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Añade una descripción</label>
                    <div className='mb-4'>
                        <TextArea name={'description'} rows={4} />
                    </div>
                    <div className={`${JustEnd}`}>

                        <SubmitField text='Cambiar' name={'description'} loading={false} handleSubmit={changeDesc} />
                    </div>
                    <SelImg name='img' />
                    <div className={`${JustEnd} mt-4`}>
                        <SubmitField text='Cambiar' name={'img'} loading={false} handleSubmit={changeImg} />
                    </div>

                </Form>
            </Acc>
        </div>
    )
}
