import { db } from '@lib/firebase'
import { Product } from '@models/products/product'
import { selectorEditId, setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { onSnapshot, doc, deleteDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { EditProd } from './EditProd'
import { DelOp } from '@components/common/del/DelOp'
import toast from 'react-hot-toast'

export const EditCntPrr = () => {
    const id = useAppSelector(selectorEditId)
    const dispatch = useAppDispatch();
    const [prod, setprod] = useState<Product | null>(null)
    useEffect(() => {
        const sub = onSnapshot(doc(db, `products/${id}`), (snap) => {
            setprod(snap.data() as Product)
        })
        return () => {
            sub()
        }
    }, [id])
    const delProd = async () => {
        await deleteDoc(doc(db, `products/${prod?.productUid}`))
        dispatch(setCloseModal());
        toast.success('Producto eliminado')
    }
    return (
        <div>
            {
                prod !== null ?
                    <div>
                        <EditProd {...prod} />
                        <div className='p-4 mt-5'>
                            <DelOp del={delProd} quest={'¿Quieres eliminar el producto?'} text={'Eliminar'} />
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}
