import React from 'react'

export const Spinner = () => {
    return (
        <div className='d-block mx-auto'>
            <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}
