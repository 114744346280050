import { Acc } from '@components/ui/accordion/Acc'
import { serviceLimit } from '@models/bono/sessionsPack'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { SellsBonus } from '@models/bono/sellsBonus'
import { cloneDeep } from 'lodash'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
import { useAppDispatch } from '@redux/store/hooks'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'

interface Props {
    servicesLimit: serviceLimit,
    bono: SellsBonus,
    index: number
}
const initPers = { newSes: '' }
const schemaPers = Yup.object().shape({
    newSes: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError('Tiene que ser un número').integer('El número debe ser entero').test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
        return Number(value) === 0 || Number (value) > 0
      })
})
export const ChangePers = ({ servicesLimit, bono, index }: Props) => {
    const dispatch = useAppDispatch()
    const [loading, setloading] = useState<boolean>(false)
    const formPers = useFormik({
        initialValues: initPers,
        validationSchema: schemaPers,
        onSubmit: async (values) => {
            setloading(true)
            const { newSes } = values;
            const sesTr = cloneDeep(bono.servicesLimit) as serviceLimit[];
            sesTr[Number(index)].sesNumber = Number(newSes);
            await updateDoc(doc(db, `sellsBonus/${bono.id}`), {
                servicesLimit: sesTr
            })
            toast.success('Sesiones cambiadas')
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    return (
        <div className='mb-3'>

            <Acc title={`${servicesLimit.name}: ${servicesLimit.sesNumber} sesiones`} id={`${index}`}>
                <Form formikVal={formPers}>
                    <div className='w-300px d-block m-auto'>
                        <FormField name={'newSes'} label={'Introduce las nuevas sesiones'} type={'text'} />
                        <div className='d-flex justify-content-center'>
                            <SubmitBtn loading={loading} />
                        </div>

                    </div>
                </Form>
            </Acc>
        </div>
    )
}
