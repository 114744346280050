import { PhotoItem } from '@components/ui/item/PhotoItem'
import { db } from '@lib/firebase'
import { Services } from '@models/services/services'
import { Ticket } from '@models/ticket/ticket'
import { selectServices } from '@redux/features/gquerySlice/gquerySlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { BoldText } from '@components/ui/text/BoldText'
import { Spinner } from 'react-bootstrap'

interface Props {
    ticket: Ticket
}
export const BonoTickSes = ({ ticket }: Props) => {
    const dispatch = useAppDispatch()
    const services = useAppSelector(selectServices);
    const [loading, setloading] = useState(false)
    const [sersDelete, setsersDelete] = useState<Services[]>([])
    useEffect(() => {
        const used = ticket.servicesUsed;
        const sersUsed = services.filter(el => used.some((u) => u.id === el.serviceId));
        setsersDelete(sersUsed);
    }, [services, ticket])

    const deleteItem = async (index: number) => {
        setloading(true)
        try {
            const serSel = sersDelete.filter((_, i) => i === index)[0]
            const newServices = ticket.servicesUsed.filter(s => s.id !== serSel.serviceId);
            await updateDoc(doc(db, `tickets/${ticket.uid}`), {
                servicesUsed: newServices
            })
            toast.success('Hecho')
            dispatch(setCloseModal())
        } catch (error) {
            toast.error('Error al cambiar al quitar el servicio')
            Sentry.configureScope(scope => scope.setTransactionName('Error change ticket ses'))
            Sentry.captureException(error, { user: { id: ticket.code } });
        }
        setloading(false)
    }

    return (
        <div>
            <BoldText text={sersDelete.length > 0 ? `Servicios utilizados` : 'No se ha utilizado ningún servicio'} classNames='text-center' />
            {loading ?
                <Spinner />
                :
                sersDelete.map((ser: Services, index: number) => (
                    <div key={ser.serviceId} className='me-5'>
                        <PhotoItem
                            index={index}
                            img={ser?.img as string}
                            title={ser?.serviceName as string}
                            subText={ser?.cat}
                            deleteFun={() => deleteItem(index)} whatever={undefined} />
                    </div>
                ))
            }
        </div>
    )
}
