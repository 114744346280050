import { Col } from '@assets/sass/bootstrapEx'
import { InlineInput } from '@components/formik/InlineInput'
import { RadioField } from '@components/formik/radio/RadioField'
import { Acc } from '@components/ui/accordion/Acc'
import { ResCol } from '@components/ui/container/ResCol'
import { BoldText } from '@components/ui/text/BoldText'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Services } from '@models/services/services'
import { SubmitField } from '@components/formik/SubmitField'
import { Form } from '@components/formik/Form'
import toast from 'react-hot-toast'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'

interface Props {
    ser: Services
}
const schema = Yup.object().shape({
    active: Yup.string().required('Determina el estado del servicio'),
    doubleWorker: Yup.string().required('Determina si es de doble emplead@'),
    apar: Yup.string().required('Determina si es de aparatología'),
})

export const SerAdition = ({ ser }: Props) => {
    const formik = useFormik({
        initialValues: {
            doubleWorker: ser.doubleWorker ? 'si' : 'no',
            active: ser.active ? 'si' : 'no', 
            apar: ser.apar ? 'si' : 'no', 
        },
        validationSchema: schema,
        onSubmit: async () => { },
    })
    const changeActive = async () => {
        await updateDoc(doc(db, `services/${ser.serviceId}`), {
            active: formik.values.active === 'si' ? true : false
        })
        toast.success('Cambiado')
    }
    const changeDouble = async () => {
        if (ser.isExpo === true) {
            return toast.error('El servicio no puede ser doble y de exposición')
        }
        if (ser.apar === true) {
            return toast.error('El servicio no puede ser doble y de aparatología')
        }
        await updateDoc(doc(db, `services/${ser.serviceId}`), {
            doubleWorker: formik.values.doubleWorker === 'si' ? true : false
        })
        toast.success('Cambiado')
    }
    const changeApar = async () => {
        if (ser.doubleWorker === true) {
            return toast.error('El servicio no puede ser doble y de aparatología')
        }
        await updateDoc(doc(db, `services/${ser.serviceId}`), {
            apar: formik.values.apar === 'si' ? true : false,
            sesApar: formik.values.apar === 'si' ? 1 : 0,
        })
        toast.success('Cambiado')
    }
    return (
        <Acc title={'Información adicional'} id={'adition'} >
            <Form formikVal={formik}>
                <ResCol childrenFirst={
                    <div className={`${Col}`}>
                        <BoldText text='¿El servicio requiere dos empleados?' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'doubleWorker'} value={'si'} />
                            <RadioField title={'No'} name={'doubleWorker'} value={'no'} />
                        </div>
                        <div className='d-flex justify-content-center mb-5'>
                            <SubmitField loading={false} handleSubmit={changeDouble} name={'doubleWorker'} text='Cambiar' />
                        </div>
                    </div>
                } childrenSecond={
                    <div className={`${Col}`}>
                        <BoldText text='¿El servicio estará activo?' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'active'} value={'si'} />
                            <RadioField title={'No'} name={'active'} value={'no'} />
                        </div>
                        <div className='d-flex justify-content-center mb-5'>
                            <SubmitField loading={false} handleSubmit={changeActive} name={'active'} text='Cambiar' />
                        </div>
                    </div>
                } />
                <ResCol childrenFirst={
                    <div className={`${Col}`}>
                        <BoldText text='Marcar servicio aparatología' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'apar'} value={'si'} />
                            <RadioField title={'No'} name={'apar'} value={'no'} />
                        </div>
                        <div className='d-flex justify-content-center mb-5'>
                            <SubmitField loading={false} handleSubmit={changeApar} name={'apar'} text='Cambiar' />
                        </div>
                    </div>
                } childrenSecond={null} />
            </Form>
        </Acc>
    )
}
