


import {  ReactNode } from 'react';
import { KTSVG } from '_metronic/helpers';
interface Props {
    title: string;
    subtitle: string,
    children: ReactNode
}

export default function CardWarning({ 
    title,
    subtitle,
    children,
}: Props) {
    return (
        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTSVG
                path='icons/duotune/general/gen044.svg'
                className='svg-icon-2tx svg-icon-warning me-4'
            />
            <div className='d-flex flex-column w-100' >
                <div className='fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>{title} </h4>
                    <p className='text-gray-800 fw-bolder'> {subtitle} </p>
                </div>
                {
                    children
                }
            </div>
        </div>
    )
}