interface Props {
    label: string,
    icon: string,
    tabKey: string,
    isFirst?: boolean
}
export const NavSec = ({ label, icon, tabKey, isFirst }: Props) => {
    // nav-title-est display none on small device + change width and height to minus 
    return (
        <li className="nav-item ">
            <a
                className={`nav-link ${isFirst ? 'active' : ''} box-nav `}
                data-bs-toggle="tab"
                href={`#kt_${tabKey}`}
            >
                <p className='mb-1 nav-title-est'>{label}</p> 
                <div className={` box-li rounded-2 d-flex justify-content-center p-3 `}>
                    <i className={`text-white ${icon} fs-2x`}></i>
                </div>
            </a>
        </li>
    )
}
