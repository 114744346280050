
import { MbscEventcalendarView} from '@mobiscroll/react';
export const initSchedule: MbscEventcalendarView = {
    schedule: {
        type: 'day',
        startDay: 1,
        endDay: 6,
        startTime: '08:00',
        endTime: '22:00',
        allDay: false,
        timeCellStep: 30,
        timeLabelStep: 60,
        currentTimeIndicator: true,
    }
}

export type prevs = {
    prevFirst: Date | null,
    prevLast: Date | null
}

export type Cell = {
    date: Date,
    resource: string
}
