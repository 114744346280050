import { BigBook } from "@components/common/wiz/wiztypes/BigBookWizHelper";
import { switchDaysForCalendar } from "@lib/helpers/dates/switchDaysForCalendar";
import { getNoWorkWeeks } from "@lib/helpers/turns/getNoWorkWeeks";
import { saveAutoDatesOne } from "@lib/helpers/turns/saveAutoDatesOne";
import { IMyDate } from "@models/bookings/IMyDate";
import { Centers } from "@models/centers/center";
import { TurnAuto } from "@models/turns/turn-auto";
import { Worker } from "@models/user/worker";
import { useCallback, useEffect, useState } from "react";
export interface Rec {
    background: string,
    recurring: {
        repeat: 'weekly',
        weekDays: string
    }
}
export default function useTurnsWorkersDisabled(turnsAuto: TurnAuto[], workers: Worker[], values: BigBook, center: Centers | null) {
    const [festives, setfestives] = useState<any>([]);
    const [enabledDates, setenabledDates] = useState<Date[]>([]);
    const [recs, setrecs] = useState<Rec[]>([])
    const concatOp = useCallback(
      (uids: string[]) => {
        let recWorker: string[] = [];
        let disAutoWor: IMyDate[] = []
        let enAutoWorkers: IMyDate[] = [];
        uids.forEach(uid => {
            const worker = workers.filter(el => el.uid === uid)[0];
            recWorker = recWorker.concat(getNoWorkWeeks(worker.turns));
            const autoRes = saveAutoDatesOne(uid, turnsAuto);
            disAutoWor = disAutoWor.concat(autoRes.autoDisDates);
            enAutoWorkers = enAutoWorkers.concat(autoRes.enabledDates);
        })
        return {recWorker, disAutoWor, enAutoWorkers}
      },
      [turnsAuto, workers],
    )
    
    useEffect(() => {
        if (center && values.multArr.length > 0) {
            const mult = values.multArr;
            let recurringDatesWorker: string[] = [];
            let disabledAutoWorkers: IMyDate[] = []
            let enabledAutoWorkers: IMyDate[] = [];
            mult.forEach(m => {
                if (m.workerId.includes('Cualquier') === false && m.workerId !== '') {
                    const {recWorker, disAutoWor, enAutoWorkers} = concatOp(mult[0].service.doubleWorker ? m.workerId.split(',') : [m.workerId])
                    recurringDatesWorker = recurringDatesWorker.concat(recWorker); 
                    disabledAutoWorkers = disabledAutoWorkers.concat(disAutoWor);
                    enabledAutoWorkers = enabledAutoWorkers.concat(enAutoWorkers);
                }
            })
            const disabledDatesCenter = center.disabledDates.concat(disabledAutoWorkers);
            enabledAutoWorkers = enabledAutoWorkers.filter(el => disabledDatesCenter.find(d => d.day === el.day && el.month === d.month && d.year === el.year));
            const recurringDaysCenter = center.rawCenterTime.filter((el: any) => el.openCenter === 'Cerrado').map((value) => switchDaysForCalendar(value.dayCenter)).join(',');
            const recCenter: Rec = {
                background: '#f0f0f0',
                recurring: {
                    weekDays: recurringDaysCenter, 
                    repeat: 'weekly'
                }
            }
            const recWorker: Rec = {
                background: '#f0f0f0',
                recurring: {
                    weekDays: recurringDatesWorker.join(','),
                    repeat: 'weekly'
                }
            }
            setrecs([recCenter, recWorker])
            setfestives([...disabledDatesCenter.map((value) => new Date(value.year, (value.month - 1), value.day))]);
            setenabledDates(enabledAutoWorkers.map((value) => new Date(value.year, (value.month - 1), value.day)))
        }

    }, [center, turnsAuto, workers, values.multArr, values.doubleWorker, concatOp])


    return {
        enabledDates,
        festives,
        recs
    }
}

