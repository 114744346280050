
import { DefTurns } from '@components/workers/DefTurns';
import { TurnAuto } from '@models/turns/turn-auto';
import { TurnGlobal } from '@models/turns/turnGlobal';
import { useEffect, useState } from 'react';
import { ShowAuto } from '../ShowAuto';
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form';
import { ActionButton } from '@components/ui/button/ActionButton';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { toast } from 'react-hot-toast';
import {cloneDeep} from 'lodash';
import { mapTurns } from '@lib/helpers/turns/mapTurns';
import { DelOp } from '@components/common/del/DelOp';
import { Turns } from '@models/turns/turns';
import { format, parse } from 'date-fns';
import { retShowing } from '@lib/helpers/dates/retShowing';

const initialValues = {
  intWorkerMor1: '',
  intWorkerMor2: '',
  intWorkerAft1: '',
  intWorkerAft2: '',
  rest: '',
  turns: [],
  day: '',
  name: ''
}

interface Props {
  back: () => void;
  isEdit: () => void;
}
export const EditAuto = ({back, isEdit}: Props) => {
  const [sel, setsel] = useState<TurnGlobal | TurnAuto | null>(null)

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (sel!== null) {
        if (JSON.stringify(sel.turns) !== JSON.stringify(values.turns)) {
          const nameCol = 'workerDoc' in sel ? 'turnPlan' : 'turnGlobal';
          const oldArr = cloneDeep(sel.turns)
          const indexOld = oldArr.findIndex(v => v.day === values.day);
          oldArr[indexOld] = {...oldArr[indexOld], ...values}
          const def = mapTurns(oldArr) as Turns[];
          if (def.some(t => t.showDate === undefined) && nameCol === 'turnPlan') {
            const t = sel as TurnAuto;
            const arr = t.stringShow.split(' ')
            const preTurns: any[] = [];
            const start = parse(arr[0], 'd/M/yyyy', new Date());
            const end = parse(arr[4], 'd/M/yyyy', new Date());
            while (start <= end) {
                const preTurn = {
                  showDate: format(start, 'd/M/yyyy'),
                  filterDate: retShowing(start),
                };
                preTurns.push(preTurn);
                start.setDate(start.getDate() + 1);
            }
            for (let i = 0; i < def.length; i++) {
              def[i].showDate = preTurns[i].showDate;
              def[i].filterDate = preTurns[i].filterDate;
            }
          }
          await updateDoc(doc(db, `${nameCol}/${sel.uid}`), {
            turns: def
          })
        } else {
          toast.error('No has cambiado nada')
        }
      }
      setsel(null)
      back()
    },
  })
  useEffect(() => {
    if (sel !== null) {
      isEdit()
    }
  }, [sel, isEdit])
  const deleteAuto = async () => {
    const col = 'filterDate' in (sel as TurnAuto) ? 'turnPlan' : 'turnGlobal';
    setsel(null)
    await deleteDoc(doc(db, `${col}/${sel?.uid}`))
    toast.success('Horario eliminad@')
    back()

  } 
  return (
    <div>
      <ShowAuto sel={sel} setsel={setsel}/>
      {
        sel !== null && 
        <div>
          <Form formikVal={formik}>
            <DefTurns  needsReset={false} currTurns={sel.turns} />
          </Form>
          <div className=' d-flex justify-content-between align-items-center mt-10'>
            <ActionButton text={'Volver'} action={() => {
              setsel(null)
              back()
              }} classNames='mt-10' />
            <DelOp del={deleteAuto} quest={'¿Quieres eliminar el horario?'} text={'Eliminar'} />
          </div>
        </div>
      }
    </div>
  )
}
