import { ActionButton } from '@components/ui/button/ActionButton'
import { db } from '@lib/firebase'
import { collection, getDocs } from 'firebase/firestore'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
interface Props {
    name: string
}
export const SelImg = ({name}: Props) => {
    const {setFieldValue, values} = useFormikContext<any>()
    const [imgs, setimgs] = useState<string[]>([])
    useEffect(() => {
        let active = true;
        if(active) {
            const fetch = async () => {
                const is = (await getDocs(collection(db, 'front-img'))).docs.map((value) => value.data());
                setimgs(is.map((value) => value.imgUrl))
            }
            fetch()
        }
        return () => {
            active = false;
        }
    }, [setFieldValue, name])
    const chooseImg = () => {
        setFieldValue(name, '')
    }
  return (
    <div className='mt-5'>
        {
            values[name] !== '' ?
            <div className='rounded-2 w-100 h-150px d-flex  align-items-baseline justify-content-end' style={{backgroundImage: `url(${values[name]})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <ActionButton important={true} text={'Elige una foto'} action={chooseImg} />
            </div>
            : 
            <div >
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Elige una foto</label>
                <div className='d-flex flex-wrap flex-row justify-content-center rounded-2'>
                    {
                        imgs.map((value, index) => (
                            <div key={`${value}-${index}`} onClick={() => setFieldValue(name, value)} className='m-2 is-pointer'  style={{backgroundImage: `url(${value})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 120, height: 100}} />
                        ))
                    }
                </div>
            </div>
        }
    </div>
  )
}
