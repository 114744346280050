import { createSearchStr } from "@lib/helpers/common/createSearchStr";
import { useEffect, useMemo, useState } from "react";

export default function useFilterTable(data: any[], changeResults?: number) {
    const [query, setDebounceQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dataTable, setDataTable] = useState<any[]>([]); //tableTable for showing on table according to filtering
    const [finalData, setfinalData] = useState<any[]>([]);
    const [totalResults, settotalResults] = useState(data.length)
    const [resultsPerPage, setResultsPerPage] = useState(10)
    const dataMem = useMemo(() => {
        return data.map((value) => ({...value, searchString: createSearchStr(JSON.stringify(value))}))
    }, [data])
    useEffect(() => {
        if (query === '') {
            setfinalData(dataMem)
        } else {
            const final = dataMem.filter(el => el.searchString.indexOf(createSearchStr(query)) > -1);
            setfinalData(final)
        }
    }, [dataMem, query])

    useEffect(() => {
        if (query !== '') {
            setCurrentPage(1)
        }
    }, [query])
    

    useEffect(() => {
        settotalResults(finalData.length)
    }, [finalData.length])
    

    const handleChangePage = (p: number) => {
        setCurrentPage(p);
    };

    useEffect(() => {
        if (changeResults !== undefined) {
            setResultsPerPage(changeResults)
        }
    }, [changeResults])
    

    useEffect(() => {
        
        setDataTable(
            finalData.slice(
                (currentPage - 1) * resultsPerPage,
                currentPage * resultsPerPage
            )
        );
    }, [finalData, currentPage, resultsPerPage]);

    return {
        finalData,
        totalResults,
        resultsPerPage,
        dataTable,
        currentPage,
        handleChangePage,
        setDebounceQuery
    }

}