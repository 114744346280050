import { useEffect, useState } from "react";
import { Option } from '@models/opts/Option'

const usePickHour = () => {
    const [optsDispo, setoptsDispo] = useState<Option[]>([]);
    
    useEffect(() => {
        const h: string[] = [];
        const x = 5;
        let tt = 0;
        for (let i = 0; tt <= 24 * 60; i++) {
            const hh = Math.floor(tt / 60);
            const mm = (tt % 60);
            const time = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            tt = tt + x;
            h.push(time);
        }
        h.splice(0, 106);
        h.shift();
        setoptsDispo(h.map((value) => ({ label: value, value: value })))
    }, [])
    return {
        optsDispo
    }
}

export default usePickHour