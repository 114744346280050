import { db } from '@lib/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useCallback, useState } from 'react'
import { Sugg } from '../autocomplete/Sugg';
import { UserFinal } from '@models/user/userFinal';
import { useAuth } from 'app/modules/auth';
import useComponentVisible from '@lib/hooks/ui/useComponentVisible';
interface Props {
  userPicked: (us: UserFinal) => void;
}
export const UserSearch = ({userPicked}: Props) => {
    const {centerId} = useAuth()
    const [filter, setfilter] = useState<UserFinal[]>([]);
    const [searchTerm, setSearch] = useState<string>('');
    const { setIsComponentVisible, isComponentVisible, ref, handlerRef } = useComponentVisible(false)
    const [timer, setTimer] = useState<any>(null)
    const trFun = useCallback((str: string) => {
      return str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }, [])
    const search = useCallback(
        async (text: string) => {
            const trStr = trFun(text);
            const arr = trStr.split(' ');
            const q = query(collection(db, 'users'), 
            where('search', 'array-contains', trStr), 
            where('centerClient', '==', centerId));
            const qArr = query(collection(db, 'users'), 
            where('search', 'array-contains-any', arr), 
            where('centerClient', '==', centerId));
            const res = ((await getDocs(q)).docs).map((v) => v.data()) as UserFinal[];
            const resArr = ((await getDocs(qArr)).docs).map((v) => v.data()) as UserFinal[];
            const cleanResArr = resArr
            .filter(el => trFun(el.displayName).includes(trStr))
            .filter(el => !res.find((x => x.uid === el.uid)));
            const total = res.concat(cleanResArr);
            setfilter(total);
    }, [centerId, trFun])


    const inputChanged = (e:any) => {
      setSearch(e.target.value)
        if (timer) {
            clearTimeout(timer)
        }
  
      const newTimer = setTimeout(() => {
        if (searchTerm.length > 2) {
            setIsComponentVisible(true)
            search(searchTerm)
        } else {
          setIsComponentVisible(false)
            setfilter([])
        }
      }, 50)
  
      setTimer(newTimer)
    }

    const suggSel = (item: UserFinal) => {
          setIsComponentVisible(false)
          userPicked(item)
          setSearch(item.displayName)
    }
    
  return (
    <div>
    <div className={`d-flex position-relative flex-column`} ref={handlerRef}>
      <input
        autoComplete='off'
        type='text'
        className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 `}
        placeholder={'Buscar clientes'}
        onChange={inputChanged}
        value={searchTerm}
      />
      <div
        ref={ref}
        className={` ${!isComponentVisible ? 'd-none' : ''} over-scroll card position-absolute z-index-1 w-100 sugg-app `}>
          <Sugg sugg={filter} suggSel={suggSel} />
      </div>
    </div>
  </div>
  )
}
