import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { InlineInput } from '@components/formik/InlineInput'
import { Form } from '@components/formik/Form'
import { Col, JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { InlineSelect } from '@components/formik/InlineSelect'
import { SelCat } from './SelCat'
import '@assets/sass/cats.scss'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { selectCats, selectServices } from '@redux/features/gquerySlice/gquerySlice'
import { TextArea } from '@components/formik/TextArea'
import { ResCol } from '@components/ui/container/ResCol'
import { BoldText } from '@components/ui/text/BoldText'
import { RadioField } from '@components/formik/radio/RadioField'
import { SelImg } from '@components/formik/img/SelImg'
import { toast } from 'react-hot-toast'
import { useAuth } from 'app/modules/auth'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import useHoursSer from '@lib/hooks/services/useHoursSer'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import * as Sentry from '@sentry/react';
import ExpoArr from './ExpoArr'
import { toMinutes } from '@lib/helpers/dates/toMinutes'
import { timeToString } from '@lib/helpers/dates/timeToString'
// https://codesandbox.io/p/sandbox/dynamic-forms-with-validation-react-formik-ugezfx?file=%2Fsrc%2Fapp.js
const initialValues = {
    price: '',
    serviceName: '',
    cat: '',
    duration: '',
    numberSessions: '',
    img: '',
    description: '',
    typeExpo: 'SERVICE_REST',
    doubleWorker: 'no', active: 'si', apar: 'no', isExpo: 'no', expoArr: [
        {
            dur: '',
            rest: false,
            index: 0
        },
        {
            dur: '',
            rest: true,
            index: 1
        }
    ]
}
const schema = Yup.object().shape({
    price: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon un precio').test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
        return Number(value) === 0 || Number(value) > 0
    }).typeError('Tiene que ser un número'),
    numberSessions: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon las sesiones').positive('Número positivo').typeError('Tiene que ser un número').integer('Sin decimales'),
    serviceName: Yup.string().required('Pon un nombre'),
    duration: Yup.string().when('isExpo', {
        is: (isExpo: string) => isExpo === 'no',
        then: Yup.string().required('Falta duración'),
        otherwise: Yup.string(),
    }),
    description: Yup.string().required('Pon una descripción'),
    cat: Yup.string().required('Pon una categoría'),
    img: Yup.string().required('Pon una imagen'),
    active: Yup.string().required('Determina el estado del servicio'),
    doubleWorker: Yup.string().required('Determina si es de doble emplead@'),
    apar: Yup.string().required('Determina si es de aparatología'),
    isExpo: Yup.string(),
    expoArr: Yup.array().of(
        Yup.object().shape({
            dur: Yup.string().when('isExpo', {
                is: (isExpo: string) => isExpo === 'si',
                then: Yup.string().required('Falta duración'),
                otherwise: Yup.string(),
            }),
            rest: Yup.boolean(),
            index: Yup.number()
        })
    )

})

export const CreateService = () => {
    const dispatch = useAppDispatch()
    const { centerId } = useAuth();
    const cats = useAppSelector(selectCats)
    const services = useAppSelector(selectServices)
    const [loading, setloading] = useState<boolean>(false)
    const { optsDispo } = useHoursSer()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            if (values.isExpo === 'si' && values.doubleWorker === 'si') {
                return toast.error('El servicio no puede ser doble y de exposición')
            }
            if (values.apar === 'si' && values.doubleWorker === 'si') {
                return toast.error('El servicio no puede ser doble y de aparatología')
            }
            if (services.some(el => el.serviceName === values.serviceName)) {
                toast.error('Ya hay un servicio con ese nombre')
            } else {
                setloading(true)
                const col = collection(db, 'services');
                const id = doc(col).id;
                try {
                    const isExpo = values.isExpo === 'si'
                    const s = {
                        ...values,
                        centerId: centerId,
                        numberSessions: Number(values.numberSessions),
                        doubleWorker: values.doubleWorker === 'si',
                        active: values.active === 'si',
                        apar: values.apar === 'si',
                        sesApar: values.apar === 'si' ? 1 : 0,
                        serviceId: id,
                        isExpo: isExpo,
                        duration: isExpo ? timeToString(values.expoArr.map((value) => 
                        toMinutes(value.dur)).reduce((a, b) => +a + +b)) : values.duration
                    }
                    await setDoc(doc(db, `services/${s.serviceId}`), s)
                    setValues({ ...initialValues })
                    resetForm()
                    setloading(false)
                    dispatch(setCloseModal())
                } catch (error) {
                    Sentry.configureScope(scope => scope.setTransactionName('Error create service'))
                    Sentry.captureException(error);
                    toast.error('Error al crear el servicio')
                }
            }
        },
    });
    const {values, setValues} = formik;


    

    return (
        <div className='p-5'>
            <Form formikVal={formik}>
                <InlineInput name='serviceName' label={'Nombra el servicio'} />
                <InlineInput name='numberSessions' label={'Pon las sesiones que gasta en un bono'} />
                <InlineInput name='price' label={'Pon el precio'} />
                <InlineSelect search={true} opts={optsDispo} name={'duration'} label={'Selecciona duración (excepto si el servicio es exposición)'} />
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Selecciona una categoría</label>
                <div className='container'>
                    <div className='d-flex flex-wrap bg-light-aqua rounded-2 pt-3'>
                        {
                            cats.map((cat) => (
                                <div key={cat.uid} style={{ width: 200, height: 90 }}>
                                    <SelCat name='cat' value={cat.name} nameCat={cat.name} iconCat={cat.iconCat ? cat.iconCat : ''} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <label className='col-lg-4 col-form-label required fw-bold fs-6 mb-5'>Información adicional</label>
                <ResCol childrenFirst={
                    <div className={`${Col}`}>
                        <BoldText text='¿El servicio requiere dos empleados?' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'doubleWorker'} value={'si'} />
                            <RadioField title={'No'} name={'doubleWorker'} value={'no'} />
                        </div>
                    </div>
                } childrenSecond={
                    <div className={`${Col}`}>
                        <BoldText text='¿El servicio estará activo?' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'active'} value={'si'} />
                            <RadioField title={'No'} name={'active'} value={'no'} />
                        </div>
                    </div>
                } />
                <ResCol childrenFirst={
                    <div className={`${Col}`}>
                        <BoldText text='Marcar servicio aparatología' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'apar'} value={'si'} />
                            <RadioField title={'No'} name={'apar'} value={'no'} />
                        </div>
                    </div>
                } childrenSecond={
                    <div className={`${Col}`}>
                        <BoldText text='Marcar servicio exposición' classNames='text-center' />
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Sí'} name={'isExpo'} value={'si'} />
                            <RadioField title={'No'} name={'isExpo'} value={'no'} />
                        </div>
                    </div>
                } />
                {
                    formik.values.isExpo === 'si' ?
                        <ExpoArr />
                    : null
                }
                <div>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Pon una descripción</label>
                    <TextArea name={'description'} rows={4} />
                </div>

                <SelImg name='img' />
                <div className={`${JustEnd} mt-5`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>

        </div>
    )
}
