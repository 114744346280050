import { StepperComponent } from '_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { KTSVG } from '_metronic/helpers'
import { Spinner } from '@components/ui/spinner/Spinner'
import { SubmitWiz } from './SubmitWiz'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { BonusSell, BonusSellSchemas, initBonusSell } from './wiztypes/BonoSellsWizHelper'
import useAddSellsBonus from '@lib/hooks/sells/useAddSellsBonus'
import { selectUser } from '@redux/features/clientSlice/clientSlice'
import { UserFinal } from '@models/user/userFinal'
import toast from 'react-hot-toast'
import { TicketForm, TicketFormSchema, initTicketForm } from './wiztypes/CreateWizTicket'
import useCreateTicket from '@lib/hooks/ticket/useCreateTicket'
import { BonoForm, BonoFormSchema, initBonoForm } from './wiztypes/CreateBonoWiz'
import useCreateBono from '@lib/hooks/bonos/useCreateBono'
interface Props {
    navs: React.ReactNode,
    contentForm: React.ReactNode,
    type: WizContent,
    closeModal?: () => void;
}
export type WizContent = 'FAST_BOOK' | 'BIG_BOOK' | 'BONUS_SELL' | 'TICKET_FORM' | 'BONO_FORM'
export const Wiz = ({ navs, type, contentForm, closeModal }: Props) => {
    const dispatch = useAppDispatch();
    const {createTicket} = useCreateTicket()
    const {createBono} = useCreateBono()
    const userSells = useAppSelector(selectUser);
    const {addSellBonus} = useAddSellsBonus()
    const [loading, setloading] = useState<boolean>(false); 
    const schema: any[] = useMemo(() => {
        if(type === 'BONUS_SELL') {
            return BonusSellSchemas
        } else if (type === 'TICKET_FORM') {
            return TicketFormSchema
        } else if (type === 'BONO_FORM') {
            return BonoFormSchema
        }
        return []
    }, [type])

    const inits: any = useMemo(() => {
        if (type === 'BONUS_SELL') {
            return initBonusSell
        } else if (type === 'TICKET_FORM') {
            return initTicketForm
        } else if (type === 'BONO_FORM') {
            return initBonoForm
        }
    }, [type])

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(schema[0])
    const [initValues] = useState<any>(inits)
    const [isSubmitButton, setSubmitButton] = useState(false)

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()

        setCurrentSchema(schema[stepper.current.currentStepIndex - 1])

        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
    }

    const submitStep = async (values: any, actions: FormikValues) => {
        if (!stepper.current) {
            return
        }
        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            if (type === 'TICKET_FORM') {
                setloading(true);
                const t = values as TicketForm;
                await createTicket(t);
                setloading(false)
            }
            stepper.current.goNext()
        } else {
            if (type === 'TICKET_FORM') {
                dispatch(setCloseModal())
                return;
            }
            setloading(true);
            if (type === 'BONUS_SELL') {
                const bonusSells = values as BonusSell;
                const isGood = await addSellBonus(bonusSells, userSells as UserFinal, bonusSells.meth);
                if (isGood) {
                    toast.success('Venta realizada')
                    dispatch(setCloseModal())
                } else {
                   toast.error('Error al realizar la venta') 
                }
            } else if (type === 'BONO_FORM') {
                const v = values as BonoForm;
                await createBono(v);
                dispatch(setCloseModal())
            }
            stepper.current.goto(1)
            actions.resetForm()
        }

        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)

        setCurrentSchema(schema[stepper.current.currentStepIndex - 1])
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])
    return (
        <div className='card'>
            <div className='card-body p-0'>
                <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                >
                    <div className='stepper-nav mb-5'>
                        {navs}
                    </div>

                    <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                        {() => (
                            <Form className='mx-auto mw-600px w-100 pb-10' id='kt_create_account_form'>
                                
                                {contentForm}

                                <div className='d-flex flex-stack pt-15'>
                                    <div className='mr-2'>
                                        {
                                            type === 'TICKET_FORM' && stepper.current && stepper.current.currentStepIndex === 2 ? null :
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                Volver
                                            </button>
                                        }
                                    </div>
                                    <div>
                                        {
                                            loading &&  
                                            <div className='me-3'>
                                                <Spinner /> 
                                            </div>
                                        }
                                        {
                                            !loading && stepper.current !== null &&
                                            <SubmitWiz type={type} currentIndex={stepper.current.currentStepIndex} isSubmitButton={isSubmitButton} />
                                        }
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}
