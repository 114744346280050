
interface Props {
    text: string,
    icon: string,
    classContainer?: string
    subText?: string,
    asideText?: string,
    color?: string
    back?: string,
    isTable?: boolean

}
export const InfoItem = ({ text, icon, subText, asideText, color, back, isTable}: Props) => {
    // ${classContainer ? classContainer : ''}
    return (
        <div className={`d-flex align-items-center w-100  rounded mb-7 `} style={{maxWidth: 240}}>
            <div className='symbol symbol-50px me-5'>
                <span className={`symbol-label ${back ? back : ''}`} style={{backgroundColor: color ? color : (back ? '' : '#e8fff3')}}>
                    <i className={`${icon} ${color ? 'text-white' : (back ? 'text-white' : 'text-success')} fs-2x`}></i>
                </span>
            </div>
            <div className='flex-grow-1 me-2'>
                <p className={`fw-bold  ${isTable ? 'size-table' : 'fs-6'} mb-0`}>
                    {text}
                </p>
                <span className='fw-semibold d-block'>{subText ? subText: ''}</span>
            </div>
            <span className='fw-bold text-warning py-1'>{asideText ? asideText : ''}</span>
        </div>
    )
}
