import React from 'react'
interface Props {
    text: string,
    specialColor?: string,
    classNames?: string
}
export const TDText = ({text, specialColor, classNames}: Props) => {
    return (
        <td className='w-25px'>
            <p 
                style={{color: specialColor? specialColor : undefined}} 
                className={`${specialColor ? '' : 'text-dark'} ${classNames ? classNames : ''} size-table text-gray-700 fw-semibold mb-1 `}>
                {text}
            </p>
        </td>
    )
}
