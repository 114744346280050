import { Booking } from "@models/bookings/booking"
import { Stopper } from "@models/stopper/stopper"

/* 
    has to save service Uid
*/
export const retStopper = (book: Booking) => {
    const stopper: Stopper = {
        initDate: book.StartTime,
        centerId: book.centerId,
        serviceName: book.serviceName,
        idBook: book.idBook,
        momentIntervalEnd: book.momentIntervalEnd,
        momentIntervalIni: book.momentIntervalIni,
        workerName: book.workerId,
        showingDate: book.showingDate,
        doubleWorker: book.doubleWorker,
        customerUid: book.customerUid,
        serviceId: book.serviceId
    }
 return stopper
}