import { Field, FieldProps, useFormikContext } from 'formik';
import { useEffect } from 'react';
import Select from 'react-select'
interface Option {
  value: string;
  label: string;
}

interface Props {
  search: boolean,
  opts: Option[],
  name: string
  placeHolder: string,
  disabled?: boolean,
  clearable?: boolean,
  className?: string
}
export const SelectField = ({ search, opts, name, placeHolder, disabled, clearable, className }: Props) => {
  const {setFieldValue, setFieldTouched} = useFormikContext();
  useEffect(() => {
    if (disabled && disabled === true) {
       setFieldValue(name, '')
    }
  }, [disabled, setFieldValue, name])
  
  return (

    <Field name={name}>
      {({ field, meta, }: FieldProps) => (
        <div>
          {
            placeHolder !== '' ?
            <label className='form-label fs-6 fw-bolder text-dark'>{placeHolder}</label>
            : null
          }
          <Select
            {...field}
            id={name}
            name={field.name}
            options={opts}
            value={field.value === '' ? field.value : opts.find(option => option.value === field.value)}
            onBlur={(event) => {
              event.preventDefault()
              const t = setTimeout(() => {
                setFieldTouched(name, true);
                clearTimeout(t)
              }, 100)
            }}
            onChange={(newValue) => {
              console.log(newValue)
              if (newValue === null) {
                setFieldValue(name, '')
              } else {
                const opt = newValue as Option
                setFieldValue(name, opt.value)
              }
            }}
            isSearchable={search}
            isClearable={clearable ? clearable : false}
            className={`est-select-container ${className ? className : '' }`}
            classNamePrefix="est-select"
            placeholder={'Seleccionar...'}
            noOptionsMessage={() => "Ninguna opción disponible"}
            isDisabled={disabled ? disabled : false}
          />
          {meta.touched && meta.error &&
            <div className='fv-plugins-message-container'>
              <span role='alert' className='text-warning'>{meta.error}</span>
            </div>
          }
        </ div>
      )}
    </Field>
  )
}
