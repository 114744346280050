import { MultPreSer } from '@models/bookings/MultPre'
import React from 'react'
interface Props {
    multArr: MultPreSer[]
}
export const TotalMult = ({ multArr }: Props) => {
    const total = multArr.filter(el => el.bono === false && el.ticketUsed === false).reduce((a, b) => +a + +Number(b.service.price), 0).toFixed(2);
    return (
        <div className='mt-5 d-flex justify-content-center'>
            {
                total === '0.00' ?
                null 
                :
                <p className='text-center text-success fs-2 border-bottom border-success border-1 w-max'>{total} €</p>
            }
        </div>
    )
}
