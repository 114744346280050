import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppSelector } from '@redux/store/hooks'
import { selectCats } from '@redux/features/gquerySlice/gquerySlice'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { SelCat } from '../create/SelCat'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { Cat } from '@models/services/serviceCat'
import { useAuth } from 'app/modules/auth'
import toast from 'react-hot-toast'
const initialValues = { name: '', iconCat: '' }
const schema = Yup.object().shape({
    name: Yup.string().required('Es necesario un nombre'),
    iconCat: Yup.string().required('Es necesario un icono'),
})

interface IconCat {
    iconCss: string
}

export const CreateCat = () => {
    const [loading, setloading] = useState<boolean>(false)
    const [icons, seticons] = useState<IconCat[]>([]);
    const { centerId } = useAuth()
    const cats = useAppSelector(selectCats);
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            if (cats.some(c => c.name === values.name)) {
                return toast.error('Ya hay una categoría con este nombre')
            }
            setloading(true)
            const catCol = collection(db, 'cat');
            const id = doc(catCol).id;
            const newIndex = retNewCatIndex();
            const cat: Cat = {
                name: values.name,
                iconCat: values.iconCat,
                center: centerId,
                index: newIndex,
                uid: id
            }
            await setDoc(doc(db, `cat/${cat.uid}`), cat)
            toast.success('Categoría creada')
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    const retNewCatIndex = () => {
        let index: number;
        if (cats.length === 0) {
            index = 0;
        } else {
            if (cats.some(el => el.index !== undefined)) {
                const max = Math.max(...cats.filter(el => el.index !== undefined).map((value => value.index as number)));
                index = max + 1;
            } else {
                index = 0;
            }
        }
        return index;
    }
    useEffect(() => {
        let active = true;
        if (active) {
            const getIcons = async () => {
                const col = collection(db, 'iconCat');
                const icons = (await getDocs(col)).docs.map((val) => val.data()) as IconCat[];
                seticons(icons)
            }
            getIcons()
        }
        return () => {
            active = false;
        }

    }, [])
    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <InlineInput name={'name'} label={'Nombre de la categoría'} />
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Selecciona una categoría</label>
                <div className='container'>
                    <div className='d-flex justify-content-center flex-wrap bg-light-aqua rounded-2 pt-3'>
                        {
                            icons.map((cat) => (
                                <div key={cat.iconCss} style={{ width: 200, height: 90 }}>
                                    <SelCat name='iconCat' value={cat.iconCss} nameCat={''} iconCat={cat.iconCss} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={`${JustEnd} mt-3`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
