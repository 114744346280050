import React from 'react'

interface Props {
    children: React.ReactNode,
    isFirst?: boolean,
    classNames?: string
}

export const StepWiz = ({ children, isFirst, classNames }: Props) => {
    return (
        <div 
        className={`${isFirst === true  ? 'current' : ''} justify-content-center `}
        data-kt-stepper-element='content'>
            {children}
        </div>
    )
}
