import React, { useMemo } from 'react'
interface Props {
    text: string,
    type: 'SUCC' | 'WARN' | 'DAN',
    isTable?: boolean
}
export const Badge = ({text, type, isTable}: Props) => {
  const trType = useMemo(() => {
    if (type === 'SUCC') {
        return 'badge-light-success'
    } else if (type === 'WARN') {
        return 'badge-light-warning'
    } else if (type === 'DAN') {
        return 'badge-light-danger'
    }
  }, [type])
  return (
    <span className={`badge 
    ${trType} ${isTable ? 'size-table' : 'fs-8'} fw-bold my-2`}>{text}</span>
  )
}
