import { Form } from '@components/formik/Form'
import { SelectField } from '@components/formik/SelectField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { ResCol } from '@components/ui/container/ResCol'
import { SimpleCard } from '@components/ui/item/SimpleCard'
import { db } from '@lib/firebase'
import useProdsSells from '@lib/hooks/sells/useProdsSells'
import useTicketSells from '@lib/hooks/sells/useTicketSells'
import useWorkersSells from '@lib/hooks/sells/useWorkersSells'
import { Product } from '@models/products/product'
import { selectUser } from '@redux/features/clientSlice/clientSlice'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { doc, increment, updateDoc, writeBatch } from 'firebase/firestore'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { addSellProd } from '@lib/helpers/sells/addSellProd'
import { SellsCounter } from '@models/sells/sellsCounter'
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { UserFinal } from '@models/user/userFinal'
import { commitBatch } from '@lib/helpers/common/commitBatch'
import { nameintPosValid } from '@lib/helpers/Yup/nameintPosValid'
import { FormMeth } from '@components/formik/formMeth/FormMeth'
import { CrossSell } from '@models/sells/crossSell'
import { FormTicket } from '@components/formik/formMeth/FormTicket'
import { Ticket } from '@models/ticket/ticket'
import { PayMeth } from '@models/paymentMetod/payMeth'

const initialValues = {
    workerName: '',
    code: '',
    prodId: '',
    meth: '',
    efQnt: '',
    tarQnt: 0,
    changeMoney: 0
}
const schema = Yup.object().shape({
    prodId: Yup.string().required('Selecciona el producto'),
    workerName: Yup.string().required('Selecciona el emplead@'),
    meth: Yup.string().required('Es necesario un tipo'),
    efQnt: Yup.number().when('meth', {
        is: (meth: string) => meth === 'Efectivo' || meth === 'Mixto',
        then: Yup.number().required('Pon un número'),
        otherwise: Yup.number()
    }).transform(nameintPosValid),
})

export const AddProd = () => {
    const dispatch = useAppDispatch()
    const counter = useAppSelector(selectSellsCounter) as SellsCounter;
    const user = useAppSelector(selectUser);
    const [loading, setloading] = useState<boolean>(false);
    const { makePriceTicket } = useTicketSells();
    const { prods, prodsOpts } = useProdsSells(user?.centerClient as string);
    const { worOpts } = useWorkersSells()
    const [selProd, setselProd] = useState<Product | null>(null)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            const prod = selProd as Product;
            const { meth } = values;
            await productSelect(prod, meth as PayMeth, Number(prod.amount))
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    const processTicket = async (ticket: Ticket) => {
        const prod = selProd as Product;
        const opTicket = makePriceTicket(ticket, Number(prod.amount));
        if (opTicket) {
            const { restAmount, restTicket, ticketId } = opTicket;
            if (restAmount > 0) {
                setselProd((curr) => {
                    const newProd = curr as Product;
                    newProd.amount = restAmount.toFixed(2);
                    return newProd
                })
                toast.success('Selecciona cómo pagar el resto, el ticket ya ha sido gastado')
            } else {
                await productSelect(prod, 'Ticket', 0);
            }
            await updateDoc(doc(db, `tickets/${ticketId}`), {
                amount: restTicket
            })
            dispatch(setCloseModal())
        }
    }
    const { values } = formik

    const productSelect = async (prod: Product, pay: PayMeth, amount: number) => {
        const batch = writeBatch(db);
        const workerName = formik.values.workerName;
        let sell = addSellProd(prod, pay, workerName, amount, user as UserFinal, counter)
        if (pay === 'Mixto') {
            sell = { ...sell, tarQnt: values.tarQnt, efQnt: Number(values.efQnt), cross: true } as CrossSell;
        }
        batch.set(doc(db, `${'sells'}/${sell.docId}`), sell)
        batch.update(doc(db, `products/${prod.productUid}`), {
            stock: increment(-1)
        })
        await commitBatch(batch);
        toast.success('Venta realizada')
        return sell;
    }


    useEffect(() => {
        if (values.prodId !== '') {
            const p = prods.filter(el => el.productUid === values.prodId)[0];
            setselProd(p)
        } else {
            setselProd(null)
        }
    }, [values.prodId, prods])



    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <ResCol
                    childrenFirst={
                        <SelectField name='workerName' opts={worOpts} search={false} placeHolder={'Facturación'} />
                    }
                    childrenSecond={
                        <SelectField name='prodId' opts={prodsOpts} search={true} placeHolder={'Buscar producto...'} />
                    }
                />
                {
                    values.meth === 'Ticket' ?
                        <FormTicket processTicket={processTicket} />
                        :
                        selProd ?
                            <FormMeth total={Number(selProd.amount)} />
                            : null
                }
                {
                    selProd !== null ?
                        <div className='d-flex justify-content-center'>
                            <SimpleCard
                                childIcon={<i className="fa-solid fa-box text-white"
                                    style={{ fontSize: 35 }}></i>}
                                classNames='w-300px mt-5'
                                title={selProd.productName}
                                text1={`Precio ${selProd.amount} € `}
                                text2={`Stock ${selProd.stock.toString()}`} back={'bg-aqua'} />
                        </div>
                        : null
                }
                {
                    values.meth === 'Ticket' ?
                    null:
                    <div className='d-flex justify-content-end mt-5'>
                        <SubmitBtn loading={loading} text='Pagar' />
                    </div>
                }
            </Form>
        </div>
    )
}




