import { BonoForm } from '@components/common/wiz/wiztypes/CreateBonoWiz'
import { InlineInput } from '@components/formik/InlineInput'
import { InlineSelect } from '@components/formik/InlineSelect'
import { Badge } from '@components/ui/badge/Badge'
import { ActionButton } from '@components/ui/button/ActionButton'
import { SimpleCard } from '@components/ui/item/SimpleCard'
import { BoldText } from '@components/ui/text/BoldText'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import { SessionsPack } from '@models/bono/sessionsPack'
import { Services } from '@models/services/services'
import { useFormikContext } from 'formik'
import { toast } from 'react-hot-toast'

export const Norm = () => {
  const { optsServices, services } = useServicesBooking([])
  const { setFieldValue, values } = useFormikContext<BonoForm>();

  const add = () => {
    const form = values;
    const sel = services.filter(s => s.serviceId === form.currService)[0];
    if (form.services.some(el => el.serviceId === sel.serviceId)) {
      toast.error('Servicio ya incluído')
    } else {
      let currServices = [...form.services as Services[], sel];
      setFieldValue('services', currServices)
    }
    setFieldValue('currService', '')
  }
  const delService = (id: string) => {
    const form = values;
    let currSers = form.services as Services[];
    currSers = currSers.filter(el => el.serviceId !== id);
    setFieldValue('services', currSers)
  }
  const deletePack = (index: number) => {
    const form = values;
    let curr = form.sessionsPack as SessionsPack[];
    curr = curr.filter((_, i) => i !== index);
    setFieldValue('sessionsPack', curr)
  }
  const addPack = () => {
    const form = values;
    const newPack: SessionsPack = {
      name: form.name,
      amountPack: form.amountPack,
    }
    newPack.sessionPack = Number(form.sessionPack)
    let curr = [...form.sessionsPack as SessionsPack[], newPack];
    setFieldValue('sessionsPack', curr)
    resetPack()
  }
  const resetPack = () => {
    setFieldValue('name', '')
    setFieldValue('amountPack', '')
    setFieldValue('sessionPack', '')
  }
  const alwaysInPack = values.name !== '' && values.amountPack !== '' && values.sessionPack !== '';

  return (
    <div>
      <InlineSelect name='currService' opts={optsServices} search={true} label={'Elige los servicios'} />
      {
        values.currService !== '' &&
        <ActionButton text={'Añadir servicio'} action={add} classNames='mt-3' />
      }
      <InlineInput name={`name`} label={'Nombra el pack'} />
      <InlineInput name={`amountPack`} label={'Pon precio al pack'} />
      <InlineInput name={`sessionPack`} label={'Pon el número de sesiones al pack'} />
      <div>
        {
          alwaysInPack ?
            <ActionButton text={'Añadir pack'} action={addPack} /> : null
        }
      </div>
      {
        values.services.length > 0 &&
        <BoldText text={'Servicios de todos los packs'} classNames='mt-5 ' />
      }
      <div className='d-flex justify-content-start mb-5'>
        {
          values.services.map((value: Services) => (
            <div key={value.serviceId} className='position-relative fit-own me-3'>
              <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger "
                style={{ zIndex: 3 }} onClick={() => delService(value.serviceId)}></i>

              <Badge text={value.serviceName} type={'SUCC'} />
            </div>
          ))
        }
      </div>
      <div className='d-flex mt-5'>
        {
          values.sessionsPack.map((values: SessionsPack, index) => (
            <div key={index} className='position-relative w-300px me-3'>
              <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger " style={{ zIndex: 3 }} onClick={() => deletePack(index)}></i>
              <SimpleCard
                childIcon={
                  <i className={`${'fa-solid fa-gift'} text-white`}
                    style={{ fontSize: 35 }}></i>
                }
                classNames='w-300px h-auto mt-5'
                title={values.name}
                text1={`Sesiones a gastar: ${values.sessionPack}`}
                text2={`Precio ${values.amountPack} €`}
                back={'bg-info'} />
            </div>
          ))
        }
      </div>
    </div>
  )
}
