import { CenterCol, Col } from '@assets/sass/bootstrapEx';
import FormField from '@components/formik/FormField';
import Switch from '@components/formik/Switch';
import { ActionButton } from '@components/ui/button/ActionButton';
import { Spinner } from '@components/ui/spinner/Spinner';
import { BoldText } from '@components/ui/text/BoldText';
import useCreateUser from '@lib/hooks/user/useCreateUser';
import { useAuth } from 'app/modules/auth';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { retFirstTime } from '@lib/helpers/booking/retFirstTime';

interface Props {
    needsRegister: boolean,
    toggleRegister: () => void,

}
export const UserCalendar = ({ needsRegister, toggleRegister }: Props) => {
    const { centerId } = useAuth()
    const { setFieldValue, isValid, handleSubmit, values } = useFormikContext<any>();
    const client = values.client;
    const { loading, setloading, createFinalUser } = useCreateUser()
    const submitUser = async () => {
        setloading(true);
        const created = await createFinalUser(values.user)
        setloading(false)
        if (created !== '') {
            setFieldValue('client', created)
            toast.success('Usuario creado, puedes continuar')
            const timer = setTimeout(() => {
                handleSubmit()
                toggleRegister()
                clearTimeout(timer)
            }, 100)
        } else {
            Sentry.configureScope(scope => scope.setTransactionName('Error at create user look fun'))
            toast.error('Ha habido algún error contacta con Estetical')
        }
    }
    useEffect(() => {
        setFieldValue('user.center', centerId)
    }, [centerId, setFieldValue])

    useEffect(() => {
        setFieldValue('user.needsUser', needsRegister)
    }, [needsRegister, setFieldValue])

    useEffect(() => {
        const phone = values.user.phone ;
        const name = values.user.displayName as string;
        if (phone !== '' && name !== '') {
            const first = name.substring(0, 1).toUpperCase();
            setFieldValue('user.password', `${first}${phone}`)
        }
    }, [setFieldValue, values.user.phone, values.user.displayName ])

    useEffect(() => {
        let active = true;
        if (active) {
            if (client !== '') {
                const setIsFirstTime = async() => {
                    const propsClient = client.split('-');
                    const resultClient = {
                        uid: propsClient[0],
                        fromEst: propsClient[4]
                    };
                  try {
                      const isFirst = await retFirstTime(centerId, resultClient.uid, resultClient.fromEst)
                      setFieldValue('isFirstTime', isFirst)
                  } catch (error) {
                      Sentry.configureScope(scope => scope.setTransactionName('Error isFirstTime'))
                      Sentry.captureException(error, {user: {id: resultClient.uid}});
                  }
    
              }
              setIsFirstTime()
            }
          
        }
        return () => {
          active = false;
        }
      }, [client, setFieldValue, centerId])
    

    return (
        <div className={`${Col} w-100 ${!needsRegister && 'd-none'} `}>
            <p className='text-center is-pointer text-primary mt-5' onClick={toggleRegister}>Volver a elegir</p>
            <FormField name={'user.displayName'} label={'Pon el nombre'} type={'text'} />
            <FormField type='email' name='user.email' label='Pon email' />
            <div className={`${CenterCol}`}>
                <BoldText classNames='text-center mb-0' text={'¿El email es inventado?'} />
                <Switch nameField={'user.fakeEmail'} holder={''} defvalue={false} />
            </div>
            <FormField name={'user.phone'} label={'Pon el teléfono'} type={'text'} />
            <FormField type='password' name='user.password' label='Contraseña' />
            <div className={`${CenterCol} mb-4`}>
                <BoldText classNames='text-center mb-0' text={'¿Marcar como cliente Estetical?'} />
                <Switch nameField={'user.fromEst'} holder={''} defvalue={false} />
            </div>
            {
                loading === true ? (
                    <Spinner />
                ) : (isValid ?
                    <ActionButton text={'Crear usuario'} action={submitUser} />
                    : null)
            }
        </div>
    )
}
