import { Form } from '@components/formik/Form'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { selectorEditId, setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { Consent } from '@models/user/consent'
import { onSnapshot, doc, updateDoc, deleteDoc } from '@firebase/firestore';
import { db } from '@lib/firebase'
import { InlineInput } from '@components/formik/InlineInput'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { TextArea } from '@components/formik/TextArea'
import toast from 'react-hot-toast'
import { AsyncAction } from '@components/ui/button/AsyncAction'
const initialValues = { title: '' }
const schema = Yup.object().shape({ title: Yup.string().required('Es necesario un título') })
const schemaBody = Yup.object().shape({ body: Yup.string().required('Es necesario un título') })
const bodyInit = { body: '' }

const EditConsent = () => {
    const id = useAppSelector(selectorEditId)
    const dispatch = useAppDispatch();

    const [consent, setconsent] = useState<Consent | null>(null)
    const [loadDel, setloadDel] = useState(false)

    useEffect(() => {
        const sub = onSnapshot(doc(db, `consent/${id}`), (snap) => {
            setconsent(snap.data() as Consent)
        })
        return () => {
            sub()
        }
    }, [id])

    const delConsent = async () => {
        setloadDel(true)
        await deleteDoc(doc(db, `consent/${id}`))
        setloadDel(false)
        dispatch(setCloseModal())
    }

    const changeTitle = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            await updateDoc(doc(db, `consent/${id}`), {
                title: values.title
            })
            setValues({ ...initialValues })
            resetForm()
        },
    })
    const changeBody = useFormik({
        initialValues: bodyInit,
        validationSchema: schemaBody,
        onSubmit: async (values) => {
            await updateDoc(doc(db, `consent/${id}`), {
                body: values.body
            })
            toast.success('Guardado')
        },
    })
    const {setValues} = changeBody

    useEffect(() => {
        if (consent) {
            console.log('hey')
            setValues({ body: consent.body })
        }
    }, [consent, setValues])


    return (
        <div className='p-4'>
            <Form formikVal={changeTitle}>
                <InlineInput name={'title'} label={`Título actual ${consent?.title}`} />
                <div className='mb-5 mb-0'>
                    <SubmitBtn text='Guardar' loading={false} />
                </div>
            </Form  >
            <Form formikVal={changeBody}>
                <TextArea rows={12} name='body' />
                <div className='d-flex justify-content-center mt-4'>
                    {
                        changeBody.values.body !== consent?.body ?
                            <SubmitBtn text='Guardar' loading={false} />
                            : null
                    }
                </div>
            </Form >
            <div className='d-flex justify-content-end mt-5'>   
                <AsyncAction loading={loadDel} action={delConsent} isDanger={true} text={'Borrar'} />
            </div>
        </div>



    )
}

export default EditConsent
