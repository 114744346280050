import { CenterCol, JustEnd, efIcon, tarIcon } from '@assets/sass/bootstrapEx'
import { BoldText } from '@components/ui/text/BoldText'
import { selectTicketPay } from '@redux/features/ticketSlice/ticketSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Form } from '@components/formik/Form'
import { RadioContainer } from '@components/formik/radio/RadioContainer'
import { RadioField } from '@components/formik/radio/RadioField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { doc, getDoc, writeBatch } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
import { makeDatesForSell } from '@lib/helpers/sells/makeDatesForSell'
import { callCloud } from '@lib/helpers/callCloud'
import * as Sentry from '@sentry/react';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { UserFinal } from '@models/user/userFinal'
import { addSellTicket } from '@lib/helpers/sells/addSellTicket'
import { Ticket } from '@models/ticket/ticket'
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { SellsCounter } from '@models/sells/sellsCounter'
import { PayMeth } from '@models/paymentMetod/payMeth'

const initialValues = { method: '' }
const schema = Yup.object().shape({ method: Yup.string().required('Es necesario un código') })

export const CntPayTicket = () => {
    const ticket = useAppSelector(selectTicketPay) as Ticket;
    const counter = useAppSelector(selectSellsCounter) as SellsCounter;
    const [loading, setloading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues }) => {
            setloading(true)
            const user = (await getDoc(doc(db, `users/${ticket?.userEmits}`))).data() as UserFinal;
            const {  dateTime } = makeDatesForSell();
            if (user) {
                try {
                    const batch = writeBatch(db);
                    const sell = addSellTicket(ticket, dateTime, values.method as PayMeth, user, counter)
                    batch.set(doc(db, `sells/${sell.docId}`), sell)
                    batch.update(doc(db, `tickets/${ticket!.uid}`), {
                        paid: true,
                        method: values.method,
                        sellsDate: dateTime,
                    });
                    await batch.commit();
                    toast.success('Ticket pagado');
                } catch (error) {
                    setloading(false)
                    Sentry.configureScope(scope => scope.setTransactionName('Error at paying ticket'))
                    Sentry.captureException(error, {user: {id: ticket?.code}});
                    return toast.error('Error al pagar el ticket')
                }
                try {
                    const ticketPaid = callCloud('ticketPaid');
                    ticketPaid({ ticket: ticket, centerName: ticket?.centerId });
                } catch (error) {
                    Sentry.configureScope(scope => scope.setTransactionName('Error at notify ticket'))
                    Sentry.captureException(error, {user: {id: ticket?.code}});
                }
            } else {
                toast.error('El usuario que hacía el ticket no está registrado')
            }
            setloading(false)
            setValues({ ...initialValues })
            dispatch(setCloseModal())

        },
    })
    return (
        <div className='p-4'>
            <div className={`${CenterCol}`}>
                <BoldText classNames='fs-2' text={`Código ${ticket?.code}`} />
                <BoldText text={`Falta por pagar ${ticket?.amount.toFixed(2)} € `} />
            </div>
            <Form formikVal={formik}>
                <RadioContainer title='Selecciona el tipo'>
                    <RadioField title='Tarjeta de crédito' name='method' value='Tarjeta de crédito'
                        icon={tarIcon}
                    />
                    <RadioField title='Efectivo' name='method' value='Efectivo'
                        icon={efIcon}
                    />
                </RadioContainer>
                <div className={`${JustEnd}`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
