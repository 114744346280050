import { DelOp } from "@components/common/del/DelOp";
import { Acc } from "@components/ui/accordion/Acc";
import { Spinner } from "@components/ui/spinner/Spinner";
import { AddServicesWor } from "@components/workers/editworker/AddServicesWor";
import { ChangeTimesWor } from "@components/workers/editworker/ChangeTimesWor";
import { EditInfo } from "@components/workers/editworker/EditInfo";
import { db } from "@lib/firebase";
import useEditWorker from "@lib/hooks/worker/useEditWorker";
import { Booking } from "@models/bookings/booking";
import { setCloseModal } from "@redux/features/uiSlice/uiSlice";
import { useAppDispatch } from "@redux/store/hooks";
import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import toast from "react-hot-toast";
import * as Sentry from '@sentry/react';

export function EditWorker() {
    const { selWor } = useEditWorker()
    const dispatch = useAppDispatch();
    const delWorker = async () => {
        const actualDate = new Date();
        try {
            dispatch(setCloseModal())
            const bQuery = query(collection(db, 'bookings'), 
            where('centerId', '==', selWor?.centerWorkerName),
            where('workerId', '==', selWor?.name), 
            where('StartTime', '>=', actualDate))
            const books = (await getDocs(bQuery)).docs.map((value) => value.data()) as Booking[];
            if (books.length > 0 && books.some(b => b.cancelled === undefined)) {
                const mess = books.map(value => `${value.serviceName}, ${value.showingDate}, ${value.showingHour}`)
                .join('/ ');
                toast.error(`Sin cancelar: ${mess}`)
            } else {
                await deleteDoc(doc(db, `users/${selWor?.uid}`))
            }
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('Error delWorker'))
            Sentry.captureException(error);
            toast.error('Ha habido algún error')
        }

    }
    return (
        <>
            {
                selWor !== null ?
                    <div className="p-4">
                        <div className="mb-3">
                            <Acc id="Info" title={`Información de ${selWor?.name.split(' ')[0]}`} >
                                <EditInfo wor={selWor} />
                            </Acc>
                        </div>
                        <AddServicesWor isDel={true} wor={selWor} />
                        <AddServicesWor isDel={false} wor={selWor} />
                        <div className="mb-3">
                            <Acc id="Turns" title="Editar horarios por defecto">
                                <ChangeTimesWor wor={selWor} />
                            </Acc>
                        </div>
                        <DelOp del={delWorker} quest={"¿El emplead@ ya no trabaja en el centro?"} text={"Eliminar emplead@"} />
                    </div>
                    :
                    <div className="d-flex justify-content-center">
                        <Spinner />
                    </div>
            }
        </>
    )
}