import { useFormikContext } from 'formik'
interface SubmitProps {
  loading: boolean,
  text?: string,
  classNames?: string
}
export const SubmitBtn = ({ loading, text, classNames }: SubmitProps) => {
  const { isSubmitting, isValid, initialValues, values } = useFormikContext<any>();
  const isFormDirty = JSON.stringify(values) !== JSON.stringify(initialValues);
  return (
    <>
      
      <button
        type='submit'
        className={`btn btn-est ${classNames ? classNames : ''}`}
        disabled={isSubmitting || !isValid || !isFormDirty}
      >
        {!loading && <span className='text-white'>{text ? text : 'Continuar'}</span>}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Espera...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </>
  )
}
