import { BigBook } from '@components/common/wiz/wiztypes/BigBookWizHelper';
import { ActionButton } from '@components/ui/button/ActionButton';
import { useFormikContext } from 'formik';
import React from 'react'
interface Props {
    add: () => string | undefined;
}
export const AddBig = ({add}: Props) => {
  const { values } = useFormikContext<BigBook>();
  if(values.multArr[0] && values.multArr[0].service.doubleWorker === true ) {
    return (
      <div className='fv-plugins-message-container'>
          <span role='alert' className=" text-warning">No puedes añadir más citas a una cita doble</span>
      </div>
  )
  }

  
  if(values.multArr.length >= 1 && values.doubleWorker === true ) {
    return (
      <div className='fv-plugins-message-container'>
          <span role='alert' className=" text-warning">Si ya has cogido citas no puedes añadir una doble</span>
      </div>
  )
  }
  if (values.doubleWorker && values.workerB === '') {
    return (
        <div className='fv-plugins-message-container'>
            <span role='alert' className=" text-warning">Falta un emplead@</span>
        </div>
    )
  }

  if (values.doubleWorker && values.workerB === values.workerName) {
    return (
        <div className='fv-plugins-message-container'>
            <span role='alert' className=" text-warning">No puede ser el mismo emplead@</span>
        </div>
    )
  }


  return (
    <>    
        {   
            values.workerName !== '' ?
            <div className='mt-4'>
              <ActionButton text={'Añadir'} action={add} />
            </div>
            : null
        }
    </>
  )
}
