


import { Field } from 'formik'
import React from 'react'

type Props = {
    nameField: string,
    holder: string,
    defvalue: boolean
}

const Switch: React.FC<Props> = ({ nameField, holder, defvalue }: Props) => {
    return (
        <Field name={nameField}
        >
            {(formikField: any) => {
                return (
                    <div className='d-flex flex-row mt-5 align-items-center me-3'>

                        <label className='form-check-label me-5 fw-bold text-dark'>{holder}</label>

                        <div className='form-check form-check-solid form-switch fv-row'>

                            <input
                                className='form-check-input w-45px h-30px'
                                type='checkbox'
                                defaultChecked={defvalue}
                                onChange={(val) => {
                                    formikField.form.setFieldTouched(nameField, true)
                                    formikField.form.setFieldValue(nameField, val.target.checked)
                                }}
                            />
                        </div>
                    </div>
                )
            }}
        </Field>

    )
}

export default Switch