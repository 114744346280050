import React, { ReactNode } from 'react'
import { FormikProvider } from 'formik'
interface FormProps {
    children?: ReactNode,
    formikVal: any
    classNames?: string
}
export const Form = ({  children, formikVal, classNames }: FormProps) => {
    return (
        <FormikProvider value={formikVal}>
            <form
                className={classNames ? 'form w-100 mt-auto mb-auto' : classNames}
                onSubmit={formikVal.handleSubmit}
                autoComplete='off'
                noValidate
            >
                {children}
            </form>
        </FormikProvider>
    )
}
