import { db } from "@lib/firebase";
import { Ticket } from "@models/ticket/ticket";
import { query, collection, where, getDocs } from "firebase/firestore";

export const getTicketByCode = async (code: string) => {
    const qTicket = query(collection(db, 'tickets'), where('code', '==', code));
    const tickets = await getDocs(qTicket); 
    if (tickets.size > 0) {
        const ticket = tickets.docs.map((value) => value.data())[0] as Ticket
        return ticket
    } else {
        return null
    }
}