import React, { useEffect, useMemo, useRef, useState } from 'react'
import { WizContent } from '../Wiz';
import { useAppDispatch } from '@redux/store/hooks';
import useCreateBooking from '@lib/hooks/calendar/book/useCreateBooking';
import { FastBook, fastBookSchemas, initFastBook } from '../wiztypes/FastBookWizHelper';
import { BigBook, BigBookSchemas, initBigBook } from '../wiztypes/BigBookWizHelper';
import { StepperComponent } from '_metronic/assets/ts/components';
import { Form, Formik, FormikValues } from 'formik';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice';
import { KTSVG } from '_metronic/helpers';
import { Spinner } from '@components/ui/spinner/Spinner';
import { SubmitWiz } from '../SubmitWiz';

interface Props {
    navs: React.ReactNode,
    contentForm: React.ReactNode,
    type: WizContent,
    closeModal?: () => void;
}

export const WizBook = ({ navs, type, contentForm, closeModal }: Props) => {
    const dispatch = useAppDispatch();
    const {createFromFast, createFromBig} = useCreateBooking();
    const [loading, setloading] = useState<boolean>(false);
    const schema: any[] = useMemo(() => {
        if (type === 'FAST_BOOK') {
            return fastBookSchemas
        } else if (type === 'BIG_BOOK') {
            return BigBookSchemas
        }
        return []
    }, [type])
    
    const inits: any = useMemo(() => {
        if (type === 'FAST_BOOK') {
            return initFastBook
        } else if (type === 'BIG_BOOK') {
            return initBigBook
        }
    }, [type])    
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(schema[0])
    const [initValues] = useState<any>(inits)
    const [isSubmitButton, setSubmitButton] = useState(false)
    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()

        setCurrentSchema(schema[stepper.current.currentStepIndex - 1])

        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
    }

    const submitStep = async (values: any, actions: FormikValues) => {
        if (!stepper.current) {
            return
        }
        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            if (type === 'BIG_BOOK' || type === 'FAST_BOOK') {
                const big = values as BigBook
                if (big.client === '') {
                    return;
                }
            }
            stepper.current.goNext()
        } else {
            setloading(true);
            if (type === 'BIG_BOOK') {
                dispatch(setCloseModal())
                const big = values as BigBook;
                await createFromBig(big)
                setloading(false);

            } else if(type === 'FAST_BOOK') {
                if (closeModal) {
                    closeModal()
                }
                const fast = values as FastBook;
                await createFromFast(fast)
                setloading(false);
            }
            stepper.current.goto(1)
            actions.resetForm()
        }

        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)

        setCurrentSchema(schema[stepper.current.currentStepIndex - 1])
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    return (
        <div className='card'>
        <div className='card-body p-0'>
            <div
                ref={stepperRef}
                className='stepper stepper-links d-flex flex-column'
                id='kt_create_account_stepper'
            >
                <div className='stepper-nav mb-5'>
                    {navs}
                </div>

                <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                    {() => (
                        <Form className='mx-auto mw-600px w-100 pb-10' id='kt_create_account_form'>
                            
                            {contentForm}

                            <div className='d-flex flex-stack pt-15'>
                                <div className='mr-2'>
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-lg btn-light-primary me-3'
                                            data-kt-stepper-action='previous'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr063.svg'
                                                className='svg-icon-4 me-1'
                                            />
                                            Volver
                                        </button>

                                </div>
                                <div>
                                    {
                                        loading &&  
                                        <div className='me-3'>
                                            <Spinner /> 
                                        </div>
                                    }
                                    {
                                        !loading && stepper.current !== null &&
                                        <SubmitWiz type={type} currentIndex={stepper.current.currentStepIndex} isSubmitButton={isSubmitButton} />
                                    }
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </div>
  )
}
