import { toMinutes } from '@lib/helpers/dates/toMinutes';
import { Centers } from '@models/centers/center';
import { Machine } from '@models/machines/machine';
import { SellsCounter } from '@models/sells/sellsCounter';
import { Cat } from '@models/services/serviceCat';
import { Services } from '@models/services/services';
import { Worker } from '@models/user/worker';
import { RootState } from '@redux/store/store';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
const cloneDeep = require('clone-deep');
interface QueryState {
    workers: Worker[],
    center: Centers | null,
    services: Services[],
    machines: Machine[],
    cats: Cat[],
    sellsCounter: SellsCounter | null;
    hasRec: boolean
}

const initialState: QueryState = {
    center: null,
    sellsCounter: null,
    workers: [],
    services: [],
    machines: [],
    cats: [],
    hasRec: false
}

export const gquerySlice = createSlice({
  name: 'gquery',
  initialState,
  reducers: {
    setSellsCounter: (state, action: PayloadAction<SellsCounter>) => {
      const payload = action.payload
      state.sellsCounter = payload
    },
    setWorkers: (state, action: PayloadAction<Worker[]>) => {
    const payload = action.payload
    state.workers = payload;
    },
    setCenter: (state, action: PayloadAction<Centers>) => {
    const payload = action.payload
    state.center = payload
    },
    setServices: (state, action: PayloadAction<Services[]>) => {
      const payload = action.payload;
      state.services = payload;
    },
    setMachines: (state, action: PayloadAction<Machine[]>) => {
      const payload = action.payload
      state.machines = payload
    },
    setCat: (state, action: PayloadAction<Cat[]>) => {
      const payload = action.payload
      state.cats = payload;
    },
    setRec: (state, action: PayloadAction<boolean>) => {
      const payload = action.payload;
      state.hasRec = payload;
    },

  },
})
export const selectRec = (state: RootState) => state.gquerySlice.hasRec
export const selectPureWorkers = (state: RootState) => state.gquerySlice.workers;
export const selectServices = (state: RootState) => state.gquerySlice.services;
export const selectMachines = (state: RootState) => state.gquerySlice.machines;
export const selectCenter = (state: RootState) => state.gquerySlice.center
export const selectCats = (state: RootState) => state.gquerySlice.cats
export const selectSellsCounter = (state: RootState) => state.gquerySlice.sellsCounter


export const selectWorCal = createSelector(
  (state: RootState) => state.gquerySlice,
  (gquery) => {
    const cloneWorkers: Worker[] = cloneDeep(gquery.workers)
    return {
        workerCal: cloneWorkers.length > 0 ? 
        cloneWorkers.sort((a, b) => a.indexCal - b.indexCal).filter(w => w.defCal === true)
        :
        []
    }
  }
);
export const selectDisabledDates = createSelector(
    (state: RootState) => state.gquerySlice,
    (gquery) => {
        return {
            centerDisDates: gquery.center ? gquery.center.disabledDates : []
        }
    }
)

export const selectRestCenter = createSelector(
  (state: RootState) => state.gquerySlice,
  (gquery) => {
    return {
      restMinutes: gquery.center ? toMinutes(gquery.center?.rest) : 0
    }
  } 
)
export const {setWorkers, setCenter, setServices, setMachines, setCat, setSellsCounter, setRec} = gquerySlice.actions;
export default gquerySlice.reducer