import { Services } from "@models/services/services";
import { useMemo } from "react";

export default function useServicesForWorker(
    catName: string,
    services: Services[]
) {
    const serOpt = useMemo(() => {
        let s = services;
        if (catName !== '') {
            s = s.filter(se => se.cat === catName)
        } 
        return s.map((value) => ({ label: value.serviceName, value: value.serviceId, id: value.serviceId }));
    }, [catName, services,])

    

    return {
        serOpt,
        services
    }
    

}