import { Field, FieldProps, useFormikContext } from 'formik';
import Select from 'react-select'
import { Option } from '@models/opts/Option'
import { useEffect } from 'react';

interface Props {
    name: string,
    label: string,
    disabled?: boolean,
    clearable?: boolean,
    className?: string,
    search: boolean,
    opts: Option[],
}
export const InlineSelect = ({ label, disabled, name, opts, search, clearable, className  }: Props) => {
    const {setFieldValue, setFieldTouched} = useFormikContext();
    useEffect(() => {
      if (disabled && disabled === true) {
         setFieldValue(name, '')
      }
    }, [disabled, setFieldValue, name])
    return (
        <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>{label}</label>
            <div className='col-lg-8 fv-row'>
                <div className="w-100">
                    <Field name={name}>
                        {({ field, meta, }: FieldProps) => (
                            <>
                                <Select
                                    {...field}
                                    id={name}
                                    name={field.name}
                                    options={opts}
                                    value={field.value === '' ? field.value : opts.find(option => option.value === field.value)}
                                    onBlur={(newValue) => setFieldTouched(name, true)}
                                    onChange={(newValue) => {
                                        if (newValue === null) {
                                            setFieldValue(name, '')
                                        } else {
                                            const opt = newValue as Option
                                            setFieldValue(name, opt.value)
                                        }
                                    }}
                                    isSearchable={search}
                                    isClearable={clearable ? clearable : false}
                                    className={`est-select-container  ${className ? className : ''}`}
                                    classNamePrefix="est-select"
                                    placeholder={'Seleccionar...'}
                                    noOptionsMessage={() => "Ninguna opción disponible"}
                                    isDisabled={disabled ? disabled : false}
                                />
                                {meta.touched && meta.error &&
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-warning'>{meta.error}</span>
                                    </div>
                                }
                            </>
                        )}
                    </Field>
                </div>
            </div>
        </div>
    )
}
