import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { RadioField } from '@components/formik/radio/RadioField'
import { Form } from '@components/formik/Form'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from 'app/modules/auth'
import { InfoBillCard } from './InfoBillCard'
import { endOfDay, endOfMonth, format, parse, startOfDay, startOfMonth } from 'date-fns'
import { useAppDispatch } from '@redux/store/hooks'
import { setDayArr, setMonthPdf } from '@redux/features/billSlice/billSlice'
import es from 'date-fns/locale/es'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { useBillContext } from '@lib/context/bill/BillCxt'
import { MonthPicker } from '@components/formik/MonthPicker';

const initialValues = { multiple: 'uno', year: new Date().getFullYear(), month: '' }

const retShowDate = (d: Date) => {
    const startOfTheDay = startOfDay(d);
    const endOfTheDay = endOfDay(d);
    return `${format(startOfTheDay, 'd/M/yyyy')} - ${format(endOfTheDay, 'd/M/yyyy')}`
}

export const DateBill = () => {
    const [date, setDate] = useState(new Date());
    const dispatch = useAppDispatch();
    const [showDate, setshowDate] = useState(retShowDate(new Date()))
    const { currentUser } = useAuth()
    const { resetState } = useBillContext()
    const handleChange = (d: Date | null) => {
        if (d) {            
            setDate(d)
            setshowDate(retShowDate(d))
        }
    }
    const formik = useFormik({
        initialValues,
        onSubmit: async (values) => {
            const d = `${values.month} ${values.year}`;
            const parsedDate = parse(d, 'MMMM yyyy', new Date(), { locale: es });
            const firstDate = startOfMonth(parsedDate);
            const lastDate = endOfMonth(parsedDate);
            setshowDate(`${format(firstDate, 'd/M/yyyy')} - ${format(lastDate, 'd/M/yyyy')}`)
            dispatch(setMonthPdf(d))
        }
    })

    useEffect(() => {
        resetState()
        dispatch(setDayArr(showDate))
    }, [showDate, dispatch, resetState])

    const show = formik.values.multiple === 'uno' ? showDate.split(' - ')[0] : showDate;

    return (

        <InfoBillCard color={'primary'} text='' title='Selecciona fechas' total={show} children={
            <div style={{minHeight: 100}}>

                <Form formikVal={formik}>
                    {currentUser?.role.includes('worker') ? null :
                        <div className='d-flex justify-content-center mt-5'>
                            <RadioField className='me-4' title={'Por fecha'} name={'multiple'} value={'uno'} />
                            <RadioField className='me-4' title={'Por mes'} name={'multiple'} value={'mas'} />
                        </div>
                    }

                    <div className={`${formik.values.multiple === 'mas' ? '' : 'd-none'}`}>
                        <MonthPicker />
                        {
                            formik.values.month !== '' && formik.values.multiple === 'mas' ?
                                <div className='d-flex justify-content-center'>
                                    <SubmitBtn classNames='mt-5' text='' loading={false} />
                                </div>
                                : null
                        }
                    </div>
                    <div className={`${formik.values.multiple === 'uno' ? '' : 'd-none'} d-flex justify-content-center`}>
                        <DatePicker
                            selected={date}
                            className='form-control form-control-solid w-250px '
                            onChange={handleChange}
                            locale={es}
                            dateFormat='dd/MM/yyyy'
                            portalId="root-portal"
                            dayClassName={(currDate) => (currDate && (currDate.toDateString() === date.toDateString()) ? 'selected_day_picker' : '')}
                        />
                    </div>
                </Form>
            </div>

        } />
    )
}
