import { Field, FieldProps} from 'formik'
import React from 'react'
interface Props {
    name: string,
    rows?: number,
    label?: string
}
export const TextArea = ({ name, rows, label }: Props) => {
    return (
        <>
            {
                label ? 
                <label className='col-lg-4 col-form-label required fw-bold fs-6 mt-3'>{label}</label>
                : null
            }        
            <Field name={name}>
                {({ field,}: FieldProps) => {
                    return (
                        <textarea
                            className='form-control border-1 p-3 pe-10 resize-none min-h-25px'
                            rows={rows ? rows : 4}
                            placeholder='Escribe...'
                            id={name}
                            {...field}
                        />
                    );
                }}
            </Field>
        </>
    )
}

