import { Booking } from "@models/bookings/booking";
import { callCloud } from "../callCloud";
import * as Sentry from '@sentry/react';


export const notify = async (incBook: Booking) => {
  try {
    const adminNotification = callCloud('adminNotification')
    await adminNotification({book: incBook});
  } catch (error) {
    Sentry.configureScope(scope => scope.setTransactionName("Error notify admin"));
    Sentry.captureException(error, {user: {id: incBook.senderUid}});
  }

};
