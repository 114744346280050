interface Props {
    loading: boolean,
    text: string,
    classNames?: string,
    action: () => void;
    isDanger?: true
}
export const AsyncAction = (
    {loading, classNames, action, text, isDanger}: Props
) => {
    return (
        <button
            type='button'
            className={`btn ${isDanger ? 'btn-danger' : 'btn-primary' } ${classNames ? classNames : ''}`}
            onClick={action}
        >
            {!loading && <span className='indicator-label'>{text}</span>}
            {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    Espera...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
        </button>
    )
}
