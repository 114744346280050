import { Col, CenterCol } from '@assets/sass/bootstrapEx';
import { BigBook } from '@components/common/wiz/wiztypes/BigBookWizHelper';
import { Badge } from '@components/ui/badge/Badge';
import { ActionButton } from '@components/ui/button/ActionButton';
import { PhotoItem } from '@components/ui/item/PhotoItem';
import { BoldText } from '@components/ui/text/BoldText';
import { ToolBar } from '@components/ui/toolbar/ToolBar';
import { useFormikContext } from 'formik';
import React from 'react'
interface Props {
    deleteItem?: (index: number) => void,
    move?: (index: number, side: string) => void
    classNames?: string,
    actions: boolean
}
export const MultShow = ({ deleteItem, move, classNames, actions}: Props) => {
    const { values } = useFormikContext<BigBook>();
    return (
        <>

            {
                values.multArr.map((value, index) => (
                    <div key={index} className='position-relative'>

                        <PhotoItem
                            index={index}
                            img={value.service?.img as string}
                            title={value.service?.serviceName as string}
                            subText={value.service?.cat}
                            whatever={
                                <div className={`${Col} ${classNames ? classNames : ''}`}>
                                    <div className='d-flex justify-content-end mt-0'>
                                        {
                                            value.bono || value.ticketUsed === true ?
                                                <Badge text={value.bonoName as string} type='SUCC' />
                                                : <Badge text={`${value.service?.price as string} €`} type='SUCC' />
                                        }
                                    </div>
                                    <BoldText text={value.workerName.replaceAll(',', ', ')} classNames='mb-0' />
                                    {
                                        actions ?
                                            <ToolBar title={'Acciones'} containerClass={'d-block ms-auto mb-0'}>
                                                <div className={`${CenterCol}`}>
                                                    { index !== 0 ?
                                                        <ActionButton classNames='mb-3' text={'Mover arriba'} action={() => {
                                                            if (move) {
                                                                move(index, 'up')
                                                            }
                                                        }} />
                                                        : null
                                                    }
                                                    {
                                                        values.multArr.length - 1 !== index ?
                                                        <ActionButton classNames='mb-3' text={'Mover abajo'} action={() => {
                                                            if (move) {
                                                                move(index, '')
                                                            }
                                                        }} />
                                                        : null
                                                    }
                                                    <ActionButton classNames='mb-3' text={'Eliminar'} action={() => {
                                                        if (deleteItem) {
                                                            deleteItem(index)
                                                        }
                                                    }} />
                                                </div>
                                            </ToolBar>
                                            :
                                            null
                                    }
                                </div>
                            }
                        />
                    </div>
                ))
            }
        </>
    )
}
