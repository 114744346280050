import { ActionButton } from '@components/ui/button/ActionButton'
import { LinkButton } from '@components/ui/button/LinkButton'
import { ToolBarPag } from '@components/ui/toolbar/ToolBarPag'
import { db } from '@lib/firebase'
import { UserFinal } from '@models/user/userFinal'
import { setUser } from '@redux/features/clientSlice/clientSlice'
import { showModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { ModalView } from '@redux/types/ui/modalViews'
import { KTSVG } from '_metronic/helpers'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
interface Props {
  isForBack: boolean
}
export const ClientHead = ({ isForBack }: Props) => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { id } = useParams();
  const [user, setuser] = useState<UserFinal | null>(null)
  useEffect(() => {
    const docUser = doc(db, `users/${id}`)
    const sub = onSnapshot(docUser, (snap) => {
      const userData = snap.data() as UserFinal;
      setuser(userData)
    })
    return () => {
      sub()
    }
  }, [id])
  const editUser = (type: ModalView) => {
    dispatch(showModal({ view: type }))
  }

  useEffect(() => {
    if (user) {
      const us = user as any;
      delete us.birthDate
      dispatch(setUser(us))
    }
  }, [user, dispatch])
  const navUser = () => {
    nav(`/clientes/${id}`)
  }
  const navBack = () => {
    nav(`/clientes`)
  }
  return (
    <div className={`card mb-5 mb-xl-10 ${isForBack ? 'w-max' : ''}`}>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          {
            isForBack === false ?
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  {
                    user?.photo ?

                      <div className='box-photo symbol-label h-lg-160px  w-lg-160px rounded-2' style={{ backgroundImage: `url(${user.photo})` }} />
                      :
                      <span style={{ fontSize: 50 }} className={`symbol-label bg-light-aqua text-aqua fw-bolder`}>
                        {user?.displayName.charAt(0).toLocaleUpperCase()}
                      </span>
                  }
                </div>
              </div>
              : null
          }

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                    {user?.displayName}
                  </p>
                  {
                    user?.fakeEmail === false ?
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      /> : null
                  }
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <p
                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com002.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user?.email}
                  </p>
                  <p
                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com003.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user?.phone}
                  </p>
                </div>
                {
                  isForBack ?
                    <ActionButton text={'Volver'} action={navUser} />
                    :
                    <ActionButton important={true} text={'Editar usuario'} action={() => editUser('EDIT_USER')} />
                }
              </div>

              {
                isForBack ?
                  null
                  :
                  <div>
                    <div className='d-flex flex-wrap mt-1 my-4'>
                      <ToolBarPag title="Acciones">
                        <div className='d-flex mb-4'>
                          <ActionButton classNames='me-2 mt-1' text={'Comentarios'} action={() => editUser('ADD_COM_USER')} />
                          <LinkButton link={`/preguntas/${id}`} icon='' textButt='Cuestionario' classText='me-2 mt-1' />
                        </div>
                        <LinkButton link={`/consentimiento/${id}`} icon='' textButt='Consentimientos' classText='me-2 mt-1 mb-4' />
                        <ActionButton classNames='me-2 mt-2' text={'Volver a clientes'} action={navBack} />
                      </ToolBarPag>
                    </div>
                  </div>
              }

            </div>
          </div>
        </div>
        {
          isForBack ?
            null
            :
            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/clientes/${id}/reservas` && 'active')
                    }
                    to={`/clientes/${id}/reservas`}
                  >
                    Reservas
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/clientes/${id}/reservas-dobles` && 'active')
                    }
                    to={`/clientes/${id}/reservas-dobles`}
                  >
                    Reservas dobles
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/clientes/${id}/productos` && 'active')
                    }
                    to={`/clientes/${id}/productos`}
                  >
                    Productos
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/clientes/${id}/bonos` && 'active')
                    }
                    to={`/clientes/${id}/bonos`}
                  >
                    Bonos
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/clientes/${id}/tickets` && 'active')
                    }
                    to={`/clientes/${id}/tickets`}
                  >
                    Tickets
                  </Link>
                </li>
              </ul>
            </div>
        }

      </div>
    </div>
  )
}
