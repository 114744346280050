import React from 'react'
interface Props {
    childrenFirst: React.ReactNode,
    childrenSecond: React.ReactNode,
    firstCol?: number,
    secondCol?: number
}
export const ResCol = ({childrenFirst, childrenSecond, firstCol}: Props) => {
    return (
        <div className='row mb-6'>

          <div className='row'>
            <div className={`col-lg-${firstCol ? firstCol : 6} fv-row`}>
                {childrenFirst}
            </div>

            <div className='col-lg-6 fv-row'>
                {childrenSecond}
            </div>
          </div>
      </div>
    )
}
