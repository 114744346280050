import { Field, useFormikContext } from 'formik'
import React from 'react'
interface Props {
    name: string,
    value: string,
    title: string,
    classNames?: string
    noCol?: true
}
export const RadioBox = ({name, value, title, classNames, noCol}: Props) => {
  const {setFieldValue, values, initialValues} = useFormikContext<any>();
  const clickev = () => {
    if (values[name] === value) {
      const d = initialValues[name] === null ? null : ''
      setFieldValue(name, d)
    }
  }
  return (
    <div className={noCol === true ? '' : 'col'}>
    <Field
      type='radio'
      className='btn-check'
      name={name}
      value={value}
      id={`kt_${value}`}
      onClick={clickev}
      
    />
    <label
      className={` btn btn-outline btn-outline-dashed btn-outline-default mt-3 ${classNames ? classNames : ''} `}
      htmlFor={`kt_${value}`}
    >
      <span className='fw-bolder fs-3 text-nowrap'>{title}</span>
    </label>
  </div>
  )
}
