export const switchDaysForCalendar = (day: string) => {
    switch (day) {
        case 'Lunes':
          return 'MO';

          case 'Martes':
          return 'TU';

          case 'Miércoles':
          return 'WE';

          case 'Jueves':
          return 'TH';

          case 'Viernes':
          return 'FR';

          case 'Sábado':
          return 'SA';

          case 'Domingo':
          return 'SU';
          default:
            return 'SU'
        } 
}

