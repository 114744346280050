import { Badge } from '@components/ui/badge/Badge'
import { LinkButton } from '@components/ui/button/LinkButton'
import { BoxPhoto } from '@components/ui/photo/BoxPhoto'
import { TDAny } from '@components/ui/table/TDAny'
import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { BoldText } from '@components/ui/text/BoldText'

type Props = {
    dataTable: any[]
}
const TabUsers = ({ dataTable }: Props) => {
    return (
        <>
            { dataTable.length > 0 ?
                dataTable.map((value) => (
                    <TRPar key={`${new Date().getTime()}-${value.uid ? value.uid : value.email}`}>
                        <TDAny>
                            <div className='d-flex justify-content-center align-items-center w-200px flex-nowrap ms-3'>
                                <BoxPhoto colorLetter='text-aqua' back='light-aqua' url={value.photo} name={value.displayName ? value.displayName : 'No tiene nombre'} />
                                {
                                    value.displayName ?
                                        <div className='d-flex flex-column'>
                                            <BoldText classNames='ms-3 w-150px size-table mb-0' text={value.displayName.split(' ')[0]} />
                                            <BoldText classNames='ms-3 w-150px size-table' text={value.displayName.split(' ').filter((_: any, i: number) => i !== 0).join(' ')} />
                                        </div>
                                        :
                                        <BoldText classNames='ms-3 w-150px size-table' text={value.displayName ? value.displayName : 'No tiene nombre'} />
                                }
                            </div>
                        </TDAny>
                        <TDText text={value.email} />
                        <TDText text={value.phone} />
                        <TDAny>
                            <Badge isTable={true} text={value.push ? 'App' : 'No App'} type={value.push ? 'SUCC' : 'DAN'} />
                        </TDAny>
                        <TDAny>
                            <LinkButton link={`/clientes/${value.uuid}`} textButt='Ver cliente' icon='' />
                        </TDAny>
                    </TRPar>
                    
                )) 
                : null
            }

        </>
    )
}

export default TabUsers