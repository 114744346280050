import { InlineInput } from '@components/formik/InlineInput'
import Switch from '@components/formik/Switch'
import { TextArea } from '@components/formik/TextArea'
import { SelImg } from '@components/formik/img/SelImg'
import React from 'react'

export const StepInfoBono = () => {
    return (
        <div className='w-100'>
            <InlineInput name={'nameBonus'} label={'Nombra el bono'} />
            <div className='d-flex justify-content-center mt-4 mb-4'>
                <Switch nameField={'pers'} holder={'¿El bono es personalizado?'} defvalue={false} />
            </div>
            <div className='d-flex justify-content-center mt-4 mb-4'>
                <Switch nameField={'active'} holder={'¿El bono estará activo?'} defvalue={true} />
            </div>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Añade una descripción</label>
            <div className='mb-4'>
                <TextArea name={'description'} rows={4} />
            </div>
            <SelImg name='img' />
        </div>
    )
}
