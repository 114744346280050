import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { counterForm, SellsCounter } from '@models/sells/sellsCounter'
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import { Form } from '@components/formik/Form'
import { ResCol } from '@components/ui/container/ResCol'
import FormField from '@components/formik/FormField'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from "react-hot-toast";
import Switch from '@components/formik/Switch'
import { BoldText } from '@components/ui/text/BoldText'

type Props = {
    counterForm: counterForm,
    isInConfig: boolean,
}
const schema = Yup.object().shape({
    needsYear: Yup.boolean(),
    isInConfig: Yup.boolean(),
    cif: Yup.string().required('Es necesario cif'),
    dir: Yup.string().required('Es necesaria una direción'),
    postal: Yup.string().required('Es necesario un código'),
    busName: Yup.string().required('Es necesario un nombre de empresa'),
    counter: Yup.number().when('isInConfig', {
        is: (isInConfig: boolean) => isInConfig === false,
        then: Yup.number().typeError('Pon un número').required('Es necesario un número de venta'),
        otherwise: Yup.number()
    })

})
export const FormCounter = ({ counterForm, isInConfig }: Props) => {
    const [loading, setloading] = useState<boolean>(false)
    const counter = useAppSelector(selectSellsCounter) as SellsCounter;
    const formik = useFormik({
        initialValues: {
            needsYear: false,
            isInConfig: true,
            cif: '',
            dir: '',
            postal: '',
            counter: '0',
            busName: ''
        },
        validationSchema: schema,
        onSubmit: async (values,) => {
            setloading(true);
            const newCounter = {
                completed: true,
                counter: isInConfig ? counter.counter : Number(values.counter),
                cif: values.cif,
                dir: values.dir,
                postal: values.postal,
                busName: values.busName,
                needsYear: values.needsYear
            }
            await updateDoc(doc(db, `sellsCounter/${counter.docId}`), {
                ...newCounter
            })
            toast.success('Datos guardados')
            setloading(false)
        },
    })
    const { setFieldValue } = formik
    useEffect(() => {
        setFieldValue('cif', counterForm.cif)
        setFieldValue('dir', counterForm.dir)
        setFieldValue('postal', counterForm.postal)
    }, [counterForm, setFieldValue])

    useEffect(() => {
        if (counter && isInConfig) {
            setFieldValue('isInConfig', isInConfig);
            setFieldValue('busName', counter.busName);
            setFieldValue('needsYear', counter.needsYear);
        }
    }, [counter, isInConfig, setFieldValue])
    return (
        <div>
            <Form formikVal={formik}>
                <ResCol childrenFirst={
                    <FormField name={'busName'} label={'Nombre de la empresa'} type={'text'} />
                } childrenSecond={
                    <FormField name={'cif'} label={'Cif de la empresa'} type={'text'} />
                } />
                <ResCol childrenFirst={
                    <FormField name={'dir'} label={'Dirección de la empresa'} type={'text'} />
                } childrenSecond={
                    <FormField name={'postal'} label={'Código postal de la empresa'} type={'text'} />
                } />
                <ResCol childrenFirst={
                    <>
                        {
                            isInConfig ?
                                null
                                :
                                <FormField name={'counter'} label={'Número de venta'} type={'text'} />
                        }
                    </>
                } childrenSecond={
                    <>
                        {
                            isInConfig === false ?
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <BoldText classNames='text-center mb-0' text={'¿Nº de facturación anual?'} />
                                    <Switch nameField={'needsYear'} holder={''} defvalue={false} />
                                </div>
                                : null
                        }
                    </>
                } />
                {
                    isInConfig === true ?
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <BoldText classNames='text-center mb-0' text={'¿Nº de facturación anual?'} />
                            <Switch nameField={'needsYear'} holder={''} defvalue={counter.needsYear} />
                        </div>
                        : null
                }

                <div className={`${JustEnd}`}>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
