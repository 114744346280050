import { db } from "@lib/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import * as Sentry from '@sentry/react';


export const retFirstTime = async (centerId: string, userUid: string, fromEst: string) => {
    try {
        const len = (await getDocs(query(collection(db, 'bookings'), where('centerId', '==', centerId), where('customerUid', '==', userUid)))).size
        if (len === 0 && fromEst === 'true') {
          return true;
        } else {
          return false;
        }  
      } catch (error) {
        Sentry.configureScope(scope => scope.setTransactionName("Error retFirstTime"));
        Sentry.captureException(error, {user: {id: userUid}});
      }
      return false; 
}