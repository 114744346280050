import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { WithChildren } from '../../../../_metronic/helpers'
import { AuthUser } from '@models/user/AuthUser'
import { onAuthStateChanged, signOut, User } from 'firebase/auth'
import { db, fireAuth } from '@lib/firebase'
import { getUserDoc } from '@lib/helpers/user/getUserDoc'
import { switchUserAuth } from '@lib/helpers/user/switchUserAuth'
import toast from 'react-hot-toast'
import { getCenter } from '@lib/helpers/auth/getCenter'
import { doc, onSnapshot } from 'firebase/firestore'
type AuthContextProps = {
  currentUser: AuthUser | undefined,
  canary: boolean,
  centerId: string,
  setCurrentUser: Dispatch<SetStateAction<AuthUser | undefined>>,
  logout: () => void,
  superCenterChange: (centerId: string, canary: boolean) => void,
  adminCenterChange: (centerId: string) => void;

}

const initAuthContextPropsState = {
  currentUser: undefined,
  canary: false,
  centerId: '',
  setCurrentUser: () => { },
  logout: () => { },
  superCenterChange: (centerId: string, canary: boolean) => { },
  adminCenterChange: (centerId: string) => { }
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<AuthUser | undefined>()
  const superCenterChange = useCallback((centerId: string, canary: boolean) => {
    setCurrentUser({ ...currentUser as AuthUser, centerId: centerId, canary: canary });
  }, [currentUser])

  const adminCenterChange = useCallback((centerId: string) => {
    setCurrentUser({ ...currentUser as AuthUser, centerId: centerId });
  }, [currentUser])

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const sub = onSnapshot(doc(db, `users/${currentUser?.uid}`), (snap) => {
      const u = snap.data() as any;
      const userAuth = switchUserAuth(u);
      if (userAuth && userAuth.centerId !== currentUser?.centerId) {
        if (userAuth.role.includes('superAd')) {
          superCenterChange(userAuth.centerId, userAuth.canary)
        } else if (userAuth.role.includes('admin')) {
          adminCenterChange(userAuth.centerId)
        }
      }
    })

    return () => {
      sub()
    }
  }, [currentUser, superCenterChange, adminCenterChange])


  const value = useMemo(
    () => ({
      centerId: currentUser ? currentUser.centerId : '',
      currentUser,
      canary: currentUser ? currentUser.canary : false,
    }), [currentUser]);


  const logout = () => {
    setCurrentUser(undefined);
    signOut(fireAuth);
  }


  return (
    <AuthContext.Provider value={{ currentUser: value.currentUser, centerId: value.centerId, canary: value.canary, setCurrentUser, logout, superCenterChange, adminCenterChange }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, setCurrentUser } = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const out = () => {
    logout();
    setShowSplashScreen(false);
  }
  useEffect(() => {

    const subscriber = onAuthStateChanged(fireAuth, obs => {
      handleAuth(obs)
    });

    const handleAuth = async (user: User | null) => {
      if (user) {
        try {
          const userDoc = await getUserDoc(user.uid);
          if (userDoc) {
            const tryUserAuth = switchUserAuth(userDoc);
            if (tryUserAuth === null) {
              toast.error(`No podemos acceder a tus datos`);
              out()
              return 
            }
            const userAuth = tryUserAuth as AuthUser;
            if (userAuth.role.includes('superAd')) {
              setCurrentUser(userAuth)
              setShowSplashScreen(false);
            } else {
              const center = await getCenter(userAuth.centerId, true);
              const notFinal = !userAuth.role.includes('user');
              if (center && notFinal) {
                setCurrentUser(userAuth)
                setShowSplashScreen(false);
              } else {
                const mess = center ? 'El centro ya no existe' : 'Solo para administradores'
                toast.error(`${mess}`);
                out()
              }
            }
          } else {
            toast.error(`No podemos acceder a tus datos`);
            out()
          }
        } catch (error) {
          toast.error(`Hay algún error`);
          out();
        }
      } else {
        out();
      }
    }


    return () => {
      subscriber();
    }

    // eslint-disable-next-line
  }, [])


  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
