export const timeToString = (time: number) => {
    const hora = time / 60;
    const minuts = time % 60;
    let strTime = '';
    if (hora < 10) {
      strTime = '0' + Math.trunc(hora) + ':';
    } else {
      strTime = Math.trunc(hora) + ':';
    }
    if (minuts === 0) {           strTime += '00';
      } else if (minuts === 5) {  strTime += '05';
      } else if (minuts === 10) {  strTime += '10';
      } else if (minuts === 15) {  strTime += '15';
      } else {                    strTime += minuts; }
    return strTime;
}