import { useAuth } from "app/modules/auth";
import { useCallback } from "react";
import toast from "react-hot-toast";

export default function useCheckRole() {
    const {currentUser} = useAuth()
    const onlyOwners = useCallback(() => {
        if (currentUser) {
            const adminOrSuper = currentUser.role.includes('admin') || currentUser.role.includes('superAd');
            if (adminOrSuper) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [currentUser])
    const noticeWorker = useCallback(() => {
        return toast.error('Acción no permitida para emplead@s');
    }, [])
    return {
        onlyOwners,
        noticeWorker
    }
}