import { Product } from "@models/products/product";
import { makeDatesForSell } from "./makeDatesForSell";
import { db } from "@lib/firebase";
import { PayMeth } from "@models/paymentMetod/payMeth";
import { SimpleSell, Sell } from "@models/sells/sell";
import { format } from "date-fns";
import { collection, doc } from "firebase/firestore";
import { doRandomUid } from "../random/doRandomUid";
import { UserFinal } from "@models/user/userFinal";
import { SellsCounter } from "@models/sells/sellsCounter";
export const addSellProd = (
    prod: Product,
    pay: string,
    workerName: string,
    amount: number,
    user: UserFinal,
    counter: SellsCounter
) => {
    const { dateTime } = makeDatesForSell()
    const col = collection(db, 'sells');
    const docId = doc(col).id;
    const simpleSell: SimpleSell = {
        cross: (pay as PayMeth) === 'Mixto',
        name: prod.productName,
        amount: amount,
        workerName: workerName,
        type: 'PRODS',
        orderDate: format(new Date(), 'dd-MM-yyyy HH:mm'),
        parentId: docId,
        paymentMethod: pay as PayMeth,
        userName: user!.displayName,
        userUid: user!.uid,
        uidFind: doRandomUid(user!.centerClient),
        uidInCol: prod.productUid
    }
    const sell: Sell = {
        uidInCol: prod.productUid,
        docId: docId,
        dateTime: dateTime,
        centerId: user!.centerClient,
        cross: (pay as PayMeth) === 'Mixto',
        userUid: user!.uid,
        userName: user!.displayName,
        amount: amount,
        paymentMethod: pay as PayMeth,
        allTypes: ['PRODS'],
        sells: [simpleSell],
        counter: 0,
        cif: counter.cif,
        busName: counter.busName,
        dir: counter.dir,
        postal: counter.postal,
        email: user.email,
        isAdmin: true
    }
    return sell;
}