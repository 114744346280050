import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form';
import { Questions } from '@models/user/questions';
import { TextArea } from '@components/formik/TextArea';
import { SubmitBtn } from '@components/formik/SubmitBtn';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { Messages } from '@models/user/messages';
interface Props {
    quest: Questions
}

const initialValues = { ans: '' }
const schema = Yup.object().shape({ ans: Yup.string().required('Es necesaria una respuesta') })

export const DoAns = ({quest}: Props) => {
    const { id } = useParams();
    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            const m: Messages = {
                matter: quest.quest,
                text: values.ans,
                creation: new Date().getTime(),
                docId: ''
            } 
            const ref = await addDoc(collection(db, `users/${id}/messages`), m)
            await updateDoc(doc(db, `users/${id}/messages/${ref.id}`), {
                docId: ref.id
            })
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    return (
        <div>
            <Form formikVal={formik}>
                <TextArea name={'ans'} rows={8} />
                <div className='d-flex justify-content-center mt-5'>
                    <SubmitBtn loading={loading} />
                </div>
            </Form>
        </div>
    )
}
