import { db } from "@lib/firebase";
import { Option } from "@models/opts/Option";
import { Product } from "@models/products/product";
import { getDocs, query, collection, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

export default function useProdsSells(centerId: string) {
    const [prods, setprods] = useState<Product[]>([])
    const [prodsOpts, setprodsOpts] = useState<Option[]>([])

    const fetchProds = useCallback(
        async () => {
          const t = (await getDocs(query(collection(db, 'products'), 
          where('centerId', '==', centerId)))).docs.map((value) => { 
            const data = value.data();
            data.productUid = value.id
            return data }) as Product[];
            setprods(t)
        },
        [centerId],
      )

      useEffect(() => {
        let active = true;
        if (active === true) {
            fetchProds()
        }
        return () => {
          active = false;
        }
      }, [fetchProds])

      useEffect(() => {
        if (prods.length > 0) {
            const o = prods.filter(p => p.stock > 0).map((value) => ({label: value.productName, value: value.productUid}))
            setprodsOpts(o)
        }
      }, [prods])
      

    return {
        prods,
        prodsOpts
    }

}