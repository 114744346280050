import { TimeV2 } from "@models/bookings/timeV2";

export const checkAftOrMor = (morns: any, afts: any, time: TimeV2) => {
    if(time.str >= morns.intStart && time.end <= morns.intEnd) {
        return {def: morns, via: true};
      } else if (time.str >= afts.intStart && time.end <= afts.intEnd) {
        return {def: afts, via: true};
      } else {
        return {def: morns, via: false}
      } 
}