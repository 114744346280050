import React from 'react'
interface Props {
    childIcon?: React.ReactNode
    title: string
    text1?: string
    text2?: string
    text3?: string
    back: string, 
    classNames?: string

}
export const SimpleCard = ({ title,  childIcon, text1, text2, text3, back, classNames }: Props) => {
    return (
        <div className={`card h-100 ${back} ${classNames ? classNames : ''}`}>
            <div className='card-body d-flex justify-content-start flex-column p-8'>
                <div className='d-flex flex-column'>
                    <div className='symbol symbol-75px mb-6'>
                        {childIcon}
                    </div>
                </div>
                <p className={`fs-5 ${back !== 'bg-primary-light' ? 'text-white' : 'text-black'} fw-bolder text-start mb-2`}>{title}</p>
                <p className={`fs-7 fw-bold ${back !== 'bg-primary-light' ? 'text-white' : 'text-black'} text-start`}>{text1}</p>
                <p className={`fs-7 fw-bold ${back !== 'bg-primary-light' ? 'text-white' : 'text-black'} text-start`}>{text2}</p>
                <p className={`fs-7 fw-bold ${back !== 'bg-primary-light' ? 'text-white' : 'text-black'} text-start`}>{text3}</p>

            </div>
        </div>
    )
}
