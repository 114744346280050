// import { Wiz } from '@components/common/wiz/Wiz';
import { StepWiz } from '@components/common/wiz/StepWiz';
import { PickUser } from './PickUser';
import { NavWiz } from '@components/common/wiz/NavWiz';
import { BigBooking } from './BigBooking';
import { PickDate } from './PickDate';
import { WizBook } from '@components/common/wiz/wizbook/WizBook';

export const BigBookContainer = () => {

  return (
    <WizBook 
      type='BIG_BOOK'
      contentForm={
        <>
          <StepWiz isFirst={true}>
              <PickUser />
          </StepWiz>
          <StepWiz>
              <BigBooking />
          </StepWiz>
          <StepWiz>
              <PickDate />
          </StepWiz>
        </>
      }
      navs={
        <>
          <NavWiz isFirst={true} title='Elige usuario' />
          <NavWiz  title='Servicios y bonos' />       
          <NavWiz  title='Selecciona una fecha' />   
        </>
      }
    />
  )
}
