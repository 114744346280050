import { TimeV2 } from "@models/bookings/timeV2";
import { FastBook, initFastBook, userRules } from "./FastBookWizHelper";
import * as Yup from 'yup'

const initBigBook: BigBook = {
    ...initFastBook,
    time: null,
    workerB: '',
    doubleWorker: false,
    sameAnyWorker: false,
    wantsHour: false,
    dispoHour: '',
}


export interface BigBook extends FastBook {
    doubleWorker: boolean,
    workerB: string,
    sameAnyWorker: boolean,
    time: TimeV2 | null,
    wantsHour: boolean,
    dispoHour: string,
}

const BigBookSchemas = [
    Yup.object({
      client: Yup.string().when('user.needsUser', {
        is: (needsUser: boolean) => needsUser === false,
        then: Yup.string().required('Selecciona un cliente para continuar'),
        otherwise: Yup.string()
      }),
      ...userRules
    }),
    Yup.object().shape({
      doubleWorker: Yup.boolean(),
      // multArr: Yup.array().min(1, 'No has reservado').required('No has reservado')
    }),
    Yup.object().shape({
      // time: Yup.mixed().required('Selecciona una hora y fecha')
    })
  ]


export {initBigBook, BigBookSchemas}
