import { SessionsPack, serviceLimit } from '@models/bono/sessionsPack'
import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitField } from '@components/formik/SubmitField'
import { Bonus } from '@models/bono/bonus'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
import { Badge } from '@components/ui/badge/Badge'
import { BoldText } from '@components/ui/text/BoldText'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import { InlineSelect } from '@components/formik/InlineSelect'

const schema = Yup.object().shape({
    name: Yup.string(),
    amountPack: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).positive('Número positivo').typeError('Tiene que ser un número'),
    sessionPack: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        .positive('Número positivo')
        .typeError('Tiene que ser un número')
        .integer('El número debe ser entero'),
    limitPack: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        .positive('Número positivo')
        .typeError('Tiene que ser un número')
        .integer('El número debe ser entero'),
    servicesLimit: Yup.array(),
})
interface Props {
    pack: SessionsPack,
    pers: boolean,
    back: () => void;
    bono: Bonus,
    index: number
}
export const EditPack = ({ pack, pers, bono, index }: Props) => {
    const { optsServices, services } = useServicesBooking([])

    const formik = useFormik({
        initialValues: {
            name: pack.name,
            amountPack: `${pack.amountPack}`,
            sessionPack: pers ? '' : `${pack!.sessionPack}`,
            limitPack: '',
            servicesLimit: pers ? pack!.servicesLimit : [],
            currService: ''
        },
        validationSchema: schema,
        onSubmit: async () => { },
    })

    const changeName = async () => {
        const f = formik.values;
        const packs = bono.sessionsPack;
        packs[index].name = f.name;
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            sessionsPack: packs
        })
        toast.success('Cambiado')

    }


    const changePrice = async () => {
        const f = formik.values;
        const packs = bono.sessionsPack;
        packs[index].amountPack = f.amountPack;
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            sessionsPack: packs
        })
        toast.success('Cambiado')

    }
    const changeSes = async () => {
        const f = formik.values;
        const packs = bono.sessionsPack;
        packs[index].sessionPack = Number(f.sessionPack);
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            sessionsPack: packs
        })
        toast.success('Cambiado')

    }

    const delServicesLimit = async (x: number) => {
        if (pack.servicesLimit?.length === 1) {
            return toast.error('Tiene que quedar al menos un servicio')
        }
        const s = pack;
        const packs = bono.sessionsPack;
        s.servicesLimit = (s.servicesLimit as serviceLimit[]).filter((_, i) => x !== i);
        packs[index] = s;
        await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
            sessionsPack: packs
        })
        toast.success('Cambiado')
    }

    const addServicesLimit = async () => {
        const form = formik.values;
        const sel = services.filter(s => s.serviceId === form.currService)[0];
        if ((pack.servicesLimit as serviceLimit[]).some(el => el.id === sel.serviceId)) {
            toast.error('Servicio ya incluído')
        } else {
            const newLimit = {
                sesNumber: Number(form.limitPack),
                name: sel.serviceName,
                id: sel.serviceId
            }
            const s = pack;
            const packs = bono.sessionsPack;
            s.servicesLimit = [...(pack.servicesLimit as serviceLimit[]), newLimit]
            packs[index] = s;
            const a = {id: newLimit.id, name: newLimit.name}
            await updateDoc(doc(db, `bonus/${bono.bonusId}`), {
                sessionsPack: packs,
                services: arrayUnion(a)
            })
            toast.success('Añadido')
            formik.setFieldValue('currService', '');
            formik.setFieldValue('limitPack', '')
        }
    }



    return (
        <Form formikVal={formik}>
            <InlineInput name={`name`} label={`Cambia el nombre de ${pack.name}`} />
            <div className={`${JustEnd} mt-3 mb-3`}>
                <SubmitField text='Cambiar' name={'name'} loading={false} handleSubmit={changeName} />
            </div>
            <InlineInput name={`amountPack`} label={`Cambia el precio`} />
            <div className={`${JustEnd} mt-3 mb-3`}>
                <SubmitField text='Cambiar' name={'amountPack'} loading={false} handleSubmit={changePrice} />
            </div>
            {
                pers === false ?
                    <div>
                        <InlineInput name={`sessionPack`} label={`Cambia las sesiones`} />
                        <div className={`${JustEnd} mt-3 mb-3`}>
                            <SubmitField text='Cambiar' name={'sessionPack'} loading={false} handleSubmit={changeSes} />
                        </div>
                    </div>
                    : null
            }
            {
                pers === true ?
                    <div>
                        <BoldText text={'¿Quieres eliminar algún servicio?'} classNames='text-center' />
                        <div className='d-flex justify-content-start'>
                            {
                                (pack.servicesLimit as serviceLimit[]).map((value: serviceLimit, index) => (
                                    <div key={value.id} className='position-relative fit-own me-3'>
                                        <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger "
                                            style={{ zIndex: 3 }} onClick={() => delServicesLimit(index)}></i>
                                        <Badge text={`${value.name}(${value.sesNumber})`} type={'SUCC'} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    : null
            }
            {
                pers === true ?
                    <div>
                        <BoldText text={'¿Quieres añadir algún servicio?'} classNames='text-center mt-5 mb-5' />
                        <InlineSelect name='currService' opts={optsServices} search={true} label={'Elige los servicios'} />
                        <InlineInput name={`limitPack`} label={`Pon las sesiones`} />
                        <div className={`${JustEnd} mt-3 mb-3`}>
                            {
                                formik.values.currService !== '' && formik.values.limitPack &&
                                <SubmitField text={'Añadir servicio'} name='limitPack' loading={false} handleSubmit={addServicesLimit} classNames='mt-3' />
                            }
                        </div>
                    </div>
                    : null
            }
        </Form>
    )
}
