import { db } from "@lib/firebase"
import { SellsBonus } from "@models/bono/sellsBonus"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"

export const sellsBonusCases = async (sellDocId: string, uidFind: string) => {
    const colSellsBonus = collection(db, 'sellsBonus')
    const queryIsUidFind = (await getDocs(query(colSellsBonus, where('uidFind', '==', uidFind))))
    const queryIsInFrac = (await getDocs(query(colSellsBonus, where('fracDocId', 'array-contains', sellDocId))));
    const checkId = (await (getDoc(doc(db, `sellsBonus/${sellDocId}`)))).data();
    if (checkId) {
        return {bo: checkId as SellsBonus, frac: false}
    } else if (queryIsInFrac.size > 0) {
        const boFrac = queryIsInFrac.docs.map((v) => v.data())[0]
        return {bo: boFrac as SellsBonus, frac: true}
    } else if (queryIsUidFind.size > 0) {
        const bonoFind = queryIsUidFind.docs.map((v) => v.data())[0]
        return {bo: bonoFind as SellsBonus, frac: false}
    } else {
        return {bo: null, frac: false}
    }
}