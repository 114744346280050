import { selectorEditId } from '@redux/features/uiSlice/uiSlice'
import { useAppSelector } from '@redux/store/hooks'
import React from 'react'

export const ReadCons = () => {
    const text = useAppSelector(selectorEditId)
    return (
        <div className='p-5'>
            <pre
                style={{ fontSize: 22, textAlign: 'justify', whiteSpace: 'pre-line', lineHeight: 2, fontFamily: 'Inter, Helvetica, "sans-serif"' }}>
                {text}
            </pre>
        </div>
    )
}
