import { Form } from '@components/formik/Form'
import { SelectField } from '@components/formik/SelectField'
import { db, fireAuth } from '@lib/firebase'
import { Centers } from '@models/centers/center'
import { useAuth } from 'app/modules/auth'
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";
import { ActionButton } from '@components/ui/button/ActionButton'
import { signOut } from 'firebase/auth'
const initialValues = {
  centerName: '',
}
const schema = Yup.object().shape({
  centerName: Yup.string().required()

});
export const ChoiceSuperAd = () => {
  const nav = useNavigate()
  const [centers, setcenters] = useState<Centers[]>([])
  const {currentUser} = useAuth();
  const opts = useMemo(() => {
    if (centers.length > 0) {
      const opts = centers.map((value) => ({label: value.displayName, value: value.centerName}))
      return opts;
    } else {
      return []
    }
  }, [centers])
  useEffect(() => {
    async function getCenters() {
      const allCenters = (await getDocs(collection(db, 'centers'))).docs.map((value) => value.data()) as Centers[];
      setcenters(allCenters);
    }
    getCenters()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      const centerSel = centers.filter(el => el.centerName === values.centerName)[0];
      await updateDoc(doc(db, `users/${currentUser?.uid}`), {
        centerWorkerName: values.centerName,
        canary: centerSel.canary
      })
      nav('/dashboard')
    },
  })

  const signOutEst = async () => {
    await signOut(fireAuth)
  }

  return (
    <Form formikVal={formik}>
        <SelectField search={true} opts={opts} name={'centerName'} placeHolder={'Selecciona un centro'} />
        <div className='mt-3 d-flex justify-content-between mt-10'>
          <ActionButton text={'Salir de la aplicación'} action={signOutEst} />
          <ActionButton disabled={formik.values.centerName === ''} important={true} action={formik.handleSubmit} text={'Continuar'} />
        </div>
    </Form>
  )
}

