

import { memo, useState } from 'react'
import { Spinner } from '@components/ui/spinner/Spinner'
import { ActionButton } from '@components/ui/button/ActionButton'
import {cloneDeep} from 'lodash'
import { useAppSelector } from '@redux/store/hooks'
import {  selectMonthPdf, selectType } from '@redux/features/billSlice/billSlice'
import useBill from '@lib/hooks/bill/useBill'
import { callCloud } from '@lib/helpers/callCloud'
import { parse } from 'date-fns'
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'
interface Props {
  putUrl: (url: any) => void
}
export const SendPdfBills = memo(({putUrl}: Props) => {
  const t = useAppSelector(selectType);
  const center = useAppSelector(selectCenter);
  const [loading, setloading] = useState(false);
  const month = useAppSelector(selectMonthPdf);
  const { totsCol, clean } = useBill(t)
  const createPdf = async () => {
    setloading(true);
    const ivaImport = center?.canary === true ? 1.07 : 1.21;
    const infoMonth = month.split(' ');
    const totalNoIva = Math.abs(totsCol.total / ivaImport);
    const iva = totsCol.total - totalNoIva;
    const order = cloneDeep(clean)
    .sort((a, b) => 
    parse(a.orderDate, 'dd-MM-yyyy HH:mm', new Date())
    .getTime() - parse(b.orderDate, 'dd-MM-yyyy HH:mm', new Date()).getTime()).map((v) => ({...v, amount: v.amount.toFixed(2)}));
    const data = {
      month: infoMonth[0],
      year: infoMonth[1],
      total: totsCol.total.toFixed(2),
      iva: iva.toFixed(2),
      totalNoIva: totalNoIva.toFixed(2),
      totalTar: totsCol.tar.toFixed(2),
      totalEf: totsCol.ef.toFixed(2),
      centerLogo: center?.centerLogo,
      centerId: center?.centerName,
      prods: order.filter(el => el.type === 'PRODS'),
      ticks: order.filter(el => el.type === 'TICKS'),
      bonus: order.filter(el => el.type === 'BONUS'),
      books: order.filter(el => el.type === 'BOOKS'),
      ivaName: center?.canary === true ? '7% IGIC' : '21% IVA' 
    }
    const sendTotalsPdf = callCloud('sendTotalsPdf');
    const url = (await sendTotalsPdf({pdfData: data})).data as string;
    putUrl(url);
    setloading(false)

  }

  return (
    <div className='d-flex justify-content-end'>
    {
      loading ? 
    <div className='me-5'>
      <Spinner /> 
    </div>
    :
    <ActionButton  classNames='p-4 m-4' text={'Crear pdf'} important={true} action={() => { 
        createPdf()
    }} />
    }
</div>
  )
})
