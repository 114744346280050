import { SellsBonus } from '@models/bono/sellsBonus'
import {UserFinal} from '@models/user/userFinal'
import {RootState} from '@redux/store/store'
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'

interface ClientState {
  user: UserFinal | null
  changeBono: SellsBonus | null
  bonoToChange: string
  payLeft: number
}

const initialState: ClientState = {
  user: null,
  changeBono: null,
  bonoToChange: '',
  payLeft: 0,
}

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserFinal>) => {
      const payload = action.payload
      state.user = payload
    },
    setChangeBono: (state, action: PayloadAction<SellsBonus>) => {
      const payload = action.payload
      state.changeBono = payload
    },
    setPayLeft: (state, action: PayloadAction<{num: number; bonoId: string}>) => {
      const payload = action.payload
      state.bonoToChange = payload.bonoId;
      state.payLeft = payload.num
    },
  },
})

export const selectUser = (state: RootState) => state.clientSlice.user
export const selectBonoCh = (state: RootState) => state.clientSlice.changeBono
export const selectPayLeft = createSelector(
  (state: RootState) => state.clientSlice,
  (clientSlice) => {
    return {
      leftToPay: clientSlice.payLeft,
      bonoId: clientSlice.bonoToChange
    }
  }
)

export const selectUid = createSelector(
  (state: RootState) => state.clientSlice,
  (client) => {
    return {
      uid: client.user ? client.user.uid : '',
    }
  }
)

export const {setUser, setPayLeft, setChangeBono} = clientSlice.actions

export default clientSlice.reducer
