export const hoursAday = () => {
    const times = [];
    let start = 8;
    let init = 0;
    while (start < 22) {
      while (init < 60) {
        const min = init < 10 ? '0' + init : '' + init
        let time = start + ':' + min;
        if (start < 10) { time = '0' + time};
        times.push(time);
        init += 5
      }
      start = start + 1;
      init = 0;
    }
    times.push('22:00');
    return times;
  }