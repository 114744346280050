import { selectorSpinner } from '@redux/features/uiSlice/uiSlice';
import { useAppSelector } from '@redux/store/hooks';
import { Spinner } from './Spinner';
export const GlobalSpinner = () => {
  const display = useAppSelector(selectorSpinner);
  return (
    <div
      className={`modal ${display ? 'd-block' : 'd-none'}`}
      id="global_spinner"
      data-keyboard="false"
      data-backdrop="static"
      tabIndex={-1} role="dialog"
      aria-labelledby="staticBackdrop"
      aria-hidden="true"
      style={{ backgroundColor: '#00000070' }}
    >
      <div className="modal-dialog modal-dialog-centered mw-900px" role="document">
        <Spinner />
      </div>
    </div>

  )
}
