import { RootState } from '@redux/store/store'
import { ModalView } from '@redux/types/ui/modalViews'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalPayload {
  view: ModalView,
  editId?: string
}

interface ImpaidPay {
  view: ModalView,
  warn: boolean
}

interface UiState {
  displaySpinner: boolean
  displayModal: boolean,
  modalView: ModalView,
  editId: string
  impaidWarning: boolean,

}

const initialState: UiState = {
  displaySpinner: false,
  displayModal: false,
  modalView: '',
  editId: '',
  impaidWarning: true
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showSpinner: (state, action: PayloadAction<boolean>) => {
        const payload = action.payload
        state.displaySpinner = payload;
    },
    showModal: (state, action: PayloadAction<ModalPayload>) => {
      const payload = action.payload;
      state.displayModal = true;
      state.modalView = payload.view
      if (payload.editId !== undefined) {
        state.editId = payload.editId;
      }
    },
    setCloseModal: state => {
      state.displayModal = false;
      state.modalView = '';
    },
    setMinModal: (state) => {
      state.displayModal = false;
    },
    setImpaidWarn: (state, action: PayloadAction<ImpaidPay>) => {
      const payload = action.payload
      state.impaidWarning = payload.warn;
      state.modalView = payload.view;
      state.displayModal = payload.view === 'DATE_DUE' ? true : false
    },
  }
})
export const selectorWorUid = (state: RootState) => state.uiSlice.editId;
export const selectView = (state: RootState) => state.uiSlice.modalView;
export const selectorEditId = (state: RootState) => state.uiSlice.editId;
export const selectorSpinner = (state: RootState) => state.uiSlice.displaySpinner;
export const selectorWarn = (state: RootState) => state.uiSlice.displaySpinner;

export const selectorModalView = createSelector(
  (state: RootState) => state.uiSlice,
  (state) => {
    return {
      display: state.displayModal,
      view: state.modalView
    }
  }
);
export const { showModal, setCloseModal, showSpinner, setMinModal, setImpaidWarn  } = uiSlice.actions;
export default uiSlice.reducer