import { useFormikContext } from 'formik';
import React, { useState } from 'react'
interface Props {
    name: string,
    label: string,
    type?: string
}
export const InlineInput = ({ name, label, type }: Props) => {
    const { getFieldProps, getFieldMeta } = useFormikContext<any>();
    const meta = getFieldMeta(name)
    const [typeValue, settypeValue] = useState<any | undefined>(type)
    return (
        <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>{label}</label>
            <div className='col-lg-8 fv-row'>
                <div className="input-group">
                    <input
                        autoComplete='off'
                        type={typeValue !== undefined ? typeValue : 'text'}
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder={label}
                        {...getFieldProps(name)}
                    />
                    {
                        type === 'password' &&
                        <span className="input-group-text" onClick={() => settypeValue(typeValue === 'password' ? 'text' : 'password')}>
                            <i className={`${typeValue === 'password' ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'} text-primary fs-2x`}></i>
                        </span>
                    }
                </div>
                {meta.touched && meta.error &&
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className=" text-warning">{meta.error}</span>
                    </div>
                }
            </div>
        </div>
    )
}
