import { AsyncAction } from '@components/ui/button/AsyncAction'
import { BoldText } from '@components/ui/text/BoldText'
import { deleteSellsBonus } from '@lib/helpers/bonos/deleteSellsBonus'
import { selectBonoCh } from '@redux/features/clientSlice/clientSlice'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { useState } from 'react'
import toast from 'react-hot-toast'

export const DeleteSellBonus = () => {
    const dispatch = useAppDispatch()
    const changeBono = useAppSelector(selectBonoCh)
    const [isloading, setisloading] = useState(false)
    const deleteItem = async () => {
        setisloading(true);
        if (changeBono) {
            const isDel = await deleteSellsBonus(changeBono)
            if (isDel) {
              toast.success('Eliminado')
            } else {
              toast.error('Error al eliminar el bono')
            }
            dispatch(setCloseModal())
        }
        setisloading(false);
    }
    return (
        <div className='p-4'>
            {
                changeBono ? 
                <>
                    <BoldText text={"Eliminar la venta no cambiará el número del contador"} />
                    {
                        changeBono.paymentMethod === 'Mixto' ? 
                        <BoldText classNames='text-danger text-center' text='Bono pagado con método mixto, estás eliminando todos los items de la venta*' />
                        : null
                    }
                    <AsyncAction classNames="d-block mx-auto" isDanger={true} loading={isloading} text={"Eliminar venta"} action={deleteItem} />
                </>
                : null
            }
        </div>
    )
}
