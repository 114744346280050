import { Acc } from '@components/ui/accordion/Acc'
import { selectorEditId } from '@redux/features/uiSlice/uiSlice'
import { useAppSelector } from '@redux/store/hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { callCloud } from '@lib/helpers/callCloud'
import toast from 'react-hot-toast'
import { AsyncAction } from '@components/ui/button/AsyncAction'
import { BoldText } from '@components/ui/text/BoldText'

const schema = Yup.object().shape({ email: Yup.string().required('Es necesario un email').email() })
const initialValues = { email: '' }
export const SellActions = () => {
  const [receiptUrl, setreceiptUrl] = useState('')
  const id = useAppSelector(selectorEditId)
  const [loading, setloading] = useState<boolean>(false)
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setValues, resetForm }) => {
      setloading(true)
      const callReceiptSell = callCloud('callReceiptSell');
      await callReceiptSell({ docId: id, email: values.email })
      setloading(false)
      toast.success('Has recibido el recibo siempre y cuando el correo sea correcto, revisa el correo y la bandeja de spam')
      setValues({ ...initialValues })
    },
  })
  const download = async () => {
    setloading(true)
    const downloadSellRec = callCloud('downloadSellRec');
    const url = (await downloadSellRec({ docId: id })).data as string;
    if (url !== 'err') {
      setreceiptUrl(url)
    } else {
      toast.error('Error al descargar')
    }
    setloading(false)
  }
  return (
    <div className='p-4'>
      <Acc title={'Enviar por correo'} classNames='mb-4' id={'SendRec'}>
        <Form formikVal={formik}>
          <FormField type='email' name='email' label='Pon email' />
          <div className={`${JustEnd}`}>
            <SubmitBtn loading={loading} />
          </div>
        </Form>
      </Acc>
      <Acc title={'Descargar'} id={'DownloadRec'}>
        {
          receiptUrl !== '' ?
            <a href={receiptUrl} className='mt-5 mb-5'>
              <BoldText classNames='text-center text-primary fs-2' text={`Pulsa para descargar el recibo`} />
            </a>
            : null
        }
          <AsyncAction loading={loading} text={'Descarga'} action={download} classNames='d-block mx-auto' />
      </Acc>

    </div>
  )
}
