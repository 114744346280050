import { Ticket } from "@models/ticket/ticket"
import { RootState } from "@redux/store/store"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface TicketState {
    currCodes: string[],
    ticketEnd: TicketEnd,
    ticketPay: Ticket | null
}

export type TicketEnd = {
    error: boolean,
    code: string,
    amount: number
}

const initialState: TicketState = {
    currCodes: [],
    ticketEnd: {code: '', error: false, amount: 0 },
    ticketPay: null
}

export const ticketSlice = createSlice({
    name: 'tick',
    initialState,
    reducers: {
        setCurrCodes: (state, action: PayloadAction<string[]>) => {
            const payload = action.payload
            state.currCodes = payload;
        },
        setTicketEnd: (state, action: PayloadAction<TicketEnd>) => {
            const payload = action.payload
            state.ticketEnd = {...payload}
        },
        setTicketPay: (state, action: PayloadAction<Ticket>) => {
            const payload = action.payload
            state.ticketPay = payload;
        },
    }
})
export const selectCodes = (state: RootState) => state.ticketSlice.currCodes
export const selectTicketEnd = (state: RootState) => state.ticketSlice.ticketEnd
export const selectTicketPay = (state: RootState) => state.ticketSlice.ticketPay

export const {
    setCurrCodes,
    setTicketEnd,
    setTicketPay,
    
} = ticketSlice.actions

export default ticketSlice.reducer