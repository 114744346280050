import React from 'react'
interface Props {
  img: string,
  title: string,
  index: any
  whatever: React.ReactNode,
  subText?: string,
  deleteFun?: (index: any) => void;
}
export const PhotoItem = ({ img, title, subText, whatever, index, deleteFun }: Props) => {
  return (
    <div className='d-flex align-items-sm-center flex-lg-row flex-column mb-7 position-relative'>
      {
        deleteFun ?
        <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger" onClick={() => deleteFun(index)}></i>
        : null
      }

      <div className= 'symbol symbol-lg-60px symbol-2by3 me-4'>
        <div
          className='symbol-label'
          style={{ backgroundImage: `url(${img})` }}
        ></div>
      </div>
      <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
        <div className='flex-grow-1 me-2 w-50'>
          <p className='text-gray-800  fs-6 mb-0'>
            {title}
          </p>
          <span className='text-muted fw-semibold d-block pt-1'>{subText ? subText : ''}</span>
        </div>
        {whatever}
      </div>
    </div>
  )
}
