import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { db } from '@lib/firebase'
import { retShowing } from '@lib/helpers/dates/retShowing'
import { SellsBonus } from '@models/bono/sellsBonus'
import { Ticket } from '@models/ticket/ticket'
import { selectUser } from '@redux/features/clientSlice/clientSlice'
import { selectServices } from '@redux/features/gquerySlice/gquerySlice'
import { setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { collection, doc, getDoc, getDocs, query, where, writeBatch } from 'firebase/firestore'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import * as Yup from 'yup'
import * as Sentry from '@sentry/react';
import { ServicePair } from '@models/machines/servicePair'
import { Sell } from '@models/sells/sell'

const initialValues = {
    code: ''
}
const schema = Yup.object().shape({
    code: Yup.string().required('Es necesario un código')
})
export const AsignTicket = () => {
    const user = useAppSelector(selectUser);
    const services = useAppSelector(selectServices);
    const [loading, setloading] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, {resetForm, setValues}) => {
            setloading(true)
            try {
                const batch = writeBatch(db)
                const code = values.code;
                const upTicket = await retTicket(code)
                if (upTicket) {
                    if (upTicket.paid && upTicket.userReceives === '') {
                        const sellTicket = await retSellsTicket(code);
                        if (sellTicket === undefined) {
                            toast.error('Has borrado el ticket de facturación, no se puede asociar');
                            setloading(false)
                            resetForm();
                            setValues({code: ''})
                            return;
                        }
                        const workerName = sellTicket.sells[0].workerName;
                        const date = (sellTicket.dateTime as any).toDate();
                        if (upTicket.bono) {
                            if (!verifyServiceExists(upTicket.servicesInc.map((v) => v.id))){
                                toast.error('El servicio del ticket no existe');
                            } else {
                                const stringBonus = retShowing(date)
                                const selecteds = upTicket.servicesInc;
                                const onSersArr = services.filter(el => selecteds.some((a) => a.id === el.serviceId))
                                if (onSersArr.every(el => el.numberSessions !== undefined)) {
                                    const sum = onSersArr.reduce((a, b) => +a + +b.numberSessions, 0);
                                    const sellsBonus = retSpecialBonus(upTicket.centerId, upTicket.code, upTicket.servicesInc, user?.uid as string,
                                        (upTicket.amount as number).toFixed(2), sum, date, stringBonus, user?.email as string, upTicket.method as string, workerName, user?.displayName as string, 'ticket');
                                    batch.set(doc(db, `${'sellsBonus'}/${upTicket.code}`), sellsBonus)
                                    batch.update(doc(db, `tickets/${upTicket.uid}`), {
                                        userReceives: user?.uid,
                                        userEmReceives: user?.email,
                                        uid: upTicket.uid
                                    })
                                    toast.success('Ticket asignado, entra en la facturación como bono');
                                    await batch.commit()
                                } else {
                                    const nameNotFound = onSersArr.filter(el => el.numberSessions === undefined)[0].serviceName;
                                    toast.error(`No encontramos el número de sesiones de ${nameNotFound}`);
                                    Sentry.captureMessage(`Error assign number sessions ${nameNotFound}`)
                                }
                            }
                        } else {
                            batch.update(doc(db, `tickets/${upTicket.uid}`), {
                                userReceives: user?.uid,
                                userEmReceives: user?.email,
                                uid: upTicket.uid
                            });
                            await batch.commit();
                            toast.success('Ticket asignado');
                        }
                    } else {
                        if (upTicket.userReceives !== '') { toast.error('Ya se ha asignado este código') }
                        else { toast.error('No se puede asignar un ticket hasta que no está pagado') };
                    }
                } else {
                    toast.error('Ningún ticket con este código')
                }
            } catch (error) {
                toast.error('Error al asociar el ticket')
                Sentry.configureScope(scope => scope.setTransactionName('Error asign ticket'))
                Sentry.captureException(error, {user: {id: user?.uid}});
            }
            setloading(false)
            dispatch(setCloseModal())
        },
    })
    const retSellsTicket = async (code: string) => {
        const sell = (await getDoc(doc(db, `sells/${code}`))).data() as Sell
        return sell
    }
    const retTicket = async (code: string) => {
        const t = (await getDocs(query(collection(db, 'tickets'), where('code', '==', code)))).docs.map((value) => { 
            const data = value.data();
            data.uid = value.id
            return data })[0] as Ticket
        return t;
    }
    const verifyServiceExists =(sersTicket: string[]) => {
        if (services.some(el => sersTicket.includes(el.serviceId))) {
          return true;
        } else {
          return false;
        }
      }
      const retSpecialBonus = (centerUid: string, code: string, bonusServices: ServicePair[], user: string, bonusAmount: string, 
        numberSes: number, dateBono: Date, dateString: string, mail: string, pay: string, opt: string, customerName: string, origin: string) => {
        const sell:SellsBonus = { 
          id: code,
          paymentMethod: pay,
          centerId: centerUid,
          name: code,
          services: bonusServices,
          userUid: user,
          amount: bonusAmount,
          numberSessions: numberSes,
          dateTime: dateBono,
          dateShow: dateString,
          userEmail: mail,
          optFac: opt,
          customerName: customerName,
          simplePay: true,
          firstPayment: true,
          originBono: origin,
          hasTicket: true,
          fracDocId: [],
          uidFind: ''
        }
        return sell;
      }
    return (
        <div className='p-4'>

            <Form formikVal={formik}>
                <FormField name={'code'} label={'Introduce el código'} type={'text'} />
                <div className='d-flex justify-content-center mt-5'>
                    <SubmitBtn loading={loading} text='Asignar' />
                </div>
            </Form>
        </div>
    )
}

