import { WriteBatch } from "firebase/firestore";

export const commitBatch = async (batch: WriteBatch) => {
    try {
        await batch.commit();
        return true;        
    } catch (error) {
        // Sentry
        return false; 
    }
}