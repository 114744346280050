import { fireAuth } from '@lib/firebase'
import { AuthUser } from '@models/user/AuthUser'
import { showModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { KTSVG } from '_metronic/helpers'
import { useAuth } from 'app/modules/auth'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

export const Out = () => {
    const nav = useNavigate();
    const {currentUser} = useAuth();
    const dispatch = useAppDispatch();
    const decideOut = async () => {
        if (currentUser?.role.includes('superAd')) {
            nav('/escoge-centro')
        } else if (currentUser?.role.includes('admin')) {
            if (currentUser.centers.length > 1) {
                dispatch(showModal({view: 'CHANGE_CENTER', editId: (currentUser as AuthUser).centers.join(',')}))
            } else {
                await signOut(fireAuth)
            }
        } else {
            await signOut(fireAuth)
        }
    }
    return (
        <div className='menu-item' onClick={decideOut}>
            <div className='menu-link without-sub'>
                <span className='menu-icon'>
                    {' '}
                    <KTSVG path={'/media/icons/duotune/arrows/arr001.svg'} className='svg-icon-2' />
                </span>
                 <span className='menu-title'>Salir</span>
            </div>
        </div>
    )
}
