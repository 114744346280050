import { BoldText } from '@components/ui/text/BoldText'
import { useFormikContext } from 'formik'
import React from 'react'
interface Props {
    name: string,
    value: string,
    holder: string,
    classNames?: string

}
export const RadioArray = ({name, value, holder, classNames}: Props) => {
  const {values, setFieldValue} = useFormikContext<any>()
  return (
    <label className={`form-check form-check-custom form-check-solid align-items-start rounded-2 p-2
    ${classNames ? classNames : ''}`} >
    <input
      className='form-check-input me-3'
      type='checkbox'
      name={name}
      checked={values[name].includes(value) ? true : false}
      onChange={() =>{
        if (values[name].includes(value)) {
            const newArr = values[name].filter((el: string) => el !== value);
            setFieldValue(name, newArr)
        } else {
            const p = values[name];
            setFieldValue(name, p.concat(value))
        }
    }
      }
    />

    <span className='d-flex flex-column align-items-start'>
        <BoldText text={holder} />
      {/* <span className='fw-bolder fs-5 mb-0'>{holder}</span> */}
    </span>
  </label>
  )
}
