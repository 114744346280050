import { UpMenu } from '@components/bills/compbills/UpMenu'
import { ShowBill } from '@components/bills/compbills/ShowBill';
import { BillContext } from '@lib/context/bill/BillCxt';
import { QueryBills } from '@components/bills/compbills/QueryBills';
import { ModalSells } from '@components/bills/modal/ModalSells';

const SellsPage = () => {
    return (
        <BillContext>
            <QueryBills />
            <UpMenu />
            <ShowBill />
            <ModalSells />
        </BillContext>
    )
}

export default SellsPage
