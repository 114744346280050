import React from 'react'
interface Props {
    children: React.ReactNode,
    classNames?: string,
}
export const TDAny = ({children, classNames, }: Props) => {
  return (
    <td className={`${classNames ? classNames : ''} w-25px `}>
          {children}
    </td>
  )
}
