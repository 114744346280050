import React from 'react'
interface Props {
    isFirst?: boolean,
    content: React.ReactNode,
    id: string 
}
export const ContainerSec = ({id, content, isFirst}: Props) => {
  return (
    <div  role="tabpanel" className={`tab-pane fade ${isFirst ? 'active show' : ''}`} id={`kt_${id}`}>
        {content}
    </div>
  )
}
