import { CenterRow, Row } from '@assets/sass/bootstrapEx';
import { BigBook } from '@components/common/wiz/wiztypes/BigBookWizHelper';
import { SelectField } from '@components/formik/SelectField';
import Switch from '@components/formik/Switch';
import useBonosBooking from '@lib/hooks/bonos/useBonosBooking';
import { AddBig } from '@components/calendar/bigbook/AddBig';
import useCommonBooking from '@lib/hooks/calendar/book/useCommonBooking';
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking';
import useWorkersBooking from '@lib/hooks/calendar/book/useWorkersBooking';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import { MultShow } from './MultShow';
import { TotalMult } from './TotalMult';

export const BigBooking = () => {
  const { values, setFieldValue } = useFormikContext<BigBook>();
  const [canbesame, setcanbesame] = useState<boolean>(false)
  const [onlyServices, setonlyServices] = useState<string[]>([])
  const { optsServices, services } = useServicesBooking(onlyServices)
  const { bonoUser, bonosAsOps, ticketsUser, setbonoUser, setticketsUser } = useBonosBooking(values.client.split('-')[0], []);
  const { workers, worOpts } = useWorkersBooking(values.currSerId, services, values.doubleWorker);
  const { add, deleteItem, move } = useCommonBooking("BIG_BOOK", services, setFieldValue, values, setonlyServices, bonoUser, ticketsUser, setticketsUser, setbonoUser, workers);
  useEffect(() => {
    if (values.currSerId !== '') {
      const sel = services.filter(s => s.serviceId === values.currSerId)[0];
      if (sel.doubleWorker) {
        setFieldValue('doubleWorker', true)
      } else {
        setFieldValue('doubleWorker', false)
      }
    }
  }, [values.currSerId, services, setFieldValue])

  useEffect(() => {
      if (values.multArr.length > 1 && values.multArr.every(el => el.workerId === 'Cualquier emplead@')) {
        setcanbesame(true);
      } else {
        setcanbesame(false)
      }
  }, [values.multArr])

  return (
    // lacks moving position arrays
    <div className='w-100'>
      { canbesame ?
        <div className='d-flex justify-content-center mb-4'>
          <Switch nameField={'sameAnyWorker'} holder={'Mismo emplead@'} defvalue={false} />
        </div>
        : null
      }
      {
        bonosAsOps.length > 0 && values.doubleWorker === false ?
          <div className='mb-4'>
            <div className={`${Row} align-items-center justify-content-center`}>
              <Switch nameField={'currBono'} holder={'Bono'} defvalue={false} />
            </div>
            {
              values.currBono === true ?
                <SelectField search={true} opts={bonosAsOps} name={'currBonoId'} placeHolder={''} />
                : null
            }
          </div>
          : null
      }
      <div className='mt-4 mb-4'>
        <SelectField search={true} opts={optsServices} name={'currSerId'} placeHolder={'Servicios'} />
      </div>

      <div className='mt-4 mb-4'>
        <SelectField search={true} disabled={values.currSerId === ''} opts={worOpts} name={'workerName'} placeHolder={'Emplead@'} />
      </div>
      {
        values.doubleWorker ?
          <SelectField search={true} opts={worOpts} name={'workerB'} placeHolder={'Segundo emplead@'} />
          : null
      }
      
      <div className={`${CenterRow}`}>
        <AddBig add={add} />
      </div>
      <TotalMult multArr={values.multArr} />
      <div className='mt-5'>
          <MultShow actions={true} deleteItem={deleteItem} move={move} />
      </div>
    </div>
  )
}
