
import { InlineInput } from '@components/formik/InlineInput';
import { BoldText } from '@components/ui/text/BoldText';
import { KTCard } from '_metronic/helpers'
import React from 'react'
import { WorkerCrAv } from './WorkerCrAv';

export const WorkerPutInfo = React.memo(() => {
    return (
        <KTCard className='mb-10 p-4'>
            <BoldText classNames='fs-2' text='Información del emplead@' />
            <InlineInput name={'workerName'} label={'Nombre completo'} />
            <InlineInput name={'workerEmail'} label={'Email'} />
            <InlineInput name={'password'} label={'Contraseña'} type='password' />
            <WorkerCrAv title='Selecciona un avatar' />
        </KTCard>
    )
})
