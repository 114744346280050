import React, { useState } from 'react'
import { Datepicker, localeEs } from '@mobiscroll/react'
import { getMonday } from '@lib/helpers/dates/getMonday'
import { BoldText } from '@components/ui/text/BoldText'
import { ActionButton } from '@components/ui/button/ActionButton'
import { CreateAuto } from './CreateAuto'
import { CopyAuto } from './CopyAuto'
import { useAutoContext } from '@lib/context/autoWorker/AutoWorCxt'
import { format } from 'date-fns'
import { toast } from 'react-hot-toast'
export type RangeDate = {
    valueText: string,
    datesArr: Date[],
}
interface Props {
    backShow: () => void;
}
type StateCreate = '' | 'COPY' | 'CREATE'
const mon = getMonday()
export const ContainerCreateAuto = ({ backShow }: Props) => {
    const {autos} = useAutoContext()

    const [state, setstate] = useState<StateCreate>('')
    const [range, setrange] = useState<RangeDate>({
        valueText: '',
        datesArr: []
    })
    const back = () => {
        setstate('');
    }
    return (
        <div>
            {
                state === '' ?
                    <>
                        {
                            range.valueText === '' ?
                                <BoldText classNames='ms-3' text='Selecciona manualmente de Lunes a Domingo: ' />
                                :
                                <BoldText classNames='ms-3' text={`Días seleccionados ${range.valueText}`} />
                        }
                        <Datepicker
                            controls={['calendar']}
                            display="inline"
                            touchUi={true}
                            locale={localeEs}
                            theme='ios'
                            min={mon}
                            maxRange={7}
                            minRange={7}
                            select="preset-range" firstSelectDay={1}
                            showRangeLabels={false}
                            onChange={(props) => {
                                if ((props as any).valueText.includes('-'))
                                    if (autos.some((auto) => auto.firstDate === format((props as any).value[0], 'd/M/yyyy'))) {
                                        return toast.error('Ya hay horarios para esta semana, elimínalos o modificalos')
                                    }
                                    setrange({ valueText: (props as any).valueText, datesArr: (props as any).value })
                            }}
                            defaultSelection={null}
                        />
                        {
                            range.datesArr.length > 0 &&
                            <div className='d-flex flex-column align-items-end mt-4'>
                                <div className='d-flex flex-row mb-4'>
                                    <BoldText text='¿Quieres copiar los horarios?' />
                                    <p onClick={() => setstate('COPY')} className='text-primary border-bottom border-primary is-pointer ms-2'> Copiar horarios</p>
                                </div>
                            </div>
                        }
                        <div className='d-flex justify-content-between mt-10'>
                            <ActionButton action={backShow} text='Volver' />
                            <ActionButton disabled={range.datesArr.length === 0} important={true} text={'Continuar'} action={() => setstate('CREATE')} />
                        </div>
                    </>
                    :
                    (
                        state === 'COPY'
                            ?
                            <CopyAuto back={back} range={range} />
                            : <CreateAuto back={back} range={range} />
                    )
            }
        </div>
    )
}
