import { useState } from 'react'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import FormField from '@components/formik/FormField'
import { sendPasswordResetEmail } from 'firebase/auth'
import { fireAuth } from '@lib/firebase'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'
interface ForgotProps {
  toggleReset: () => void;
}
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Esto no es un email válido...')
    .required('Es necesario un email'),
})

export function ForgotPassword({ toggleReset }: ForgotProps) {
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      try {
        await sendPasswordResetEmail(fireAuth, values.email);
        toast.success('Mira en tu email');
        Cookies.set('RESET_PASS', values.email, { expires: 7 })
      } catch (error) {
        // Sentry
        toast.error('Error al enviar el link, puede que lo hayas intentado demasiadas veces')
      }
      setLoading(false)
      resetForm({ values: { ...initialValues }, errors: {}, touched: {} })
    },
  })


  return (
    <FormikProvider value={formik}>
      <form
        className='form w-100 mt-auto mb-auto'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Reestablecer contraseña</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Introduce tu email
          </div>
          {/* end::Link */}
        </div>


        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <FormField
            type='email'
            name='email'
            label='Email'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={toggleReset}
          >
            Volver
          </button>
          <SubmitBtn loading={loading} />
        </div>
        {/* end::Form group */}
      </form>
    </FormikProvider>

  )
}
