import React from 'react'
interface Props {
    action?: () => void
    iconClass: string,
    containerClass?: string
    bgColor?: string
}
export const IconButton = ({ action, iconClass, containerClass, bgColor}: Props) => {
    return (
        <div className={`${containerClass ? containerClass : ''} me-4`} onClick={() => {
            if (action) {
                action()
            }
        }}>
            <div className='symbol symbol-50px is-pointer'>
                <span className={`symbol-label ${bgColor ? bgColor : 'bg-primary'}`}>
                    <i className={`${iconClass} text-white fs-2x`}></i>
                </span> 
            </div>
        </div>
    )
}
