import { ModalShow } from '@components/ui/modal/ModalShow'
import { closeSellsModal, selectEditBill, selectModalSells } from '@redux/features/billSlice/billSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import React from 'react'
import { EditBill } from '../day/EditBill'
import { DelBill } from '../day/DelBill'

export const ModalSells = () => {
  const dispatch = useAppDispatch()
  const { actions } = useAppSelector(selectEditBill)
  const isOpen = useAppSelector(selectModalSells);
  const title = actions === 'AMOUNT' || actions === 'METHOD' ? 'Editar' : 'Eliminar';
  const close = () => {
    dispatch(closeSellsModal())
  }
  return (

    <ModalShow maxW='mw-900px' id={'SellsModal'} show={isOpen} >
      <div className="modal-header bg-aqua" style={{ padding: 10, paddingLeft: 25 }}>
          <h5 className="modal-title text-white">{title}</h5>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
              <i className={`fa-solid fa-x text-white fs-2`}></i>
            </div>
      </div>
      {
        actions === 'AMOUNT' || actions === 'METHOD' ?
          <EditBill />
          :
          <DelBill />
      }
    </ModalShow>
  )
}
