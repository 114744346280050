import { Services } from "@models/services/services";
import { selectPureWorkers } from "@redux/features/gquerySlice/gquerySlice";
import { useAppSelector } from "@redux/store/hooks";
import { useMemo } from "react";


export default function useWorkersBooking(selectedService: string, services: Services[], doubleWorker: boolean) {
    const workers = useAppSelector(selectPureWorkers);
    const worOpts = useMemo(() => {
        if (selectedService === '') {
            return []
        }
        const ser = services.filter(el => el.serviceId === selectedService)[0];
        let workersByService = [{label: 'Cualquier emplead@', value: 'Cualquier emplead@'}]
        const arrOpts = workers.filter(w => w.services.some(el => el.id === ser.serviceId))
        .map((value) => ({label: value.name, value: value.name}));
        workersByService = workersByService.concat(arrOpts);
        if (doubleWorker) {
            workersByService = workersByService.filter(w => w.value !== 'Cualquier emplead@');
        }
        return workersByService

    }, [selectedService, services, workers, doubleWorker])
    return {
        worOpts,
        workers
    }

}