import React from 'react'
interface Props {
    children: React.ReactNode,
    className?: string
}
export const RadioBoxContainer = ({children, className}: Props) => {
  return (
    <div className='row mb-2' data-kt-buttons='true'>
        {
            children
        }
    </div>
  )
}
