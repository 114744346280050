import { Rec } from "@lib/hooks/calendar/book/useTurnsWorkers"

export const isInRec = (d: number, recs: Rec[]) => {
    const daysInRec = ['SU','MO','TU','WE', 'TH', 'FR', 'SA', ]
    const sel = daysInRec[d];
    if (recs.some(el => el.recurring.weekDays === sel)) {
        return true
    } else {
        return false
    }
}