import React, { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { TextArea } from '@components/formik/TextArea'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import toast from 'react-hot-toast'
import { retShowing } from '@lib/helpers/dates/retShowing'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { ActionButton } from '@components/ui/button/ActionButton'
import { Messages } from '@models/user/messages'
import { db } from '@lib/firebase'
type Props = {
    className: string,
    mess: Messages
    id: string
    // photo?: string,
    // title: string,
    // subTitle?: string,
    // text: string,
}

const initialValues = { newAns: '' }
const schema = Yup.object().shape({ newAns: Yup.string().required('Es necesaria una respuesta') })

export const NorCard = ({ className, mess, id }: Props) => {
    const [loading, setloading] = useState<boolean>(false)
    const [isEdit, setisEdit] = useState<boolean>(false);

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            await updateDoc(doc(db, `users/${id}/messages/${mess.docId}`), {
                text: values.newAns
            })
            toast.success(`Respuesta cambiada`)
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
        },
    })
    const deleteAns = async () => {
        await deleteDoc(doc(db, `users/${id}/messages/${mess.docId}`))
        toast.success('Pregunta eliminada')
    }
    const created = useMemo(() => {
        return retShowing(new Date(mess.creation))
    }, [mess.creation])
    return (
        <div className={`card ${className}`}>
            <div className='card-body pb-0'>
                <div className='d-flex align-items-center mb-5'>
                    <div className='d-flex align-items-center flex-grow-1'>
                        {/* <div className='symbol symbol-45px me-5'>
                            {
                                photo ?
                                    <div className='box-photo symbol-label h-lg-160px  w-lg-160px rounded-2' style={{ backgroundImage: `url(${photo})` }} />
                                    : null
                            }
                        </div> */}
                        <div className='d-flex flex-column'>
                            <p className='text-gray-800  fs-6 fw-bold'>
                                {mess.matter}
                            </p>
                            <span className='text-gray-400 fw-semibold'>{created}</span>
                            {/* {
                                subTitle ?
                                    : null
                            } */}
                        </div>
                    </div>
                    {/* <div className='my-0'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
                </div>
                <div className='mb-5'>
                    <p className='text-gray-800 fw-normal mb-5'>
                        {mess.text}
                    </p>
                    {/* <div className='d-flex align-items-center mb-5'>
          <a
            href='#'
            className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
          >
            <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
            120
          </a>

          <a
            href='#'
            className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
          >
            <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
            15
          </a>
        </div> */}
                </div>

                {/* begin::Separator */}
                <div className='separator mb-4'></div>
                {/* end::Separator */}

                {/* begin::Reply input */}

                {
                    !isEdit ?
                        <ActionButton classNames='mb-5' text='Editar' action={() => { setisEdit(true) }} />
                        :
                        <Form formikVal={formik}>
                            <ActionButton text='Volver' classNames='d-block mb-5' action={() => { setisEdit(false) }} />
                            <TextArea label='Edita...' name={'newAns'} />
                            <div className='d-flex justify-content-between mt-5 mb-5'>
                                <SubmitBtn loading={loading} />
                                <ActionButton isDanger={true} text='Eliminar' action={deleteAns} />
                            </div>
                        </Form>
                }


                {/* <form className='position-relative mb-6'>
                    <textarea
                        className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                        rows={1}
                        placeholder='Reply..'
                    ></textarea> */}

                    {/* <div className='position-absolute top-0 end-0 me-n5'>
          <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
            <KTSVG
              path='/media/icons/duotune/communication/com008.svg'
              className='svg-icon-3 mb-3'
            />
          </span>

          <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
            <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-2 mb-3' />
          </span>
        </div> */}
                {/* </form> */}
                {/* edit::Reply input */}
            </div>
            {/* end::Body */}
        </div>

    )
}
