import { Booking } from "@models/bookings/booking";

export const chooseSerName = (book: Booking) => {
      /*
        look book types 
    */
    let serviceName = '';
    let template = 0;
    const multNames: string[] = [];
    if (book.multiSer) {
      book.multCounter.forEach(el => multNames.push(el.serviceName));
      serviceName = multNames.join(', ');
      template = 3;
    } else if (book.doubleWorker) {
      serviceName = book.serviceName
      template = 2;
    } else {
      serviceName = book.serviceName
      template = 1;
    }
    return {serviceName: serviceName, template: template}
}