import { Services } from '@models/services/services'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Acc } from '@components/ui/accordion/Acc'
import { Form } from '@components/formik/Form'
import { InlineInput } from '@components/formik/InlineInput'
import useHoursSer from '@lib/hooks/services/useHoursSer'
import { InlineSelect } from '@components/formik/InlineSelect'
import { SubmitField } from '@components/formik/SubmitField'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
interface Props {
    ser: Services
}
const schema = Yup.object().shape({
    price: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon un precio').test('more-than-zero', 'El precio debe ser igual a cero o superior', function (value: any) {
        return Number(value) === 0 || Number (value) > 0
    }).typeError('Tiene que ser un número'),
    numberSessions: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon las sesiones').positive('Número positivo').typeError('Tiene que ser un número').integer('Sin decimales'),
    duration: Yup.string().required('Pon una duración'),
})

export const InfoSer = ({ ser }: Props) => {
    const { optsDispo } = useHoursSer()
    const formik = useFormik({
        initialValues: { price: ser.price, duration: ser.duration, numberSessions: ser.numberSessions, },
        validationSchema: schema,
        onSubmit: async () => { },
    })
    const changeses = async () => {
        await updateDoc(doc(db, `services/${ser.serviceId}`), {
            numberSessions: Number(formik.values.numberSessions)
        })
        toast.success('Cambiado')
    }
    const changePrice = async () => {
        await updateDoc(doc(db, `services/${ser.serviceId}`), {
            price: formik.values.price
        })
        toast.success('Cambiado')
    }
    const changeDur = async () => {
        await updateDoc(doc(db, `services/${ser.serviceId}`), {
            duration: formik.values.duration
        })
        toast.success('Cambiado')
    }
    return (
        <Acc title={`Editar ${ser.serviceName}`} id={'info'} classNames='mb-3 mt-3'>
            <Form formikVal={formik}>
                <InlineInput name='numberSessions' label={'Pon las sesiones que gasta en un bono'} />
                <div className='d-flex justify-content-end mb-5'>
                    <SubmitField loading={false} handleSubmit={changeses} name={'numberSessions'} text='Cambiar' />
                </div>
                <InlineInput name='price' label={'Pon el precio'} />
                <div className='d-flex justify-content-end mb-5'>
                    <SubmitField loading={false} handleSubmit={changePrice} name={'price'} text='Cambiar' />
                </div>
                <InlineSelect search={true} opts={optsDispo} name={'duration'} label={'Selecciona duración'} />
                <div className='d-flex justify-content-end mb-5'>
                    <SubmitField loading={false} handleSubmit={changeDur} name={'duration'} text='Cambiar' />
                </div>
            </Form>
        </Acc>
    )
}
