export type ModalView =
  | ''
  | 'CAL_WORKERS'
  | 'CAL_EMAIL'
  | 'BIG_BOOK'
  | 'EDIT_USER'
  | 'ADD_COM_USER'
  | 'ASIGN_TICKET_USER'
  | 'SELL_PROD_USER'
  | 'SELL_BONO_USER'
  | 'CHANGE_SES'
  | 'PAY_BONO_LEFT'
  | 'CREATE_WORKER'
  | 'EDIT_WORKER'
  | 'AUTO'
  | 'CREATE_TICKET'
  | 'PAY_TICKET'
  | 'CREATE_SERVICE'
  | 'EDIT_SERVICE'
  | 'CREATE_CAT'
  | 'CREATE_MACH'
  | 'EDIT_MACH'
  | 'CREATE_PROD'
  | 'EDIT_PROD'
  | 'CREATE_BONO'
  | 'EDIT_BONO'
  | 'SHOW_BONO_SERS'
  | 'CREATE_PUSH'
  | 'EDIT_CENTER'
  | 'EDIT_ADMIN'
  | 'READ_CONSENT'
  | 'EDIT_GLOB'
  | 'CHANGE_CENTER'
  | 'CREATE_USER'
  | 'DATE_DUE'
  | 'NEW_VERSION'
  | 'EDIT_CONSENT'
  | 'IS_REWRITE'
  | 'VIEW_SELL'
  | 'ACTION_SELL'
  | 'DELETE_SELLBONUS'
export interface ViewTitle {
  view: ModalView
  title: string
}

export const arrTitles: ViewTitle[] = [
  {
    view: 'CAL_WORKERS',
    title: 'Filtra los emplead@s',
  },
  {
    view: 'EDIT_CONSENT',
    title: 'Edita el consentimiento',
  },

  {
    view: 'CAL_EMAIL',
    title: '¿Notificar al cliente?',
  },
  {
    view: 'BIG_BOOK',
    title: 'Reservar',
  },
  {
    view: 'EDIT_USER',
    title: 'Editar usuari@',
  },
  {
    view: 'ADD_COM_USER',
    title: 'Añadir comentario',
  },
  {
    view: 'ASIGN_TICKET_USER',
    title: 'Asignar ticket',
  },
  {
    view: 'SELL_PROD_USER',
    title: 'Añadir producto',
  },
  {
    view: 'SELL_BONO_USER',
    title: 'Añadir bono',
  },
  {
    view: 'CHANGE_SES',
    title: 'Cambiar sesiones',
  },
  {
    view: 'PAY_BONO_LEFT',
    title: 'Pagar restante',
  },
  {
    view: 'CREATE_WORKER',
    title: 'Crear emplead@',
  },
  {
    view: 'EDIT_WORKER',
    title: 'Editar emplead@',
  },
  {
    view: 'AUTO',
    title: 'Horarios auto',
  },
  {
    view: 'CREATE_TICKET',
    title: 'Crear ticket',
  },
  {
    view: 'PAY_TICKET',
    title: 'Pagar ticket',
  },
  {
    view: 'CREATE_SERVICE',
    title: 'Crear servicio',
  },
  {
    view: 'EDIT_SERVICE',
    title: 'Editar servicio',
  },
  {
    view: 'CREATE_CAT',
    title: 'Crear categoría',
  },
  {
    view: 'CREATE_MACH',
    title: 'Crear máquina',
  },
  {
    view: 'EDIT_MACH',
    title: 'Editar máquina',
  },
  {
    view: 'CREATE_PROD',
    title: 'Crear producto',
  },
  {
    view: 'EDIT_PROD',
    title: 'Editar producto',
  },
  {
    view: 'CREATE_BONO',
    title: 'Crear bono',
  },
  {
    view: 'EDIT_BONO',
    title: 'Editar bono',
  },
  {
    view: 'SHOW_BONO_SERS',
    title: 'Servicios del bono',
  },
  {
    view: 'CREATE_PUSH',
    title: 'Crear notificación',
  },
  {
    view: 'EDIT_CENTER',
    title: 'Editar centro',
  },
  {
    view: 'EDIT_ADMIN',
    title: 'Editar perfil',
  },
  {
    view: 'READ_CONSENT',
    title: 'Leer consentimiento',
  },

  {
    view: 'EDIT_GLOB',
    title: 'Editar plantilla ',
  },

  {
    view: 'CHANGE_CENTER',
    title: 'Cambiar de centro',
  },
  {
    view: 'CREATE_USER',
    title: 'Crear usuari@',
  },
  {
    view: 'DATE_DUE',
    title: 'Impago',
  },
  {
    view: 'NEW_VERSION',
    title: 'Nueva versión',
  },
  {
    view: 'IS_REWRITE',
    title: 'Servidores en mantenimiento',
  },
  {
    view: 'VIEW_SELL',
    title: 'Ver venta',
  },
  {
    view: 'DELETE_SELLBONUS',
    title: 'Elimina la venta',
  },
]

export const switchTitles = (view: ModalView) => {
  const item = arrTitles.filter((el) => el.view === view)[0]
  if (item) {
    return item.title
  } else {
    return ''
  }
}
