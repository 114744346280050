import { db } from "@lib/firebase"
import { SellsBonus } from "@models/bono/sellsBonus"
import { doc, updateDoc } from "firebase/firestore"

export const relatedAmountBonus = async (sellsBonus: SellsBonus | null, frac: boolean, newAmount: number) => {
    if (!frac && sellsBonus !== null) {
        try {
            await updateDoc(doc(db, `sellsBonus/${sellsBonus.id}`), {
                amount: newAmount.toFixed(2)
            })
            
        } catch (error) {
            
        }
    } 
}