import { TimeV2 } from "@models/bookings/timeV2";

export const isPast = (filtDate: string, intervTime: TimeV2, addCanary: boolean) =>  {
    const actDate = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
    const nowDate = new Date();
    const hour = nowDate.getHours() * 60;
    const minutes = nowDate.getMinutes();
    let hoursAminutes = addCanary ? (hour + minutes)- 60 : (hour + minutes);
    if (hoursAminutes > intervTime.str && filtDate === actDate) {
      return true;
    } else {
      return false;
    }

  }