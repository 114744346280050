import { PayMeth } from "@models/paymentMetod/payMeth";
import { Sell, SimpleSell } from "@models/sells/sell"
import { Ticket } from "@models/ticket/ticket"
import { UserFinal } from "@models/user/userFinal";
import { format } from "date-fns"
import { doRandomUid } from "../random/doRandomUid";
import { SellsCounter } from "@models/sells/sellsCounter";

export const addSellTicket = (
    ticket: Ticket, 
    dateTime: Date, 
    meth: PayMeth,
    user:UserFinal,
    counter: SellsCounter
) => {
    const simpleSell: SimpleSell = {
        name: ticket.code,
        amount: ticket.amount,
        workerName: ticket!.workerName as string,
        type: "TICKS",
        orderDate: format(dateTime, 'dd-MM-yyyy HH:mm'),
        parentId: ticket.code,
        paymentMethod: meth,
        userName: user.displayName,
        userUid: user.uid,
        uidFind: doRandomUid(ticket.centerId),
        uidInCol: ticket.code
    }
    const sell: Sell = {
        uidInCol: ticket.code,
        docId: ticket.code,
        dateTime: dateTime,
        centerId: ticket.centerId,
        cross: false,
        userUid: user.uid,
        userName: user.displayName,
        email: user.email,
        amount: ticket.amount,
        paymentMethod: meth,
        allTypes: ['TICKS'],
        sells: [simpleSell],
        counter: 0,
        cif: counter.cif,
        busName: counter.busName,
        dir: counter.dir,
        postal: counter.postal,
        isAdmin: true
    }

    return sell; 
}