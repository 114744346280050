import { Acc } from '@components/ui/accordion/Acc';
import { db } from '@lib/firebase';
import { Questions } from '@models/user/questions';
import { useAuth } from 'app/modules/auth';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { DoAns } from './DoAns';

export const Ans = () => {
    const { centerId } = useAuth()
    const [quests, setquests] = useState<Questions[]>([]);
    useEffect(() => {
        const qQuest = query(collection(db, 'questions'), where('centerId', '==', centerId));
        const sub = onSnapshot(qQuest, (snap) => {
            const docs = snap.docs.map((value) => value.data()) as Questions[];
            setquests(docs)
        })
        return () => {
            sub()
        }
    }, [centerId])
    return (
        <div>
            {
                quests.map((v) => (
                    <div key={v.docId} className='mb-3'>

                    <Acc title={v.quest} id={v.docId as string} >
                        <DoAns quest={v} />
                    </Acc>
                    </div>
                ))
            }
        </div>
    )
}
