import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useMemo, useState, } from 'react'
import { Form } from '@components/formik/Form'
import { Machine } from '@models/machines/machine'
import useServicesBooking from '@lib/hooks/calendar/book/useServicesBooking'
import { Acc } from '@components/ui/accordion/Acc'
import { InlineInput } from '@components/formik/InlineInput'
import { SubmitField } from '@components/formik/SubmitField'
import { PhotoItem } from '@components/ui/item/PhotoItem'
import { InlineSelect } from '@components/formik/InlineSelect'
import { ActionButton } from '@components/ui/button/ActionButton'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { toast } from 'react-hot-toast'
import { Datepicker, localeEs } from '@mobiscroll/react'
import '@assets/sass/calendar.scss'
import { format } from 'date-fns'
import { Spinner } from '@components/ui/spinner/Spinner'
import { RadioField } from '@components/formik/radio/RadioField'
const schema = Yup.object().shape({
    name: Yup.string().required('Es necesario un nombre'),
    sesApar: Yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).required('Pon las sesiones').positive('Número positivo').typeError('Tiene que ser un número').integer('Sin decimales'),
    time: Yup.string(),
    availableDates: Yup.array().when('time', {
        is: (time: string) => time === 'si' ?  true : false,
        then: Yup.array().required(),
        otherwise: Yup.array()
    })
})

export const EditMach = (mach: Machine) => {
    const [loadingDates, setloadingDates] = useState(false)
    const { optsServices, services } = useServicesBooking([])
    const curr = useMemo(() => {
        const currIds = mach.servicesIds.map((v) => v.id);
        const c = services.filter(el => currIds.includes(el.serviceId))
        return c;
    }, [services, mach.servicesIds])

    const formik = useFormik({
        initialValues: {
            name: mach.name, sesApar: `${mach.sesApar}`,
            currService: '', time: mach.time === true ? 'si' : 'no', availableDates: mach.availableDates ? mach.availableDates : []
        },
        validationSchema: schema,
        onSubmit: async () => { },
    })
    const changeName = async () => {
        await updateDoc(doc(db, `machine/${mach.docId}`), {
            name: formik.values.name
        })
        toast.success('Cambiado')
    }
    const changeStock = async () => {
        await updateDoc(doc(db, `machine/${mach.docId}`), {
            sesApar: Number(formik.values.sesApar)
        })
        toast.success('Cambiado')
    }
    const add = async () => {
        const newSer = services.filter(s => s.serviceId === formik.values.currService)[0];
        if (mach.servicesIds.some(el => el.id === newSer.serviceId)) {
            toast.error('Servicio ya escogido')
        } else {
            const newServiceIds = { id: newSer.serviceId, name: newSer.serviceName }
            await updateDoc(doc(db, `machine/${mach.docId}`), {
                servicesIds: arrayUnion(newServiceIds)
            })
            toast.success('Cambiado')
            formik.setFieldValue('currService', '')
        }
    }

    const deleteItem = async (id: string) => {
        if (mach.servicesIds.length === 1) {
            return toast.error('Un servicio asociado por lo menos')
        }
        const newSers = mach.servicesIds.filter(el => el.id !== id);
        await updateDoc(doc(db, `machine/${mach.docId}`), {
            servicesIds: newSers
        })
        toast.success('Cambiado')
    }
    const deleteDate = async (d: string) => {
        const avs = mach.availableDates as string[];
        if (avs.length === 1) {
            return toast.error('Mínimo un día activo');
        }
        setloadingDates(true)
        const newDates = avs.filter(t => t !== d)
        if (mach.availableDates) {
            await updateDoc(doc(db, `machine/${mach.docId}`), {
                availableDates: newDates
            })
        }
        toast.success('Cambiado')
        setloadingDates(false)
    }
    const changeDates = async (d: string) => {
        setloadingDates(true)
        let avs: string[] = []
        if (mach.availableDates !== undefined) {
            avs = (mach.availableDates as string[]).concat(d)
        }
        await updateDoc(doc(db, `machine/${mach.docId}`), {
            availableDates: avs,
            time: true
        })
        toast.success('Cambiado')
        setloadingDates(false)
    }
    const changeTime = async () => {
        const v = formik.values.time === 'si' ?  true : false;
        await updateDoc(doc(db, `machine/${mach.docId}`), {
            time: v
        })
    }
    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <Acc classNames='mt-3' title={`Nombre de la máquina: ${mach.name}`} id={'Mach_name'}>
                    <InlineInput name={'name'} label={'Editar'} />
                    <div className='d-flex justify-content-end mb-5'>
                        <SubmitField loading={false} handleSubmit={changeName} name={'name'} text='Cambiar' />
                    </div>
                </Acc>
                <Acc classNames='mt-3' title={`Stock de la máquina`} id={'Mach_stock'}>
                    <InlineInput name={'sesApar'} label={'Editar'} />
                    <div className='d-flex justify-content-end mb-5'>
                        <SubmitField loading={false} handleSubmit={changeStock} name={'sesApar'} text='Cambiar' />
                    </div>
                </Acc>
                <Acc classNames='mt-3' title={`Servicios de la máquina`} id={'Mach_sers'}>
                    <InlineSelect name='currService' opts={optsServices} search={true} label={'Elige los servicios'} />
                    {
                        formik.values.currService !== '' &&
                        <ActionButton text={'Añadir'} action={add} classNames='mt-3' />
                    }
                    {
                        curr.map((ser, index) => (
                            <div key={ser.serviceId} className='me-5 mt-4'>
                                <PhotoItem
                                    index={index}
                                    img={ser?.img as string}
                                    title={ser?.serviceName as string}
                                    subText={ser?.cat}
                                    deleteFun={() => deleteItem(ser.serviceId)} whatever={undefined} />

                            </div>
                        ))
                    }
                </Acc>
                <Acc classNames='mt-3' title={'Máquina por días'} id={'Mach_time'}>
                    <div className='d-flex justify-content-center flex-column'>
                        <div className='d-flex flex-row justify-content-evenly'>
                            <RadioField title={'Máquina por días'} name={'time'} value={'si'} />
                            <RadioField title={'Máquina normal'} name={'time'} value={'no'} />
                        </div>
                        <div className='d-flex'>
                            <SubmitField loading={false} handleSubmit={changeTime} name={'time'} text='Cambiar' />
                        </div>
                        <div className='d-flex flex-wrap justify-content-center mt-5'>
                            {
                                loadingDates ? <Spinner />
                                    :
                                    (mach.availableDates && mach.time ? mach.availableDates.map((v) => (
                                        <div key={v} className='me-3 p-3 position-relative'>
                                            <i className="fa-solid fa-x position-absolute top-0 end-0 is-pointer text-danger" onClick={() => deleteDate(v)}></i>
                                            <p>{v}</p>
                                        </div>
                                    )) : null)
                            }
                        </div>
                        {
                            mach.time === true ?
                                <>

                                    <Datepicker
                                        controls={['calendar']}
                                        display="inline"
                                        touchUi={true}
                                        locale={localeEs}
                                        theme='ios'
                                        min={new Date()}
                                        onChange={(props) => {
                                            const sel = format((props as any).value, 'd/M/yyyy')
                                            if (mach.availableDates && mach.availableDates.some(el => el === sel)) {
                                                toast.error('Fecha ya seleccionada')
                                            } else {
                                                changeDates(sel)
                                            }
                                        }}
                                        defaultSelection={null}
                                    />
                                </>
                                :
                                null
                        }

                    </div>
                </Acc>
            </Form>
        </div>
    )
}
