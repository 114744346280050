import React from 'react'
import { KTSVG } from '_metronic/helpers';
import { CntTool } from './CntTool';
interface Props {
    children: React.ReactNode,
    title: string,
    containerClass?: string,
    important?: boolean,

}
export const ToolBarPag = ({ children, title, containerClass, important }: Props) => {
    return (
        <div className={`card-toolbar ${containerClass && containerClass}`} >
            {/* begin::Menu */}
            <button
                type='button'
                style={{ width: 100 }}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                className={`btn btn-sm
         text-white
         btn-icon
         ${important ? 'btn-est' : 'btn-primary'}
         btn-primary
         position-relative
         d-flex justify-content-center align-items-center
         `}
            >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                {title}
            </button>
            <CntTool title={title}>
                {children}
            </CntTool>
        </div>
    )
}
