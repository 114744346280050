import { ReuseNot } from "@components/nots/ReuseNot";
import { ActionButton } from "@components/ui/button/ActionButton"
import { BarItem } from "@components/ui/item/BarItem";
import { TDAny } from "@components/ui/table/TDAny";
import { TDText } from "@components/ui/table/TDText";
import { TRPar } from "@components/ui/table/TRPar";
import { TableBody } from "@components/ui/table/TableBody";
import { TableHead } from "@components/ui/table/TableHead";
import { TablePag } from "@components/ui/table/TablePag";
import { BoldText } from "@components/ui/text/BoldText"
import { db } from "@lib/firebase";
import useFilterTable from "@lib/hooks/ui/useFilterTable";
import { PushCenter } from "@models/nots/pushCenter";
import { showModal } from "@redux/features/uiSlice/uiSlice";
import { useAppDispatch } from "@redux/store/hooks";
import { KTCard } from "_metronic/helpers";
import { useAuth } from "app/modules/auth";
import { collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
const headsUsers = ['Título', 'Mensaje', 'Usuarios', 'Fecha', 'Reutilizar', 'Eliminar']

const NotsPage: React.FC = () => {
    const { centerId, currentUser } = useAuth();
    const [push, setpush] = useState<PushCenter[]>([])
    const [usersPush, setusersPush] = useState<number>(0)
    const dispatch = useAppDispatch();
    const { dataTable, setDebounceQuery, totalResults, handleChangePage, currentPage, resultsPerPage } = useFilterTable(push);
    const passSeachterm = useCallback(
        (str: string) => {
            setDebounceQuery(str)
        },
        [setDebounceQuery])

    const create = () => {
        dispatch(showModal({ view: 'CREATE_PUSH', editId: `${usersPush}` }))
    }

    const deleteNot = async (not: PushCenter) => {
        await deleteDoc(doc(db, `pushCenter/${not.docId}`))
        toast.success('Notificación eliminada')
    }

    useEffect(() => {
        let active = true;
        if (active) {
            const getSize = async () => {
                const qUserPush = query(collection(db, 'users'), where('role', 'array-contains', 'user'), orderBy('push'), where('centerClient', '==', centerId))
                const size = (await getDocs(qUserPush)).size;
                setusersPush(size)
            }
            getSize()
        }
        return () => {
            active = false;
        }
    }, [centerId])


    useEffect(() => {
        const qNots = query(collection(db, 'pushCenter'), where('center', '==', centerId))
        const sub = onSnapshot(qNots, (snap) => {
            let d = snap.docs.map((v) => v.data()) as PushCenter[];
            d = d.sort((a, b) => {
                const parseDate = (dateString: string): Date => {
                    const [day, month, year] = dateString.split('/').map(Number);
                    return new Date(year, month - 1, day);
                };
                const dateA = parseDate(a.dateShow as string).getTime();
                const dateB = parseDate(b.dateShow as string).getTime();
                return  dateB - dateA;
            })
            setpush(d)
        })
        return () => {
            sub()
        }
    }, [centerId])
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-5'>
                <BoldText classNames='fs-2' text='Notificaciones del centro' />
                {
                    usersPush > 0 ?
                        <ActionButton important={true} text={'Crear'} action={create} />
                        : null
                }
            </div>
            <KTCard>
                <TableBody options={
                    <div className='d-flex justify-content-start align-items-center'>
                        <TableHead passSearch={passSeachterm} />
                    </div>
                }
                    heads={headsUsers}>
                    {
                        dataTable.map((value) => (
                            <TRPar key={`${new Date().getTime()}-${value.docId}`}>
                                <TDAny classNames='w-75px'>
                                    <div className={` ms-5 d-flex justify-content-start`}>
                                        <BarItem isTable={true} title={value.title} desc={''} back={'bg-aqua'} />
                                    </div>
                                </TDAny>
                                <TDText text={`${value.mess}`} />
                                <TDText text={`${value.numUsers}`} />
                                <TDText text={`${value.dateShow}`} />
                                <TDAny>
                                    <ReuseNot usersPush={usersPush} push={value} userUid={currentUser!.uid} />
                                </TDAny>
                                <TDAny>
                                    <ActionButton text={'Eliminar'}   action={() => deleteNot(value)} />
                                </TDAny>
                            </TRPar>
                        ))
                    }
                </TableBody>
                <TablePag className={'pagination-bar'} totalResults={totalResults} currentPage={currentPage} resultsPerPage={resultsPerPage} onChange={handleChangePage} />
            </KTCard>
        </div>
    )
}

export default NotsPage