import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Worker } from '@models/user/worker'
import { Form } from '@components/formik/Form'
import { KTCard, toAbsoluteUrl } from '_metronic/helpers'
import { BoldText } from '@components/ui/text/BoldText'
import { InlineInput } from '@components/formik/InlineInput'
import { SubmitField } from '@components/formik/SubmitField'
import { callCloud } from '@lib/helpers/callCloud'
import toast from 'react-hot-toast'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { useAuth } from 'app/modules/auth'
import { WorkerCrAv } from '../WorkerCrAv'
interface Props {
    wor: Worker
}
const schema = Yup.object().shape({
    workerName: Yup.string().required('Es necesario un nombre'),
    avatar: Yup.string().required('Es necesario un avatar'),
    workerEmail: Yup.string().email('Pon un formato correcto').required('Es necesario un nombre'),
    password: Yup.string().required('Falta información de cliente').min(10, '10 carácteres mínimo').max(25, 'Demasiado largo')
        .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, 'La contraseña requiere números y letras'),
})
const avatarArr = ['001-boy', '002-girl', '004-boy-1', '005-girl-2', '015-boy-6', '047-girl-25']

export const EditInfo = ({ wor }: Props) => {
    const initialValues = { workerEmail: wor.mail, password: '', avatar: wor.avatar, workerName: '' }
    const [newPass, setnewPass] = useState<boolean>(false)
    const [newEmailLoad, setlNewMail] = useState<boolean>(false)
    const { currentUser } = useAuth()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async () => { },
    })

    const changeNameWorker = async () => {
        try {
            const rewChangeWorkerName = callCloud('rewChangeWorkerName');
            const res = (await rewChangeWorkerName({ centerId: wor.centerWorkerName, uid: wor.uid, oldName: wor.name, newName: formik.values.workerName }))
            .data as boolean;
            if (res === true) {
                toast.success('Nombre cambiado')
            } else {
                toast.error('Error al reescribir las citas')
            }
            toast.success('Nombre cambiado')
        } catch (error) {
            toast.error('Error al reescribir las citas')
        }
    }

    const changeEmail = async () => {
        if (currentUser) {
            if (currentUser.role.includes('worker')) {
                return toast.error('Esto es una acción sólo para admins')
            }
        }
        setlNewMail(true);
        try {
            const changeEmailWorker = callCloud('changeEmailWorker');
            await changeEmailWorker({ uid: wor.uid, newEmail: formik.values.workerEmail })
            await updateDoc(doc(db, `users/${wor.uid}`), {
                mail: formik.values.workerEmail
            })
            toast.success('Email cambiado')
            formik.resetForm()
        } catch (error) {
            toast.error('Error al cambiar el email')
        }
        setlNewMail(false)
    }


    const changePassword = async () => {
        if (currentUser) {
            if (currentUser.role.includes('worker')) {
                return toast.error('Esto es una acción sólo para admins')
            }
        }
        setnewPass(true)
        try {
            const changePassword = callCloud('changePassword');
            await changePassword({ uid: wor.uid, newPass: formik.values.password })
            toast.success('Email cambiado')
            formik.resetForm()
        } catch (error) {
            toast.error('Error al cambiar el email')
        }
        setnewPass(false)
    }

    const changeAv = async () => {
        await updateDoc(doc(db, `users/${wor.uid}`), {
            avatar: formik.values.avatar
        })
        toast.success('Cambiado')
    }

    return (
        <Form formikVal={formik}>
            <KTCard className='mb-10 p-4'>
                <BoldText text={`Información de ${wor.name.split(' ')[0]}`} classNames='fs-2' />
                <InlineInput name={'workerEmail'} label={'Cambiar email'} />
                <div className='d-flex justify-content-end mb-5'>
                    <SubmitField loading={newEmailLoad} handleSubmit={changeEmail} name={'workerEmail'} text='Cambiar email' />
                </div>
                {
                    currentUser && currentUser.role.includes('superAd') ?
                        <>
                            <InlineInput name={'workerName'} label={'Cambiar nombre'} />
                            <div className='d-flex justify-content-end mb-5'>
                                <SubmitField loading={newEmailLoad} handleSubmit={changeNameWorker} name={'workerName'} text='Cambiar nombre' />
                            </div>
                        </>
                        : null
                }
                <InlineInput name={'password'} label={'Contraseña'} type='password' />
                <div className='d-flex justify-content-end mb-5'>
                    <SubmitField loading={newPass} handleSubmit={changePassword} name={'password'} text='Cambiar contraseña' />
                </div>

                <WorkerCrAv title='Cambiar avatar' />
                <div className='d-flex justify-content-end mt-5 mb-5'>
                    <SubmitField loading={newPass} handleSubmit={changeAv} name={'avatar'} text='Cambiar avatar' />
                </div>
            </KTCard>
        </Form>
    )
}
