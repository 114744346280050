export const mapTurns = (t: any[]) => {
    const tr = t.map((item) => {
        const { ...restProps } = item;
        if (item.rest === 'Nowork') {
          delete restProps.intWorkerMor1;
          delete restProps.intWorkerMor2;
          delete restProps.intWorkerAft1;
          delete restProps.intWorkerAft2;
        } else if (item.rest === 'Intensivo') {
          delete restProps.intWorkerAft1;
          delete restProps.intWorkerAft2;
        }
        return restProps;
      });
 return tr
}