import { FC, createContext, useContext, useState, useEffect } from "react";
import { TurnAuto } from '@models/turns/turn-auto';
import { useAuth } from "app/modules/auth";
import { useAppSelector } from "@redux/store/hooks";
import { selectorWorUid } from "@redux/features/uiSlice/uiSlice";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '@lib/firebase';
import { parse } from 'date-fns';
import { TurnGlobal } from "@models/turns/turnGlobal";


export interface AutoWorState {
    autos: TurnAuto[],
    globs: TurnGlobal[]
}

export const AutoWorCxt = createContext<AutoWorState>({autos: [], globs: []})

interface childs {
    children: React.ReactNode
}

export const AutoWorContext: FC<childs> = (props) => {
    const [autos, setautos] = useState<TurnAuto[]>([])
    const [globs, setglobs] = useState<TurnGlobal[]>([])
    const uid = useAppSelector(selectorWorUid);
    const {centerId} = useAuth()
    useEffect(() => {
        const q = query(
          collection(db, 'turnPlan'),
          where('centerId', '==', centerId),
          where('workerDoc', '==', uid)
        );
        const unsub = onSnapshot(q, (snap) => {
          let t = snap.docs.map((value) => value.data()) as TurnAuto[];
          console.log(t)
          t = t.sort((a,b) => {
            const a1 = parse(a.firstDate as string, 'd/M/yyyy', new Date()).getTime()
            const b1 = parse(b.firstDate as string, 'd/M/yyyy', new Date()).getTime()
            return a1 - b1
          })
          setautos(t)
        })
        return () => {
          unsub()
        }
      }, [centerId, uid])
      useEffect(() => {
        const q = query(
            collection(db, 'turnGlobal'),
            where('centerId', '==', centerId),
          );
          const unsub = onSnapshot(q, (snap) => {
            let t = snap.docs.map((value) => value.data()) as TurnGlobal[];
            setglobs(t)
          })
      
        return () => {
          unsub()
        }
      }, [centerId])
      
      return <AutoWorCxt.Provider value={{autos, globs}} {...props} />

}

export const useAutoContext = () => {
    const context = useContext<AutoWorState>(AutoWorCxt);
    return context;
}