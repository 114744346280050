import React from 'react'
interface Props {
    text: string,
    classNames?: string
}
export const BoldText = ({text, classNames}: Props) => {
  return (
    <p className={`fw-semibold ${classNames ? classNames : ''} `}>{text}</p>
  )
}
