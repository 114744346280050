import React from 'react'
interface Props {
    icon: string
}
export const IconItem = ({icon}: Props) => {
    return (
        <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-primary-active'>
                <i className={`${icon} text-white fs-2x`}></i>
            </span>
        </div>
    )
}
