import React from 'react'
interface Props {
    title: string,
    children: React.ReactNode
}
export const CntTool = ({title, children}: Props) => {
    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>{title}</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5 d-flex flex-column align-items-center'> 
                {children}
            </div>

        </div>
    )
}
