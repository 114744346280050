import { Worker } from '@models/user/worker'
import { KTCard } from '_metronic/helpers'
import React from 'react'
import { DefTurns } from '../DefTurns'
import { useFormik } from 'formik'
import { Form } from '@components/formik/Form'
import {cloneDeep} from 'lodash';
import { mapTurns } from '@lib/helpers/turns/mapTurns'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
interface Props {
  wor: Worker
}
const initialValues = {
  intWorkerMor1: '',
  intWorkerMor2: '',
  intWorkerAft1: '',
  intWorkerAft2: '',
  rest: '',
  turns: [],
  day: ''
}
export const ChangeTimesWor = ({ wor }: Props) => {
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => { 
      const oldArr = cloneDeep(wor.turns)
      const indexOld = oldArr.findIndex(v => v.day === values.day);
      oldArr[indexOld] = {...values}
      const def = mapTurns(oldArr);
      await updateDoc(doc(db, `users/${wor.uid}`), {
        turns: def
      })
    }, 
  })
  return (
    <KTCard className='p-4 mb-6'>
      <Form formikVal={formik}>
        <DefTurns needsReset={false} currTurns={wor.turns} />
      </Form>
    </KTCard>
  )
}
