import { BoldText } from '@components/ui/text/BoldText'
import React from 'react'
interface Props {
    del: () => void,
    quest: string,
    text: string
}
export const DelOp = ({del, quest, text}: Props) => {
    return (
        <div className='mt-10 d-flex justify-content-end flex-row'>
            <BoldText text={quest} />
            <p onClick={del} className='text-danger is-pointer ms-2'> {text}</p>
        </div>
    )
}
