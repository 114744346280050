import React from 'react'
import { KTSVG } from '_metronic/helpers';
import useComponentVisible from '@lib/hooks/ui/useComponentVisible';


interface Props {
    children: React.ReactNode,
    title: string,
    containerClass?: string,
    important?: boolean,

}

export const ToolBar = ({children, title, containerClass, important}: Props) => {
    const {setIsComponentVisible, ref, isComponentVisible, handlerRef} = useComponentVisible(false)
    const toggle = () => {
        setIsComponentVisible(!isComponentVisible)
    }
    return (
        <div className={`card-toolbar ${containerClass && containerClass}`} >
            {/* begin::Menu */}
            <button ref={handlerRef}
                type='button'
                style={{width: 100}}
                className={`btn btn-sm
                 text-white
                 btn-icon
                 ${important ? 'btn-est' : 'btn-primary' }
                 btn-primary
                 position-relative
                 d-flex justify-content-center align-items-center
                 `}
                onClick={toggle}
            >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                {title}
            </button>
            <div ref={ref} className={`container-drop res-drop w-250px w-md-300px position-absolute start-lg-0 ${isComponentVisible ? 'd-block' : 'd-none'}`}>
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{title}</div>
                </div>

                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5'>
                    {children}
                </div>
            </div>
            {/* end::Menu */}
        </div>
    )
}
