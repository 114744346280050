import useBill from '@lib/hooks/bill/useBill'
import { selectType } from '@redux/features/billSlice/billSlice'
import { selectRec } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import React, { useEffect, useState } from 'react'

export const RecBanner = () => {
    const [amountRec, setamountRec] = useState({ totalSells: 0, totalAmount: 0 })
    const hasRec = useAppSelector(selectRec)
    const currType = useAppSelector(selectType);
    const t = currType !== null ? currType : null;
    const { clean } = useBill(t)
    useEffect(() => {
        if (hasRec && t === null) {
            const fromRec = clean.filter(el => el.isRec === true);
            if (fromRec.length > 0) {
                setamountRec({ totalSells: fromRec.length, totalAmount: fromRec.reduce((a, b) => +a + +b.amount, 0) })
            }
        }
    }, [clean, t, hasRec])
    return (
        <div className='w-100 d-flex justify-content-center'>
            {
                hasRec && amountRec.totalSells > 0 && t === null ?
                <div className='card '>
                    <div className='px-9 pt-7 card-rounded h-70px w-100 bg-success'>   
                        <h3 className='m-0 text-white fw-bold fs-3'>El recomendador ayudó con {amountRec.totalSells} ventas,
                        facturando {amountRec.totalAmount.toFixed(2)} €</h3>
                    </div>
                </div>
                : null

            }
        </div>
    )
}
