import { selectPureWorkers } from "@redux/features/gquerySlice/gquerySlice";
import { useAppSelector } from "@redux/store/hooks";
import { useMemo } from "react";

export default function useWorkersSells() {
    const workers = useAppSelector(selectPureWorkers);
    const worOpts = useMemo(() => {
        const arrOpts = workers.map((value) => ({label: value.name, value: value.name}));
        return arrOpts

    }, [workers])
    return {
        worOpts,
        workers
    }

}