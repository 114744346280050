import { toAbsoluteUrl } from '_metronic/helpers'
import { useFormikContext } from 'formik';
const avatarArr = ['001-boy', '002-girl', '004-boy-1', '005-girl-2', '015-boy-6', '047-girl-25']
interface Props {
    title: string
}
export const WorkerCrAv = ({ title }: Props) => {
    const { setFieldValue, values } = useFormikContext<any>();

    return (
        <div>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>{title}</label>
            {
                values.avatar !== '' ?
                    <div className='symbol symbol-50px me-5 is-pointer position-relative' onClick={() => { setFieldValue('avatar', '') }}>
                        <i  className="fa-solid fs-2 fa-x position-absolute ms-5 top-0 is-pointer text-danger "
                            style={{ zIndex: 3, right:-20 }} onClick={() => setFieldValue('avatar', '')}></i>
                        <img src={toAbsoluteUrl(`/media/svg/avatars/${values.avatar}.svg`)} alt='avatar' />
                    </div>
                    :
                    <div className='d-flex justify-content-center '>
                        {
                            avatarArr.map((v, index) => (
                                <div key={index} className='symbol symbol-50px me-5 is-pointer' onClick={() => { setFieldValue('avatar', v) }} >
                                    <img src={toAbsoluteUrl(`/media/svg/avatars/${v}.svg`)} alt='avatar' />
                                </div>
                            ))
                        }
                    </div>
            }
        </div>
    )
}
