import { Ticket } from "@models/ticket/ticket"
import { useState } from "react";


export default function useTicketSells() {
    const [showFinal, setshowFinal] = useState(0)
    const makePriceTicket = (t: Ticket, price: number) => {
        const priceTicket = t.amount;
        let restTicket = 0;
        let restAmount = 0;
        if (priceTicket > price) {
            restTicket = (priceTicket - price);
            setshowFinal(0)
        } else {
            const finalAmount = (price - priceTicket);
            restTicket = finalAmount === 0 ? 0 : (priceTicket - price);
            restAmount = finalAmount
            setshowFinal(finalAmount)
        }
        return {restTicket, restAmount, ticketId: t.uid as string}
    }

    return {
        makePriceTicket,
        showFinal,
    }

}