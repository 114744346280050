import React, { useEffect, useState } from 'react'
interface Props {
    title: string,
    desc: string,
    back: string,
    classContainer?: string,
    isTable?: boolean
}
export const BarItem = ({title, desc, back, classContainer, isTable}: Props) => {
  const [random, setrandom] = useState('')
  useEffect(() => {
    if (back === '') {
      let rawColor = ''
      const colors = ['bg-success', 'bg-danger', 'bg-warning', 'bg-primary-active']
      for (var i = 0; i < 1; i++) {
          rawColor = colors[Math.floor(Math.random() * colors.length)];
      }
      setrandom(rawColor)
    }
  }, [back])
  
  return (
    <div className={`d-flex align-items-center ${isTable ? 'mb-0' : 'mb-8'} ${classContainer ? classContainer : ''}`}>
    <span className={`bullet bullet-vertical h-40px ${back !== '' ? back : random}`}></span>
    {/* <div className='form-check form-check-custom form-check-solid mx-5'>
      <input className='form-check-input' type='checkbox' value='' />
    </div> */}
    {/* end::Checkbox */}
    {/* begin::Description */}
    <div className='flex-grow-1 ms-4'>
      <p  className={`fw-semibold ${isTable ? 'size-table' : 'fs-6'} mb-1`}>
        {title}
      </p>
      {/* text-muted */}
      <span className='text-gray-800 fw-semibold fs-6 mb-1 position-relative' style={{bottom: 10}}>{desc}</span>
    </div>
    {/* end::Description */}
    {/* <span className='badge badge-light-success fs-8 fw-bold'>New</span> */}
  </div>
  )
}
