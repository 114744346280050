import { Col, Row } from '@assets/sass/bootstrapEx';
import { IconButton } from '@components/ui/button/IconButton';
import { BoldText } from '@components/ui/text/BoldText';
import { selectTicketEnd } from '@redux/features/ticketSlice/ticketSlice';
import { useAppSelector } from '@redux/store/hooks';
import { toast } from 'react-hot-toast';

export const SeeCode = () => {
  const { code, amount, error } = useAppSelector(selectTicketEnd)
  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      toast.success('Copiado')
    } catch (err) {
      toast.error('Error al copiar')

    }
  };
  return (
    <div>
      {
        !error ?
          <div className='d-flex justify-content-center'>
              <div className={`${Col} w-100 justify-content-center me-5`}>
                <BoldText classNames='fs-2 text-success' text={`Valor total del ticket ${amount.toFixed(2)} €`} />
                <BoldText classNames='fs-2 text-center' text={code} />
                <div className='d-flex justify-content-center'>
                  <IconButton action={copyToClipBoard}  iconClass={'fa-regular fa-copy'} />
                </div>
              </div>
          </div>
          : <p className='text-danger text-center mt-5'>Error al crear el ticket</p>
      }
    </div>
  )
}
