import { CenterCol } from '@assets/sass/bootstrapEx'
import { BoldText } from '@components/ui/text/BoldText'
import React from 'react'

export const IsRewrite = () => {
    return (
        <div className={`${CenterCol} w-100 p-4 `}>
            <BoldText text={'Los servidores están en mantenimiento, vuelve más tarde'} />
        </div>
    )
}
