import { ClientHead } from "@components/clients/detail/ClientHead";
import { Ans } from "@components/clients/quest/Ans";
import { Com } from "@components/clients/quest/Com";
import { SeeAns } from "@components/clients/quest/SeeAns";
import { ContainerSec } from "@components/common/sectionspage/ContainerSec";
import { SectionsPage } from "@components/common/sectionspage/SectionsPage";


const ClientQuests: React.FC = () => {
    return (
        <div>
            
            <SectionsPage hasCompany={<ClientHead isForBack={true} />} indexSec={1} content={
                <>
                    <ContainerSec isFirst={true} content={ <SeeAns /> } id={"quests"} />
                    <ContainerSec content={<Ans />} id={"answer"} />
                    <ContainerSec content={<Com />} id={"add_comment"} />
                </>
            } />
        </div>
    )
}

export default ClientQuests