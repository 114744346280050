
import { useAuth } from 'app/modules/auth'
import { Navigate, Outlet } from 'react-router-dom'
const SuperProtected = () => {
    const {currentUser} = useAuth()
    if (currentUser?.role.includes('superAd') === false) {
        return <Navigate to='/dashboard' replace />
    }
    return (
        <Outlet />
    )
}

export { SuperProtected }