interface Props {
    text: string,
    classNames?: string,
    action: () => void;
    isDanger?: true,
    important?: boolean,
    disabled?: boolean
}

export const ActionButton = ({text, action, classNames, isDanger, important, disabled}: Props) => {
 return (
    <button 
    disabled={disabled ? disabled : false}
    type="button" onClick={action} className={`btn text-white ${isDanger ? 'btn-danger' : (important ? 'btn-est' : 'btn-primary') } ${classNames ? classNames : ''}`} >
            {text}
    </button>
 )
}