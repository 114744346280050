import { NavWiz } from '@components/common/wiz/NavWiz'
import { StepWiz } from '@components/common/wiz/StepWiz'
import { Wiz } from '@components/common/wiz/Wiz'
import React from 'react'
import { StepInfoBono } from './StepInfoBono'
import { StepPack } from './StepPack'

export const CreateBonoCnt = () => {
  // TODO verify bono creation, make query to check if same name, 
    return (
        <Wiz type='BONO_FORM'
        navs={
          <>
            <NavWiz isFirst={true} title='Bono' />
            <NavWiz title='Packs' />
          </>
        }
        contentForm={
          <>
            <StepWiz isFirst={true}>
                <StepInfoBono />
            </StepWiz>
            <StepWiz>
                <StepPack />
            </StepWiz>
          </>
        } />
      )
}
