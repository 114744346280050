import { ActionButton } from '@components/ui/button/ActionButton'
import { TDAny } from '@components/ui/table/TDAny'
import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { Sell } from '@models/sells/sell'
import { showModal } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
type Props = {
    dataTable: Sell[]
}
export const TabSells = ({ dataTable }: Props) => {
    const dispatch = useAppDispatch();
    const edit = (id: string) => {
        dispatch(showModal({ view: 'VIEW_SELL', editId: id }))
    }
    const actions = (id: string) => {
        dispatch(showModal({ view: 'ACTION_SELL', editId: id }))
    }

    return (
        <>
            {dataTable.length > 0 ?
                dataTable.map((value) => (
                    <TRPar key={`${new Date().getTime()}-${value.docId}`}>
                        <TDText text={value.counter.toString()} />
                        <TDText text={value.amount.toFixed(2)} />
                        <TDText text={value.userName} />
                        <TDText text={ format((value.dateTime instanceof Timestamp ? (value.dateTime as any).toDate() : value.dateTime ), 'dd-MM-yyyy')} />
                        <TDAny>
                            <ActionButton text={'Ver'} action={() => edit(value.docId)} />
                        </TDAny>
                        <TDAny>
                            <ActionButton text={'Ver'} action={() => actions(value.docId)} />
                        </TDAny>

                    </TRPar>

                ))
                : null
            }

        </>
    )
}
