import { useAuth } from "app/modules/auth"
import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { Navigate, Outlet } from "react-router-dom"

const AdminProtected = () => {
    const {currentUser} = useAuth()
    useEffect(() => {
        if (currentUser?.role.includes('worker') === true) {
            toast.error('Página sólo para administradores')
        }
    }, [currentUser]);
    if (currentUser?.role.includes('worker') === true) {
        
        return <Navigate to='/dashboard' replace />
    }
    return (
        <Outlet />
    )
}

export {AdminProtected}