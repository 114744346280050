import { AsyncAction } from "@components/ui/button/AsyncAction";
import { BoldText } from "@components/ui/text/BoldText";
import { useBillContext } from "@lib/context/bill/BillCxt";
import { db } from "@lib/firebase";
import { sellsBonusCases } from "@lib/helpers/sells/sellsBonusCases";
import { CrossSell } from "@models/sells/crossSell";
import {  Sell, SimpleSell } from "@models/sells/sell";
import { closeSellsModal, selectEditBill } from "@redux/features/billSlice/billSlice";
import { useAppDispatch, useAppSelector } from "@redux/store/hooks";
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const DelBill = () => {
  const { item, type, docId } = useAppSelector(selectEditBill);
  const { sells } = useBillContext();
  const dispatch = useAppDispatch();
  const [sell, setsell] = useState(null as Sell | null)
  useEffect(() => {
    let active = true;
    if (item && active) {
      const setSellOrCross = async () => {
        const cross = (await getDoc(doc(db, `${'sells'}/${item.parentId}`))).data() as Sell;
        setsell(cross);
      }
      setSellOrCross()
      return () => {
        active = false;
      }
    }
    
  }, [item])
  if (!item) {
    return null;
  }
    const deleteItem = async () => {
      if (sell && item.cross === true && sell.sells.some(el => el.type === 'TICKS' || el.type === 'BONUS')) {
        const names = sell.sells.filter(el => el.type === 'TICKS' || el.type === 'BONUS').map((v) => `${v.type === 'TICKS' ? 'ticket ' : 'bono '} ${v.name}`).join(', ');
        return toast.error(`En esta venta con pago mixto hay: ${names}, elimínalo primero en la página de cliente`)
      }
      try {
        if (type === 'TICKS') {
          const size = (await getDocs(query(collection(db, 'tickets'), where('code', '==', item.name)))).size
          if (size > 0) {
            return toast.error('Elimina el ticket desde la página de tickets')
          }
        }
        if (type === 'BONUS') {
          const {bo} = await sellsBonusCases(docId, item.uidFind);
          if (bo) {
            return toast.error('Elimina el bono desde la página de bonos de clientes')
          }
        }
        if (item.cross === true) {
            await deleteDoc(doc(db, `${'sells'}/${docId}`)) 
        } else {
          const sell = item as SimpleSell;
          const col = sell.col ? sell.col : 'sells';
          const parent = sells.filter(sell => sell.docId === docId)[0];
          const sellsWithoutItem = parent.sells.filter(s => s.uidFind !== item.uidFind);
          if (sellsWithoutItem.length > 0) {
            const newAmount = sellsWithoutItem.reduce((a, b) => +a + +b.amount, 0);
            await updateDoc(doc(db, `${col}/${docId}`), {
              sells: sellsWithoutItem,
              amount: newAmount,
            })
          } else {
            await deleteDoc(doc(db, `${col}/${docId}`))        
          }
        }      
        toast.success('Eliminado')        
      } catch (error) {
        console.log(error)
        toast.error('Error al eliminar')
      }
      dispatch(closeSellsModal())
    }
    return (
    <div className="p-4">
      <BoldText text={"Eliminar la venta no cambiará el número del contador"} />
      <BoldText text={item.cross === true ? `Estás eliminando toda la venta: 
      ${sell?.sells.map((v) => v.name).join(', ')} por un valor total de ${item.amount} €` : `Estás eliminado ${item.name}`}  />
        <BoldText text={`¿Seguro que quieres hacerlo?`} classNames="mt-4 mb-4 text-center"  />
        <AsyncAction classNames="d-block mx-auto" isDanger={true} loading={false} text={"Eliminar venta"} action={deleteItem} />
    </div>
  )
}

