import { useAuth } from 'app/modules/auth';
import React, { useCallback } from 'react'
import { TableHead } from '../TableHead';
import { getDocs, collection, query, where } from 'firebase/firestore'
import { db } from '@lib/firebase';
type Props = {
  sendResBack: (arr: any[]) => void;
  nameCol: string,
}

export const SearchAlgo = React.memo(({ nameCol, sendResBack }: Props) => {
  const { centerId } = useAuth()
  const trFun = useCallback((str: string) => {
    return str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }, [])
  const passSearch = useCallback(async (str: string) => {
    if (str.length > 2) {
      const trStr = trFun(str);
      const arr = trStr.split(' ');
      const facetDiff = nameCol === 'users' ? 'centerClient' : 'centerId'
      const q = query(collection(db, nameCol), 
      where('search', 'array-contains', trStr), 
      where(facetDiff, '==', centerId))
      const qArr = query(collection(db, nameCol), 
      where('search', 'array-contains-any', arr), 
      where('centerClient', '==', centerId));
      const res = ((await getDocs(q)).docs).map((v) => v.data())
      const resArr = ((await getDocs(qArr)).docs).map((v) => v.data());
      const cleanResArr = resArr
      .filter(el => trFun(el.displayName).includes(trStr))
      .filter(el => !res.find((x => x.uid === el.uid)));
      const total = res.concat(cleanResArr);
      sendResBack(total.map((value) => ({...value, uuid: value.uid})))
    } else {
      sendResBack([])
    }
  }, [nameCol, sendResBack, centerId, trFun])

  return (
    <TableHead passSearch={passSearch} />
  )
})
