export const convertPhone = (phone: string) => {
    const trimedPhone = phone.trim();
    const r = new RegExp(/^34?(6\d{2}|7[1-9]\d{1})\d{6}$/);
    const sameNumber = new Set(trimedPhone.substring(1)).size === 1;
    if (phone.charAt(0) === '9' || new Set(trimedPhone).size === 1) {
        return '';
    }
    if (trimedPhone.length === 9) {
        if (r.test(`34${trimedPhone}`) && !sameNumber) {
            return `34${trimedPhone}`
        } else {
            return ''
        }
    } else {
        let res = ''
        res = trimedPhone.indexOf('6') !== -1 ? `34${trimedPhone.substring(trimedPhone.indexOf('6'), trimedPhone.length)}` : '';
        if (res === '') {
            res = trimedPhone.indexOf('7') !== -1 ? `34${trimedPhone.substring(trimedPhone.indexOf('7'), trimedPhone.length)}` : '';
        }
        if (r.test(res)) {
            return res
        } else {
            return ''
        }
    }
}