import { Acc } from '@components/ui/accordion/Acc'
import { Cat } from '@models/services/serviceCat'
import React, { useEffect, useState } from 'react'
import { SelCat } from '../create/SelCat'
import { Form } from '@components/formik/Form'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import toast from 'react-hot-toast'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { SubmitBtn } from '@components/formik/SubmitBtn'
interface Props {
    currCat: string,
    cats: Cat[],
    serviceId: string
}
const schema = Yup.object().shape({ cat: Yup.string().required('Es necesaria una categoría') })
export const CatSer = ({ cats, currCat, serviceId }: Props) => {
    const [freeCats, setfreeCats] = useState<Cat[]>([])

    const [loading, setloading] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: { cat: '' },
        validationSchema: schema,
        onSubmit: async (values) => {
            setloading(true)
            await updateDoc(doc(db, `services/${serviceId}`), {
                cat: values.cat
            })
            toast.success('Cambiado')
            setloading(false)
        },
    })
    useEffect(() => {
        const frees = cats.filter(c => c.name !== currCat)
        setfreeCats(frees)
    }, [currCat, cats])

    return (
        <div>
            <Acc title={`Categoría: ${currCat}`} id={'cat'}>
                <Form formikVal={formik} >

                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Selecciona una categoría</label>
                    <div className='container'>
                        <div className='d-flex flex-wrap bg-light-aqua rounded-2 pt-3'>
                            {
                                freeCats.map((cat) => (
                                    <div key={cat.uid} style={{ width: 200, height: 90 }}>
                                        <SelCat name='cat' value={cat.name} nameCat={cat.name} iconCat={cat.iconCat ? cat.iconCat : ''} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={`${JustEnd} mt-5`}>
                        <SubmitBtn loading={loading} />
                    </div>

                </Form>
            </Acc>
        </div>
    )
}
