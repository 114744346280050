import { db } from "@lib/firebase"
import { QueryFieldFilterConstraint, collection, getCountFromServer, limit, getDocs, orderBy, query, startAfter, } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
/* 
Test:
// firebase emulators:start  --import=./mydir --export-on-exit mydir 
    Firebase rules,
    pagination changes,
    count works,


*/
export default function useTableQuery(
    nameCol: string,
    queryCons: QueryFieldFilterConstraint[],
    nameOrder: string,
    desc?: boolean

) {
    const [page, setpageAt] = useState(1)
    const [data, setdata] = useState<any[]>([])
    const [dataTable, setdataTable] = useState<any[]>([])
    const [countDocs, setcountDocs] = useState(0);
    const [isFirst, setisFirst] = useState(true)
    const [searchRes, setsearchRes] = useState<any[]>([])
    useEffect(() => {
        let active = true;
        if (active) {
            const getCount = async () => {
                try {
                    const count = await getCountFromServer(query(collection(db, `${nameCol}`), ...queryCons));
                    setcountDocs(count.data().count)
                } catch (error) {
                    console.log(error)
                }
            }
            getCount()
        }

        return () => {
            active = false;
        }
    }, [nameCol, queryCons])

    const addingDocs = useCallback(
        async (startAft: any | null) => {
            let que: any[] = [];
            if (startAft === null) {
                que = [...queryCons, orderBy(nameOrder, desc === true ? 'desc' : 'asc'), limit(100)]
            } else {
                const trStartAft = typeof startAft === 'string' ? startAft : startAft.toDate();
                que = [...queryCons, orderBy(nameOrder, desc === true ? 'desc' : 'asc'), startAfter(trStartAft), limit(100)]
            }
            const q = query(collection(db, `${nameCol}`), ...que);
            const ds = (await getDocs(q)).docs.map((value) => {
                const data = value.data();
                data.uuid = value.id;
                return data as any[]
            })
            setdata((prev) => {
                return [...prev, ...ds]
            })
            setdataTable(ds.slice(0, 10))
        },
        [nameOrder, queryCons, nameCol, desc],
    )


    useEffect(() => {
        if (isFirst) {
            const firstQuery = async () => {
                await addingDocs(null)
            }
            setisFirst(false)
            firstQuery()
        }

    }, [isFirst, addingDocs])

    useEffect(() => {
        const charge = async () => {
            if (countDocs > 0) {
                const shouldBeMore = page * 10 > data.length && data.length > 0;
                const isCompleted = countDocs === data.length;
                if (shouldBeMore && !isCompleted) {
                    const startAft = data[data.length - 1][nameOrder]
                    await addingDocs(startAft)
                } else {
                    const end = page * 10;
                    const start = end - 10
                    const newSlice: any[] = data.slice(start, end)
                    setdataTable(newSlice)
                }
            }
        }
        charge()
    }, [data, page, addingDocs, nameOrder, countDocs]);



    const changeStart = useCallback((u: number) => {
        setpageAt(u)
    }, [])

    const getSearchResult = useCallback((arr: any[]) => {
        setsearchRes(arr)
    }, [])

    return {
        getSearchResult,
        changeStart,
        searchRes,
        dataTable,
        countDocs,
        page
    }

}