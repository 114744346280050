import React from 'react'
interface Props {
  title: string,
  children: React.ReactNode
}
export const RadioContainer = ({children, title}: Props) => {
  return (
    <div className='mb-0 fv-row'>
      <label className='d-flex align-items-center form-label mb-5'>
        {title}
      </label>
      <div className='mb-0 d-lg-flex justify-content-lg-evenly'>
        {children}
      </div>
    </div>
  )
}
