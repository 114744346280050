import React from 'react'
import { TRPar } from './TRPar'
interface Props {
    heads: string[],
    children: React.ReactNode,
    options?: React.ReactNode
}
export const TableBody = ({heads, children, options}: Props) => {
  return (

        <div className='card-body py-3'>
            {options}
            <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                        <TRPar>
                            {
                                heads.map((value, index) => (
                                    <th key={`${new Date().getTime()}-${index}`} className='min-w-150px  text-gray-600 fw-bold fs-6'>{value.toUpperCase()}</th>
                                ))
                            }
                        </TRPar>
                    </thead>
                    <tbody>
                        {
                            children
                        }
                    </tbody>
                </table>
            </div>

        </div>
  )
}
