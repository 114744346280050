export const calcDate = (date: Date) => {
    let sendingDate = '';
    let sended = false;
    let dayBefore = false;
    const prepString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    const ant = new Date(new Date(date).setDate(new Date(date).getDate() - 1));
    const antString = `${ant.getDate()}/${ant.getMonth() + 1}/${ant.getFullYear()}`;
    const todayString = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
    const isSendNow = todayString === antString || todayString === prepString;
    if (isSendNow) {
        sendingDate = todayString;
        sended = true;
        dayBefore = true;
    } else {
        sendingDate = antString;
    }
    return {sendingDate: sendingDate, sended: sended, dayBefore: dayBefore} 
}