import { Wiz } from '@components/common/wiz/Wiz';
import { StepWiz } from '@components/common/wiz/StepWiz';
import { StepBonus1 } from './StepBonus1';
import { StepBonus2 } from './StepBonus2';
import { NavWiz } from '@components/common/wiz/NavWiz';
import { StepBonus3 } from './StepBonus3';


export const AddBono = () => {
  return (
    <Wiz type='BONUS_SELL'
      navs={
        <>
          <NavWiz isFirst={true} title='Elige bono' />
          <NavWiz title='Cambios en el precio' />
          <NavWiz title='Pago' />
        </>
      }
      contentForm={
        <>
          <StepWiz isFirst={true}>
            <StepBonus1 />
          </StepWiz>
          <StepWiz>
            <StepBonus2 />
          </StepWiz>
          <StepWiz>
            <StepBonus3 />
          </StepWiz>
        </>
      } />
  )
}
