import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { selectMachines } from '@redux/features/gquerySlice/gquerySlice'
import { selectorEditId, setCloseModal } from '@redux/features/uiSlice/uiSlice'
import { Machine } from '@models/machines/machine'
import { EditMach } from './EditMach'
import { DelOp } from '@components/common/del/DelOp'
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { Booking } from '@models/bookings/booking'
import toast from 'react-hot-toast'
export const EditMachCnt = () => {
    const dispatch = useAppDispatch();
    const machs = useAppSelector(selectMachines);
    const id = useAppSelector(selectorEditId);
    const [mach, setmach] = useState<Machine | null>(null)
    useEffect(() => {
        const f = machs.filter(s => s.docId === id)[0];
        if (f) {
            setmach(f);
        }
    }, [id, machs])
    const deleteMach = async () => {
        const today = new Date();
        const future = (await getDocs(query(collection(db, 'bookings'), 
        where('centerId', '==', mach?.centerId),
        where('StartTime', '>=', today)))).docs.map((value) => value.data()) as Booking[];
        const noCancel = future.filter(el => el.cancelled === undefined);
        const checkSer = noCancel.some(el => mach?.servicesIds.map(x => x.id).includes(el.serviceId));
        if (!checkSer) {
            await deleteDoc(doc(db, `machine/${mach?.docId}`))
            dispatch(setCloseModal());
            toast.success('Máquina eliminada')
        } else {
          const mess = future.map(value => `${value.serviceName}, ${value.showingDate}, ${value.showingHour}`).join('/ ')
          toast.error(`Sin cancelar: ${mess}`)
        }
    }

  return (
    <div>
        {
            mach !== null ?
            <div>
                <EditMach {...mach} />
                <div className='p-4 mt-5'>
                    <DelOp del={deleteMach} quest={'¿Quieres eliminar la máquina?'} text={'Eliminar'} />
                </div>
            </div>
            : null
        }
    </div>
  )
}
