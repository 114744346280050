import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'
import { setImpaidWarn } from '@redux/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/hooks'
import { useAuth } from 'app/modules/auth'
import React, { useEffect } from 'react'

export const DetectPaid = () => {
    const center = useAppSelector(selectCenter)
    const {currentUser} = useAuth()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (center && center.dateDue && currentUser && currentUser.role.includes('superAd') === false) {
            const maping = center.dateDue.split('/').map((value) => Number(value));
            const stopDate = new Date(maping[2], maping[1]-1, maping[0], 0, 0);
            let isWarning: boolean
            if (new Date() > stopDate) {
              isWarning = false;
            } else {
                isWarning = true
            }
            dispatch(setImpaidWarn({view: 'DATE_DUE', warn: isWarning }))
        } else {
            dispatch(setImpaidWarn({view: '', warn: false }))

        }

    }, [center, currentUser, dispatch])
    
  return null
}
