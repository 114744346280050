import { Booking } from "@models/bookings/booking";
import { Machine } from "@models/machines/machine";

export const getMachineBookings = (mach: Machine, workerName: string, bookings: Booking[]) => {
    const totalBooks: Booking[] = [];
    mach.servicesIds.forEach(se => {
        const round = bookings.filter(b => b.serviceId === se.id && b.workerId !== workerName);
        round.forEach(bo => totalBooks.push(bo))
    })
    return totalBooks;
}