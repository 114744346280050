import { db } from '@lib/firebase';
import { SellsBonus } from '@models/bono/sellsBonus';
import { serviceLimit } from '@models/bono/sessionsPack';
import {MultPreSer, WasteBono} from '@models/bookings/MultPre'
import { doc, getDoc } from 'firebase/firestore';
export const trToWasteBono = async (preArr: MultPreSer[]) => {
  const tr = preArr
  .filter(el => el.bono === true && el.ticketUsed === false)
    .map((v: MultPreSer) => ({sesToWaste: v.service.numberSessions, bonoId: v.bonoId, serviceId: v.service.serviceId}))
    .reduce((acc: WasteBono[], curr: WasteBono) => {
      const existingItem = acc.find((item: any) => item.bonoId === curr.bonoId && item.serviceId === curr.serviceId)
      if (existingItem) {
        existingItem.sesToWaste += curr.sesToWaste
      } else {
        acc.push({sesToWaste: curr.sesToWaste, bonoId: curr.bonoId, serviceId: curr.serviceId})
      }
      return acc
    }, []) as WasteBono[];
    const setTr = Array.from(new Set(tr.map((value) => value.bonoId)));
    const sellsBonus = await Promise.all(setTr.map(async (id) => {
      const sell = (await getDoc(doc(db, `sellsBonus/${id}`))).data() as SellsBonus;
      return sell
    }))
    tr.forEach(t => {
      const i = sellsBonus.findIndex(el => el.id === t.bonoId)
      if (i !== -1) {
        if (sellsBonus[i].servicesLimit) {
          const serIndex = t.serviceId;
          const index = (sellsBonus[i].servicesLimit as serviceLimit[]).findIndex(c => c.id === serIndex);
          const curr = (sellsBonus[i].servicesLimit as serviceLimit[])[index].sesNumber;
          (sellsBonus[i].servicesLimit as serviceLimit[])[index].sesNumber = curr - t.sesToWaste;
        } else {
          const sesNormal = t.sesToWaste
          const minus = (sellsBonus[i].numberSessions as number) - sesNormal;
          sellsBonus[i].numberSessions = minus
        }
      }
    })
    return sellsBonus
}

