import { Field } from 'formik';
import React from 'react'
interface Props {
  iconCat: string,
  nameCat: string,
  name: string,
  value: string
}
export const SelCat = ({ iconCat, nameCat, name, value }: Props) => {
  return (
    <div className='d-flex align-items-center mb-8 is-pointer' >
      <div className='form-check form-check-custom form-check-solid mx-5 '>
        <Field className='form-check-input' type='radio' name={name} value={value}
          onMouseDown={(e: any) => {
            e.preventDefault();
            e.target.focus();
            e.target.blur();
          }}
        />
      </div>
      <div className={`${iconCat} body-icon bg-white me-3`} style={{width: 60, height: 60}} />
      <div className=''>
        <p className='text-gray-800 text-hover-primary fw-bold fs-6'>
          {nameCat}
        </p>
      </div>
    </div>
  )
}
